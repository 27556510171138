import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  userroledummytabledata,
  userroletableColumns,
} from "../common/mockData";
import { Btn, H4 } from "../../../../AbstractElements";
import AddUserRole from "./AddUserRole";
import { DELETE, get, patch, post } from "../config";
import { API_URL } from "../config/api_urls";
import { toast, ToastContainer } from "react-toastify";
import { constructFormData } from "../config/imageHandle";
import ConfirmationModal from '../common/Confiemationdeletemodal'; 


function UserRole() {
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [roleModal, setRoleModal] = useState(false);
  const [editUserRoleData, setEditUserRoleData] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);


  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  useEffect(() => {
    fetchUserRoles();
  }, []);

 

  const fetchUserRoles = useCallback(() => {
    get(API_URL.USER_ROLE.USER_ROLE_GET)
      .then((response) => {
        console.log(response);
        
        if (response?.data.length >= 0) {
          const sortedData = response.data.sort((a, b) => a.order_no - b.order_no);
          setData(sortedData);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  

  const roleToggle = () => {
    setRoleModal(!roleModal);
  };

  const editmodalToggle = (row) => {
    setRoleModal(!roleModal);
    setEditUserRoleData(row);
  };

  const handleCancel = () => {
    setEditUserRoleData(null);
    setRoleModal(!roleModal);
  };

  const handleDeleteConfirmation = (row) => {
    setRowToDelete(row);
    setIsDeleteModalOpen(true); 
  };
  
  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(false);
    handleDeletedata(rowToDelete);
  };

  const handleSave = async (formData) => {
    const values = {
      id: formData?.id || "",
      role: formData?.role,
      order_no: formData?.order_no,
      image  : formData?.image

      // privileges_ids: formData?.privileges?.map(i => i.value)
    };
    const data = constructFormData(values);
    if (formData?.id) {
      patch(API_URL.USER_ROLE.USER_ROLE_PATCH(formData?.id), data)
        .then((response) => {
          toast.success("Updated successfully",{autoClose:1000});
          setEditUserRoleData(null);
          setRoleModal(!roleModal);
          fetchUserRoles();
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          toast.error("Oops! something went wrong",{autoClose:1000});
        });
    } else {
      try {
        await post(API_URL.USER_ROLE.USER_ROLE_POST, data);
        toast.success("User Role created successfully",{autoClose:1000});
        setEditUserRoleData(null);
        setRoleModal(!roleModal);
        fetchUserRoles();
      } catch (error) {
        console.error("Error saving data:", error);
        toast.error("Oops! something went wrong",{autoClose:1000});
      }
    }
  };

  const handleDeletedata = (row) => {
    if (row?.id) {
      DELETE(API_URL.USER_ROLE.USER_ROLE_DELETE(row?.id))
        .then((response) => {
          toast.success("Deleted successfully",{autoClose:1000});
          fetchUserRoles();
        })
        .catch((error) => {
          toast.error("Oops! something went wrong",{autoClose:1000});
        });
    }
  };

  return (
    <>
      <div>
        <div className={`d-flex align-items-center justify-content-between  p-4`}>
          <H4 attrH4={{ className: "text-muted m-0" }}>User Role</H4>
          <Btn attrBtn={{ color: "primary", onClick: roleToggle }}>
            + Add New Role
          </Btn>
  
          <AddUserRole
            isOpen={roleModal}
            toggle={roleToggle}
            title="User Role"
            editroleData={editUserRoleData}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        </div>
        <div>
          <DataTable
            data={data}
            columns={userroletableColumns(editmodalToggle, handleDeleteConfirmation)}
            striped={true}
            center={true}
            pagination
            selectableRows={false}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleDelet}
          />
        </div>
      </div>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        onConfirm={handleConfirmDelete}
        message="Are you sure to delete?"
      />
    </>
  );
}

export default UserRole;
