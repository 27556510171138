import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  attributesdummydata,
  attributestableColumns,
} from "../common/mockData";
import { useNavigate } from "react-router";
import { Btn, H4 } from "../../../../AbstractElements";
import { DELETE, get } from "../config";
import { API_URL } from "../config/api_urls";
import { toast, ToastContainer } from "react-toastify";
import ConfirmationModal from "../common/Confiemationdeletemodal"; // The new confirmation modal component

function Attributes() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [editAttribute, setEditAttribute] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  useEffect(() => {
    if (editAttribute) {
      navigate(`${process.env.PUBLIC_URL}/attributes/new/home`, {
        state: { attributeData: editAttribute },
      });
    }
  }, [editAttribute, navigate]);

  useEffect(() => {
    fetchAttributes();
  }, []);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDeleteConfirmation = (row) => {
    setRowToDelete(row);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(false);
    handleDeletedata(rowToDelete);
  };

  const fetchAttributes = useCallback(() => {
    get(API_URL.ATTRIBUTES.GET_ATTRIBUTES)
      .then((response) => {
        console.log(response);

        if (response?.data.length >= 0) {
          const sortedResponse = response?.data?.sort((a, b) => {
            return a.order_number - b.order_number;
          });
          setData(sortedResponse);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleEditdata = (row) => {
    setEditAttribute(row);
  };

  const handleNavigate = () => {
    navigate(`${process.env.PUBLIC_URL}/attributes/new/home`);
  };

  const handleDeletedata = (row) => {
    if (row?.id) {
      DELETE(API_URL.ATTRIBUTES.DELETE_ATTRIBUTES(row?.id))
        .then((response) => {
          toast.success("Deleted successfully", { autoClose: 1000 });
          fetchAttributes();
        })
        .catch((error) => {
          toast.error("Oops! something went wrong", { autoClose: 1000 });
        });
    }
  };

  console.log("data", data);

  return (
    <>
      <div>
        <div
          className={`d-flex align-items-center justify-content-between  p-4`}
        >
          <H4 attrH4={{ className: "text-muted m-0" }}>All Attributes </H4>
          <Btn attrBtn={{ color: "primary", onClick: () => handleNavigate() }}>
            + Add New Attribute
          </Btn>
        </div>
        <div>
          <DataTable
            data={data}
            columns={attributestableColumns(
              handleEditdata,
              handleDeleteConfirmation
            )}
            striped={true}
            center={true}
            pagination
            selectableRows={false}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleDelet}
          />
        </div>
      </div>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        onConfirm={handleConfirmDelete}
        message="Are you sure to delete?"
      />
    </>
  );
}

export default Attributes;
