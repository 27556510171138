import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { dummytabledata, tableColumns } from "../common/mockData";
import { useNavigate } from "react-router";
import { Btn, H4 } from "../../../../AbstractElements";
import { DELETE, get } from "../config";
import { API_URL } from "../config/api_urls";
import { toast, ToastContainer } from "react-toastify";
import EditCommonModal from "../common/EditCommonModal";
import FilterForm from "../users/FilterForm";
import ConfirmationModal from "../common/Confiemationdeletemodal";

function MarketPlace() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  console.log("data ", data);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [editMarketPlace, setEditMarketPlace] = useState();
  const [marketCategory, setMarketCategory] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  // filter

  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const [categoryModal, setCategoryModal] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [csvData, setCsvData] = useState(data);
  const [userRegional, setUserRegional] = useState([]);
  const [userRole, setUserRole] = useState();
  const [gender, setGender] = useState("");
  const [username, setUsername] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [selectedRegionalPartner, setSelectedRegionalPartner] = useState("");
  const [userCategories, setUserCategories] = useState();
  const [userRoles, setUserRoles] = useState([]);
  const [city, setcity] = useState([]);

  useEffect(() => {
    if (editMarketPlace) {
      navigate(`${process.env.PUBLIC_URL}/market-place/add-new/home`, {
        state: { marketplace: editMarketPlace },
      });
    }
  }, [editMarketPlace, navigate]);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  useEffect(() => {
    fetchMarketPlaces();
    fetchMarketCategory();
  }, []);

  const fetchMarketPlaces = useCallback(() => {
    get(API_URL.MARKET_PRPDUCTS.GET_MARKET_PRPDUCTS)
      .then((response) => {
        console.log(response);

        if (response?.data.length >= 0) {
          console.log(response);
          const reversedData = [...response.data].reverse();

          setData(reversedData);
          setOriginalData(reversedData);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log(data);

  const fetchMarketCategory = useCallback(() => {
    get(API_URL.MARKET_CATEGORY.GET_MARKET_CATEGORY)
      .then((response) => {
        if (response?.data.length > 0) setMarketCategory(response.data);
        setUserCategories(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleNavigate = () => {
    navigate(`${process.env.PUBLIC_URL}/market-place/add-new/home`);
  };
  const handleEditdata = (row) => {
    setEditMarketPlace(row);
  };

  const handleDeleteConfirmation = (row) => {
    setRowToDelete(row);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(false);
    handleDeletedata(rowToDelete);
  };

  const handleDeletedata = (row) => {
    if (row?.uuid) {
      DELETE(API_URL.MARKET_PRPDUCTS.DELETE_MARKET_PRPDUCTS(row?.uuid))
        .then((response) => {
          toast.success("Deleted successfully", { autoClose: 1000 });
          fetchMarketPlaces();
        })
        .catch((error) => {
          toast.error("Oops! something went wrong", { autoClose: 1000 });
        });
    }
  };

  const categoryToggle = () => {
    setCategoryModal(!categoryModal);
  };

  const handleCancel = () => {
    categoryToggle();
    setUserRole("");
    setGender("");
    setUsername("");
    setWhatsappNumber("");
    setCountry("");
    setCities("");
    setState("");
    setUserCategories("");
    setData(originalData);
    setCsvData(originalData);
  };

  console.log(originalData);
  console.log(username);

  const handleRefresh = () => {
    fetchMarketPlaces();
  };

  //filter
  const handleSave = () => {
    const filteredData = originalData.filter((item) => {
      const roleMatches = userRole
        ? String(item.user_role_id) === String(userRole)
        : true;
      const genderMatches = gender ? item.gender === gender : true;
      const usernameMatches = username
        ? item.user_name
          ? item.user_name === username
          : false
        : true;
      const PhonennumberMatches = whatsappNumber
        ? item.phone_number
          ? item.phone_number.includes(whatsappNumber)
          : false
        : true;
      console.log("Role Matches:", roleMatches); // Check if role matching works
      console.log();

      console.log("This ite item", item);

      const regionalPartnerMatches = selectedRegionalPartner
        ? item.uuid === selectedRegionalPartner
        : true;
      // Fixing country and state filter comparison
      const countryMatches = country ? item.country_name === country : true;
      const cityMatches =
        cities.length > 0 ? cities.includes(item.city_id) : true;
      const stateMatches =
        state.length > 0 ? state.includes(item.state_id) : true;
      const categoryMatches = userCategories
        ? String(item.category_id) === String(userCategories)
        : true;
      console.log("this is usercategory type is", typeof userCategories);
      console.log(
        "this is typeof item_category_id is",
        typeof item.category_id
      );
      console.log("");

      // console.log('User Category from filter:',typeof userCategories);
      // console.log('Item category ID:',typeof item.category_id);

      return (
        roleMatches &&
        genderMatches &&
        usernameMatches &&
        PhonennumberMatches &&
        countryMatches &&
        stateMatches &&
        categoryMatches &&
        regionalPartnerMatches &&
        cityMatches
      );
    });

    setData(filteredData);
    setCsvData(filteredData);
    categoryToggle();
    setUserRole("");
    setGender("");
    setUsername("");
    setWhatsappNumber("");
    setCountry("");
    setCities("");
    setState("");
    setUserCategories("");
  };

  return (
    <>
      <div>
        <div
          className={`d-flex align-items-center justify-content-between  p-4`}
        >
          <H4 attrH4={{ className: "text-muted m-0" }}>
            All MarketPlace Details
          </H4>
          <div className="d-flex">
            <Btn
              attrBtn={{ color: "primary", onClick: () => handleNavigate() }}
            >
              + Add New Market Place
            </Btn>
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn ms-2",
                onClick: categoryToggle,
              }}
            >
              <span className="title">Filters</span>
            </Btn>
            <button
              type="button"
              className="btn"
              style={{
                width: "30px",
                height: "38px",
                padding: "0",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleRefresh}
            >
              <i className="fa fa-refresh " style={{ fontSize: "18px" }}></i>
            </button>
          </div>
        </div>
        <EditCommonModal
          isOpen={categoryModal}
          toggle={categoryToggle}
          title="Apply filters"
          onSave={handleSave}
          onCancel={handleCancel}
        >
          <FilterForm
            userRole={userRole}
            userRoles={userRoles}
            setUserRole={setUserRole}
            gender={gender}
            setGender={setGender}
            username={username}
            setUsername={setUsername}
            whatsappNumber={whatsappNumber}
            setWhatsappNumber={setWhatsappNumber}
            country={country}
            setCountry={setCountry}
            state={state}
            setState={setState}
            city={city}
            cities={cities}
            setcities={setCities}
            usercategories={userCategories}
            userCategory={marketCategory}
            setUserCategories={setUserCategories}
            countries={countries}
            states={states}
            userRegional={userRegional}
            selectedRegionalPartner={selectedRegionalPartner}
            setSelectedRegionalPartner={setSelectedRegionalPartner}
          />
        </EditCommonModal>
        <div>
          <DataTable
            data={data}
            columns={tableColumns(handleEditdata, handleDeleteConfirmation)}
            striped={true}
            center={true}
            pagination
            selectableRows={false}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleDelet}
            fixedHeader
            fixedHeaderScrollHeight="80vh"
          />
        </div>
      </div>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        onConfirm={handleConfirmDelete}
        message="Are you sure to delete?"
      />
    </>
  );
}

export default MarketPlace;
