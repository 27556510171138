import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { jobtypetabledata, jobtypetableColumns } from '../common/mockData';
import { Btn, H4 } from '../../../../AbstractElements';
import NewJobtype from './NewJobtype';
import { DELETE, get, patch, post } from '../config';
import { API_URL } from '../config/api_urls';
import { toast, ToastContainer } from 'react-toastify';
import ConfirmationModal from '../common/Confiemationdeletemodal'; 


function JobType() {
  const [data, setData] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [jobtypeModal,setJobtypeModal] = useState(false);
  const [editJobTypeData,setEditJobTypeData] = useState();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); 
  const [rowToDelete, setRowToDelete] = useState(null);

 
  useEffect(() => {
    fetchEventTypes();
  }, [])
  
  const fetchEventTypes = useCallback(() => {
    get(API_URL.EVENT_TYPES.GET_EVENT_TYPES)
      .then((response) => {
 console.log("response ", response);
        if (response?.data.length >= 0)
          setData(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);



const jobtypeToggle = ()=>{
  setJobtypeModal(!jobtypeModal);
}
const editmodalToggle =(row)=>{
  setJobtypeModal(!jobtypeModal);
  setEditJobTypeData(row)
}


const handleCancel = () => {
  setJobtypeModal(!jobtypeModal);
  setEditJobTypeData(null)
};

const handleDeleteConfirmation = (row) => {
  setRowToDelete(row);
  setIsDeleteModalOpen(true); 
};

const handleConfirmDelete = () => {
  setIsDeleteModalOpen(false); 
  handleDeletedata(rowToDelete);
};


const handleSave = async (formData) => {
  if (formData?.id) {
    patch(API_URL.EVENT_TYPES.PATCH_EVENT_TYPES(formData?.id), formData)
      .then((response) => {
        toast.success("Updated successfully",{autoClose:1000});
        setJobtypeModal(!jobtypeModal);
        setEditJobTypeData(null)
        fetchEventTypes();
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        toast.error("Oops! something went wrong",{autoClose:1000});
      });
  }else{
    try {
      await post(API_URL.EVENT_TYPES.POST_EVENT_TYPES, formData);
      toast.success("Event Category created successfully",{autoClose:1000});
      setJobtypeModal(!jobtypeModal);
      setEditJobTypeData(null)
      fetchEventTypes();
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Oops! something went wrong",{autoClose:1000});
    }
  }
  
};


const handleDeletedata = (row)=>{
  if(row?.id){
   DELETE(API_URL.EVENT_TYPES.DELETE_EVENT_TYPES(row?.id))
   .then((response) => {
     toast.success("Deleted successfully",{autoClose:1000});
     fetchEventTypes();
   })
   .catch((error) => {
     toast.error("Oops! something went wrong",{autoClose:1000});
   });
  }
 }
 

  return (
    <>
       <div>
         <div className={`d-flex align-items-center justify-content-between  p-4`}>
                      <H4 attrH4={{ className: 'text-muted m-0' }}>Event Types</H4>
                     <div>
                     <Btn attrBtn={{ color: 'primary', onClick:jobtypeToggle}}>+ Add Event Type </Btn>
                      
                      <NewJobtype
                      isOpen={jobtypeModal}
                      toggle={jobtypeToggle}
                      title="New Event Type"
                      jopTypeData={editJobTypeData}
                      onSave={handleSave}
                      onCancel={handleCancel}
                      />
                      </div> 
                                         
                  </div>
      <div>
         <DataTable
                  data={data}
                  columns={jobtypetableColumns(editmodalToggle,handleDeleteConfirmation)}
                  striped={true}
                  center={true}
                  pagination
                  selectableRows={false}
                  clearSelectedRows={toggleDelet}
              />
      </div>
       </div>
    
       <ConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        onConfirm={handleConfirmDelete}
        message="Are you sure to delete?"
      />

    </>
   
  )
}

export default JobType