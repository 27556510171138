import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  ColorPicker,
  DateAndTime,
  MaximumLength,
  Month,
  Password,
  Placeholder,
  SimpleInput,
  StaticText,
  Telephone,
  Textarea,
  Time,
  Week,
} from "../../../../Constant";
import HeaderCard from "../../../Common/Component/HeaderCard";
import FooterCard from "../../../Forms/FormControl/Common/FooterCard";
import { useLocation, useNavigate } from "react-router";
import FormCommon from "../common/FormCommon";
import { get, patch, post } from "../config";
import { API_URL } from "../config/api_urls";
import { constructFormData } from "../config/imageHandle";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import "./Newjobopening.css";

function NewJobOpening() {
  const location = useLocation();
  const jobOpenings = location.state?.jobOpeningsData;

  console.log(jobOpenings);

  console.log(location.state);
  const [videoUrl, setVideoUrl] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedstate, setselectedstate] = useState(null);

  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    handleCityChange();
  }, []);

  console.log(selectedstate);

  //states dropdown
  const fetchStates = useCallback(async () => {
    try {
      const response = await get(API_URL.STATES.STATES_GET);
      const states = response?.data?.map((i) => ({
        label: i.name,
        value: i.id,
      }));
      states.unshift({ label: "Select State", value: "" });
      if (states.length > 0) {
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "state" ? { ...field, options: states } : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const handleCityChange = (event) => {
    const stateId = parseInt(event); // Get selected state ID

    try {
      const response = get(API_URL.CITIES.CITIES_GET); // Direct API call without state ID
      const cities = response?.data?.map((i) => ({
        label: i.name,
        value: i.id,
      }));
      console.log("Cities:", response);

      // Add default option at the beginning
      cities.unshift({ label: "Select City", value: "" });

      // Update the formDataConfig with new city options
      setFormDataConfig((prevConfig) => ({
        ...prevConfig,
        fields: prevConfig.fields.map((field) =>
          field.name === "city" ? { ...field, options: cities } : field
        ),
      }));
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  useEffect(() => {
    // fetchCities();
    fetchStates();
  }, []);

  const [formDataConfig, setFormDataConfig] = useState({
    initialState: {
      uuid: null,
      user_uuid: "",
      title: "",
      image: "",
      event_types: "",
      category: "",
      description: "",
      address_line_1: "",
      address_line_2: "",
      pincode: "",
      country: "",
      state: "",
      city: "",
      registration_fee: "",
      deadline: "",
      is_featured: "",
      is_active: "",
    },
    fields: [
      {
        name: "user_uuid",
        label: "Users",
        type: "select",
        options: [],
        required: true,
      },
      {
        name: "title",
        label: "Title",
        type: "text",
        placeholder: "Enter Title",
        required: true,
      },
      {
        name: "image",
        label: "Image",
        type: "file",
        required: false,
      },
      // {
      //   name: "Video",
      //   label: "Video",
      //   type: "file",
      //   required: true,
      //   accept: "video/*"
      // },
      {
        name: "event_types",
        label: "Event Type",
        type: "select",
        options: [],
        required: true,
      },
      {
        name: "category",
        label: "Category",
        type: "select",
        options: [],
        required: false,
      },
      {
        name: "description",
        label: "Description",
        type: "quill-editor",
        placeholder: "Enter Description",
        required: false,
      },
      {
        name: "address_line_1",
        label: "Addressline1",
        type: "text",
        placeholder: "Enter Addressline1",
        required: false,
      },
      {
        name: "address_line_2",
        label: "Addressline2",
        type: "text",
        placeholder: "Enter Addressline2",
        required: false,
      },
      {
        name: "pincode",
        label: "Pincode",
        type: "number",
        placeholder: "Enter Pincode",
        required: false,
      },
      {
        name: "country",
        label: "Country",
        type: "select",
        options: [],
        required: false,
      },
      {
        name: "state",
        label: "State",
        type: "select",
        options: [],
        required: false,
        onChange: (event) => setselectedstate(parseInt(event)),
      },
      {
        name: "city",
        label: "City",
        type: "select",
        options: [],
        required: false,
        // onChange: handleCityChange
      },
      {
        name: "registration_fee",
        label: "Registration Fee",
        type: "number",
        placeholder: "Enter Registration Fee",
        required: true,
      },
      {
        name: "deadline",
        label: "Deadline",
        type: "datetime-local",
        placeholder: "Select Deadline",
        required: true,
      },
      {
        name: "is_featured",
        label: "Featured",
        type: "checkbox",
        required: false,
      },
      {
        name: "is_active",
        label: "Active",
        type: "checkbox",
        required: false,
      },
    ],
  });

  useEffect(() => {
    if (jobOpenings) {
      console.log(jobOpenings);
      setselectedstate(jobOpenings.state); // Set the selected state when editing
      setIsEditing(true);
      setFormDataConfig((value) => ({
        ...value,
        initialState: {
          ...jobOpenings,
          user_uuid: jobOpenings?.user_uuid,
          category: jobOpenings?.category,
          event_types: jobOpenings?.event_types,
          state: jobOpenings?.state,
          country: jobOpenings?.country,
        },
      }));
      fetchStates();
      fetchCities(jobOpenings?.state);
    } else {
      setIsEditing(false);
      setFormDataConfig((value) => ({
        ...value,
        initialState: {
          uuid: null,
          user_uuid: "",
          title: "",
          image: "",
          event_types: "",
          category: "",
          description: "",
          address_line_1: "",
          address_line_2: "",
          pincode: "",
          city: "",
          state: "",
          country: "",
          registration_fee: "",
          deadline: "",
          is_featured: false,
          is_active: false,
        },
      }));
    }
  }, [jobOpenings]);

  useEffect(() => {
    fetchUsers();
    fetchEventCategory();
    fetchEventType();
    fetchCountries();
  }, []);

  // useEffect(() => {
  //   fetchStates();
  //   fetchCities();
  // }, [selectedstate])

  useEffect(() => {
    fetchStates();
  }, []);

  // Fetch cities when selectedstate changes
  useEffect(() => {
    if (selectedstate) {
      fetchCities();
    }
  }, [selectedstate]);

  const handleNavigateToEvents = () => {
    navigate(`${process.env.PUBLIC_URL}/events/openings/home`);
  };

  console.log("states", selectedstate);

  // const fetchCities = useCallback( () => {
  //   try {
  //     const response =  get(API_URL.CITIES.CITIES_BY_STATES(selectedstate));
  //     const cities = response?.data
  //     .filter((city) => {
  //       console.log('City in filter:', city); // Log each city object before filtering
  //       return city.state === selectedstate; // Filter based on matching state ID
  //     })
  //     .map((i) => {
  //       console.log('City object in map:', i); // Log the city object after filtering
  //       return {
  //         label: i.name, // Return the name of the city
  //         value: i.id,
  //       };
  //     });
  //     console.log('response',cities);

  //     cities.unshift({ label: "Select City", value: "" });
  //     if (cities.length > 0) {
  //       setFormDataConfig((prevConfig) => ({
  //         ...prevConfig,
  //         fields: prevConfig.fields.map((field) =>
  //           field.name === "city" ? { ...field, options: cities } : field
  //         ),
  //       }));
  //     }

  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // }, []);

  //users dropdown
  const fetchUsers = useCallback(async () => {
    try {
      const response = await get(API_URL.USERS.GET_USERS);
      const users_names = response?.data?.map((i) => ({
        label: i.name,
        value: i.uuid,
      }));
      setUserOptions(users_names);

      users_names.unshift({ label: "Select User", value: "" });
      if (users_names.length > 0) {
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "user_uuid"
              ? { ...field, options: users_names }
              : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  // const fetchSize = useCallback(async () => {
  // 	try {
  // 		const response = await get(API_URL.MARKET_SIZES.GET_MARKET_SIZES);
  // 		const size_ids = response?.data?.map((i) => ({
  // 			label: i.size,
  // 			value: i.id,
  // 		}));
  //     console.log(size_ids);

  //     setProductSize(size_ids)
  // 		size_ids.unshift({ label: "Select size", value: "" });
  // 		if (size_ids.length > 0) {
  // 			setFormDataConfig((prevConfig) => ({
  // 				...prevConfig,
  // 				fields: prevConfig.fields.map((field) =>
  // 					field.name === "size_value" ? { ...field, options: size_ids } : field
  // 				),
  // 			}));
  // 		}
  // 	} catch (error) {
  // 		console.error("Error:", error);
  // 	}
  // }, []);

  //category dropdown
  const fetchEventCategory = useCallback(async () => {
    try {
      const response = await get(API_URL.EVENT_CATEGORY.GET_EVENT_CATEGORY);
      const event_categories = response?.data?.map((i) => ({
        label: i.name,
        value: i.id,
      }));
      event_categories.unshift({ label: "Select Event Type", value: "" });
      if (event_categories.length > 0) {
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "category"
              ? { ...field, options: event_categories }
              : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  //event type dropdown
  const fetchEventType = useCallback(async () => {
    try {
      const response = await get(API_URL.EVENT_TYPES.GET_EVENT_TYPES);
      const events_types = response?.data?.map((i) => ({
        label: i.name,
        value: i.id,
      }));
      events_types.unshift({ label: "Select Event Type", value: "" });
      if (events_types.length > 0) {
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "event_types"
              ? { ...field, options: events_types }
              : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  //country dropdown
  const fetchCountries = useCallback(async () => {
    try {
      const response = await get(API_URL.COUNTRIES.COUNTRIES_GET);
      const countries = response?.data?.map((i) => ({
        label: i.name,
        value: i.id,
      }));
      countries.unshift({ label: "Select Country", value: "" });
      if (countries.length > 0) {
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "country" ? { ...field, options: countries } : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  //cities dropdown
  const fetchCities = useCallback(async () => {
    try {
      const response = await get(
        API_URL.CITIES.CITIES_GET_BY_ID(selectedstate)
      );
      const cities = response?.data?.map((i) => ({
        label: i.name,
        value: i.id,
      }));
      cities.unshift({ label: "Select City", value: "" });
      if (cities.length > 0) {
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "city" ? { ...field, options: cities } : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [selectedstate]);

  //   const handleInputChange = (e, field) => {
  //     const { value, type, checked } = e.target;
  //     setFormDataConfig(prev => ({
  //         ...prev,
  //         initialState: {
  //             ...prev.initialState,
  //             [field]: type === 'checkbox' ? checked : value,

  //         },
  //     }));
  // };

  const handleInputChange = (e, field) => {
    const { name, value, files, type, checked } = e.target;

    setFormDataConfig((prev) => ({
      ...prev,
      initialState: {
        ...prev.initialState,
        [field]:
          type === "file" ? files[0] : type === "checkbox" ? checked : value,
      },
    }));
  };

  // const onSave = (formData,fileNames,videoFile) => {
  //   const formattedDeadline = moment(formData.deadline).format('YYYY-MM-DD hh:mm A')

  //   console.log(formData);

  // 	const data = constructFormData({...formData,deadline: formattedDeadline }, ['image']);

  //   console.log(data);

  // 	if (formData?.uuid) {
  // 		patch(API_URL.EVENTS.PATCH_EVENT(formData?.uuid), data)
  // 			.then((response) => {
  //         console.log(response);

  // 				toast.success("Updated successfully",{autoClose:1000});
  //         handleNavigateToEvents();
  // 			})
  // 			.catch((error) => {
  // 				console.error("Error updating data:", error);
  // 				toast.error("Oops! something went wrong",{autoClose:1000});
  // 			});
  // 	} else {
  // 		post(API_URL.EVENTS.POST_EVENT, data)
  // 			.then((response) => {
  //         console.log(response);
  //         console.log(videoFile[0]);

  //         for(let i=0;i < videoFile.length;i++){
  //           const formDataa = new FormData();
  //           formDataa.append('event_uuid',response.data.event.uuid);
  //           formDataa.append('video_file',videoFile[i])

  //           // console.log(galleryImg);

  //           console.log(formDataa);

  //           post(API_URL.EVENTS.VIDEO_LINKS,formDataa)
  //           .then((response)=>{
  //             console.log(response);
  //             setVideoUrl(response.data.url);

  //           }).catch((error)=>{
  //             console.error(error);

  //           })
  //         }

  // 				toast.success("Event created successfully",{autoClose:1000});

  //         setTimeout(() => {
  //           handleNavigateToEvents();
  //         }, 1000);

  // 			})
  // 			.catch((error) => {
  // 				console.error("Error saving data:", error);
  // 				toast.error("Oops! something went wrong",{autoClose:1000});
  // 			});
  // 	}
  // };

  // const onSave = (e) => {
  //   e.preventDefault(); // Prevent default form submission

  //   const formData = formDataConfig.initialState;
  //   const formattedDeadline = moment(formData.deadline).format(
  //     "YYYY-MM-DD hh:mm A"
  //   );

  //   const data = constructFormData(
  //     { ...formData, deadline: formattedDeadline },
  //     ["image"]
  //   );

  //   if (formData?.uuid) {
  //     patch(API_URL.EVENTS.PATCH_EVENT(formData?.uuid), data)
  //       .then((response) => {
  //         toast.success("Updated successfully", { autoClose: 1000 });
  //         navigateToEvents();
  //       })
  //       .catch((error) => {
  //         console.error("Error updating data:", error);
  //         toast.error("Oops! something went wrong", { autoClose: 1000 });
  //       });
  //   } else {
  //     post(API_URL.EVENTS.POST_EVENT, data)
  //       .then((response) => {
  //         handleVideoUpload(response.data.event.uuid);
  //         toast.success("Event created successfully", { autoClose: 1000 });
  //         navigateToEvents();
  //       })
  //       .catch((error) => {
  //         console.error("Error saving data:", error);
  //         toast.error("Oops! something went wrong", { autoClose: 1000 });
  //       });
  //   }
  // };
  const onSave = (e) => {
    e.preventDefault(); // Prevent default form submission

    const formData = formDataConfig.initialState;
    const formattedDeadline = moment(formData.deadline).format(
      "YYYY-MM-DD hh:mm A"
    );

    // Construct FormData, including the "image" field as a file
    const data = constructFormData(
      { ...formData, deadline: formattedDeadline },
      ["image"] // Specifies "image" as a file field
    );

    if (formData?.uuid) {
      // Update request
      patch(API_URL.EVENTS.PATCH_EVENT(formData?.uuid), data)
        .then((response) => {
          toast.success("Updated successfully", { autoClose: 1000 });
          navigateToEvents();
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          toast.error("Oops! something went wrong", { autoClose: 1000 });
        });
    } else {
      // Create request
      post(API_URL.EVENTS.POST_EVENT, data)
        .then((response) => {
          handleVideoUpload(response.data.event.uuid);
          toast.success("Event created successfully", { autoClose: 1000 });
          navigateToEvents();
        })
        .catch((error) => {
          console.error("Error saving data:", error);
          toast.error("Oops! something went wrong", { autoClose: 1000 });
        });
    }
  };
  //     e.preventDefault(); // Prevent default form submission

  //     const formData = formDataConfig.initialState;
  //     const formattedDeadline = moment(formData.deadline).format('YYYY-MM-DD hh:mm A')

  //     // Initialize FormData and append text and file data separately
  //     const data = new FormData();
  //     data.append("deadline", formattedDeadline);

  //     // Add non-file fields to the form data
  //     for (const key in formData) {
  //         if (key !== "image" && formData[key] !== undefined) {
  //             data.append(key, formData[key]);
  //         }
  //     }

  //     // Append the image file if it exists
  //     if (formData.image) {
  //         data.append("image", formData.image);
  //     }

  //     if (formData?.uuid) {
  //         // Update request
  //         patch(API_URL.EVENTS.PATCH_EVENT(formData?.uuid), data)
  //             .then((response) => {
  //                 toast.success("Updated successfully", { autoClose: 1000 });
  //                 navigateToEvents();
  //             })
  //             .catch((error) => {
  //                 console.error("Error updating data:", error);
  //                 toast.error("Oops! something went wrong", { autoClose: 1000 });
  //             });
  //     } else {
  //         // Create request
  //         post(API_URL.EVENTS.POST_EVENT, data)
  //             .then((response) => {
  //                 handleVideoUpload(response.data.event.uuid);
  //                 toast.success("Event created successfully", { autoClose: 1000 });
  //                 navigateToEvents();
  //             })
  //             .catch((error) => {
  //                 console.error("Error saving data:", error);
  //                 toast.error("Oops! something went wrong", { autoClose: 1000 });
  //             });
  //     }
  // };

  const navigateToEvents = () => {
    navigate(`${process.env.PUBLIC_URL}/events/openings/home`);
  };

  const handleVideoUpload = (eventUuid) => {
    // Implement video upload logic here
  };

  return (
    //     <div>
    //     <FormCommon
    //     isEvenVideo = {true}
    //     title={`${jobOpenings ? 'Edit' : 'Add'} Event Opening`}
    //     formDataConfig={formDataConfig}
    //     onSave={onSave}
    //     // editMode={!!formDataConfig?.initialState?.id}
    //   />

    // </div>
    //     <div>
    //     <h2>{`${isEditing ? 'Edit' : 'Add'} Event Opening`}</h2>
    //     <form onSubmit={onSave}>
    //         {formDataConfig.fields.map(field => {
    //             const { name, label, type, options } = field;
    //             return (
    //                 <div key={name}>
    //                     <label>{label}</label>
    //                     {type === 'select' ? (
    //                         <select
    //                             value={formDataConfig.initialState[name]}
    //                             onChange={(e) => {
    //                                 handleInputChange(e, name);
    //                                 if (name === "state") {
    //                                     setselectedstate(e.target.value);
    //                                 }
    //                             }}
    //                         >
    //                             {options.map(option => (
    //                                 <option key={option.value} value={option.value}>
    //                                     {option.label}
    //                                 </option>
    //                             ))}
    //                         </select>
    //                     ) : (
    //                         <input
    //                             type={type}
    //                             value={formDataConfig.initialState[name]}
    //                             onChange={(e) => handleInputChange(e, name)}
    //                             required={field.required}
    //                             placeholder={field.placeholder}
    //                         />
    //                     )}
    //                 </div>
    //             );
    //         })}
    //         <button type="submit">{isEditing ? 'Update' : 'Create'}</button>
    //     </form>
    // </div>
    <div className="p-3">
      <h2>{`${isEditing ? "Edit" : "Add"} Event Opening`}</h2>
      <form onSubmit={onSave} className="mb-3">
        <Row>
          {formDataConfig.fields.map((field) => {
            const { name, label, type, options } = field;
            return (
              <Col md={6} key={name} className="mb-3">
                <div className="form-field d-flex flex-column">
                  <label style={{ fontWeight: "bold" }}>{label}</label>
                  {type === "select" ? (
                    <select
                      value={formDataConfig.initialState[name]}
                      onChange={(e) => {
                        handleInputChange(e, name);
                        if (name === "state") setselectedstate(e.target.value);
                      }}
                      className="styled-input"
                    >
                      {options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : type === "checkbox" ? (
                    <div className="">
                      <input
                        type="checkbox"
                        checked={formDataConfig.initialState[name]}
                        onChange={(e) => handleInputChange(e, name)}
                        className="styled-input"
                      />
                    </div>
                  ) : type === "file" ? (
                    <div className="file-field-container">
                      <input
                        type="file"
                        onChange={(e) => handleInputChange(e, name)}
                        className="styled-input"
                      />
                    </div>
                  ) : (
                    <input
                      type={type}
                      value={formDataConfig.initialState[name]}
                      onChange={(e) => handleInputChange(e, name)}
                      required={field.required}
                      placeholder={field.placeholder}
                      className="styled-input"
                    />
                  )}
                </div>
              </Col>
            );
          })}
        </Row>
        <button
          className="btn btn-secondary"
          type="submit"
          style={{ position: "relative", left: "90%" }}
        >
          {isEditing ? "Update" : "Create"}
        </button>
      </form>
    </div>
  );
}

export default NewJobOpening;
