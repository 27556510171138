import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import FormCommon from "../common/FormCommon";
import { API_URL } from "../config/api_urls";
import { constructFormData } from "../config/imageHandle";
import { get, patch, post, DELETE } from "../config/index";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "@mui/material";
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from "@mui/material/styles";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";

const NewUser = () => {
  const location = useLocation();
  const editUserData = location.state?.userData;
  const navigate = useNavigate();
  const [fileNames, setFileNames] = useState([]);
  const [imageData, setImageData] = useState("");
  const [address, setaddress] = useState("");
  const [idToken, setidToken] = useState();

  const [imagePreview, setImagePreview] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedstate, setselectedstate] = useState(null);

  const [addressConfig, setAddressConfig] = useState({
    initialStates: {
      id: null,
      uuid: null,
      address_line1: "",
      address_line2: "",
      pincode: "",
    },
  });

  const [attributeConfig, setAttributeConfig] = useState([]);

  const [AboutConfig, setAboutConfig] = useState({
    initialStates: {
      // id:null,
      uuid: null,
      about: "",
    },
  });

  const [UserVideoConfig, setUserVideoConfig] = useState({
    initialStates: {
      id:null,
      uuid: null,
      video_url: "",
    },
  });

  const [preferenceConfigue, setPreferenceConfigue] = useState([]);

  const [LocationConfig, setLocationConfig] = useState({
    initialStates: {
      // id:null,
      uuid: null,
      state_id: "",
      country_id: "",
      city_id: "",
    },
    fields: [
      {
        name: "country_id",
        label: "Country",
        type: "select",
        options: [],
        required: true,
      },
      // {
      //   name: "state_id",
      //   label: "State",
      //   type: "select",
      //   options: [],
      //   required: true,
      // },
      {
        name: "state_id",
        label: "State",
        type: "select",
        options: [],
        required: true,
        onChange: (event) => setselectedstate(parseInt(event)),
      },
      {
        name: "city_id",
        label: "City",
        type: "select",
        options: [],
        required: true,
      },
    ],
  });

  const [CheckboxConfig, setCheckboxConfig] = useState({
    initialStates: {
      // id:null,
      uuid: null,
      is_featured: "",
      is_blocked: "",
      // city_id: "",
    },
    fields: [
      {
        name: "is_featured",
        label: "Featured",
        type: "checkbox",
        required: false,
      },
      {
        name: "is_blocked",
        label: "Blocked",
        type: "checkbox",
        required: false,
      },
      {
        name: "is_active",
        label: "Active",
        type: "checkbox",
        required: false,
      },
    ],
  });

  // const [preferenceConfigue, setPreferenceConfigue] = useState([
  //   {
  //     id: "",
  //     user_uuid: "",
  //     preference_ids: "",
  //   },
  // ]);

  const [socialConfigue, setSocialConfigue] = useState([]);

  const [sociaMedia, setSocialMedia] = useState([]);

  const [preference, setPreference] = useState([]);

  const [attributes, setAttributes] = useState([]);

  const [achievements,setAchievements] = useState([])
  const [achievementConfig,setAchievementConfig] = useState([{
    name:""
  }])

  const [isPreview, setIsPreview] = useState(false);

  const [userPreferences, setUserPreferences] = useState([]);

  const [formDataConfig, setFormDataConfig] = useState({
    initialState: {
      uuid: null,
      profile_image: "",
      cover_image: "",
      name: "",
      email: "",
      whatsapp_number: "",
      phone_number: "",
      date_of_birth: "",
      gender: "",
      user_role_id: "",
      category_id: "",
      state_id: "",
      country_id: "",
      city_id: "",
      awards_achievements: "",
      user_preference: "",
      user_attributes: "",
      hearaboutus_id: "",
      age: "",
      social_media: "",
      social_media_link: "",
      is_featured: "",
      is_blocked: "",
      gallery: "",
    },
    fields:
      [
        {
          name: "name",
          label: "Full Name",
          type: "text",
          placeholder: "Enter Full Name",
          required: true,
        },
        {
          name: "email",
          label: "Email",
          type: "email",
          placeholder: "Enter Email ID",
          required: true,
        },
        {
          name: "whatsapp_number",
          label: "WhatsApp Number",
          type: "tel",
          placeholder: "Enter WhatsApp Number",
          required: true,
        },
        {
          name: "phone_number",
          label: "Phone Number",
          type: "tel",
          placeholder: "Enter Phone Number",
          required: true,
        },
        {
          name: "date_of_birth",
          label: "Date Of Birth",
          type: "date",
          placeholder: "Select DOB",
          required: true,
        },
        {
          name: "profile_image",
          label: "Profile Image",
          type: "file",
          required: false,
        },
        {
          name: "cover_image",
          label: "Cover Image",
          type: "file",
          required: false,
        },
        {
          name: "gender",
          label: "Gender",
          type: "select",
          options: [
            {
              disabled: true,
              selected: "true",
              value: "",
              label: "select gender",
            },
            { value: "Male", label: "Male" },
            { value: "Female", label: "Female" },
            { value: "Other", label: "Other" },
          ],
          required: true,
        },

        {
          name: "user_role_id",
          label: "User Role",
          type: "select",
          options: [],
          required: true,
        },
        {
          name: "category_id",
          label: "User Category",
          type: "select",
          options: [],
          required: true,
        },
        // {
        //   name: "country_id",
        //   label: "Country",
        //   type: "select",
        //   options: [],
        //   required: true,
        // },
        // {
        //   name: "state_id",
        //   label: "State",
        //   type: "select",
        //   options: [],
        //   required: true,
        // },
        // {
        //   name: "state_id",
        //   label: "State",
        //   type: "select",
        //   options: [],
        //   required: true,
        //   onChange: (event) => setselectedstate(parseInt(event)),
        // },
        // {
        //   name: "city_id",
        //   label: "City",
        //   type: "select",
        //   options: [],
        //   required: true,
        // },
        {
          name: "hearaboutus_id",
          label: "How did you know about us",
          type: "select",
          options: [],
          required: true,
        },
        {
          name: "age",
          label: "Age",
          type: "number",
          placeholder: "Enter Age",
          required: true,
        },
        {
          name: "awards_achievements",
          label: "Awards & Achievements",
          type: "text",
          placeholder: "Enter awards or achievements",
          required: false,
        },
        // {
        //   name: "is_featured",
        //   label: "Featured",
        //   type: "checkbox",
        //   required: false,
        // },
        // {
        //   name: "is_blocked",
        //   label: "Blocked",
        //   type: "checkbox",
        //   required: false,
        // },
        // {
        //   name: "is_active",
        //   label: "Active",
        //   type: "checkbox",
        //   required: false,
        // },
      ] || [],
    imageField: "image",
  });

  useEffect(() => {
    if (editUserData) {
      setIsEditing(true);
      setselectedstate(editUserData.state_id); // Set the selected state when editing
      // setFormDataConfig((value) => ({
      //   ...value,
      //   initialState: {
      //     ...editUserData,
      //     country_id: editUserData?.country_id,
      //     state_id: editUserData?.state_id,
      //     city_id: editUserData?.city_id,
      //     user_role_id: editUserData?.user_role_id,
      //     category_id: editUserData?.category_id,
      //     hearaboutus_id: editUserData?.hearaboutus_id,
      //     profile_image: editUserData?.profile_image,
      //     cover_image: editUserData?.cover_image,
      //     gallery: editUserData?.gallery || [],
      //     user_preference: editUserData?.user_preference || "",
      //     about: editUserData?.about,
      //     address_line1: editUserData?.address_line1,
      //     address_line2: editUserData?.address_line2,
      //   },
      // }));
      setFormDataConfig((value) => {
        console.log("Current value:", value); // Log the current value

        return {
          ...value,
          initialState: {
            ...editUserData,
            country_id: editUserData?.country_id,
            state_id: editUserData?.state_id,
            city_id: editUserData.city_id, // Set the saved city ID here
            user_role_id: editUserData?.user_role_id,
            category_id: editUserData?.category_id,
            hearaboutus_id: editUserData?.hearaboutus_id,
            profile_image: editUserData?.profile_image,
            cover_image: editUserData?.cover_image,
            gallery: editUserData?.gallery || [],
            user_preference: editUserData?.user_preference || "",
            about: editUserData?.about,
            address_line1: editUserData?.address_line1,
            address_line2: editUserData?.address_line2,
          },
        };
      });

      console.log(formDataConfig);
      fetchstates();
      fetchCities(editUserData?.state_id);
      // fetchaddress()
    } else {
      setIsEditing(false);
      setFormDataConfig((value) => ({
        ...value,
        initialState: {
          uuid: null,
          profile_image: "",
          cover_image: "",
          name: "",
          email: "",
          whatsapp_number: "",
          date_of_birth: "",
          gender: "",
          user_role_id: "",
          category_id: "",
          state_id: "",
          country_id: "",
          city_id: "",
          user_preference: "",
          hearaboutus_id: "",
          age: "",
          social_media_id: "",
          social_media_link: "",
          is_featured: "",
          is_blocked: "",
          is_active: "",
          about: "",
        },
      }));
    }
  }, [editUserData]);

  console.log(editUserData);

  useEffect(() => {
    fetchuserroles();
    fetchusercategory();
    fetchstates();
    fetchcountries();
    // fetchcity();
    fetchhearaboutus();
    fetchUserPreferences();
    fetchaddress();
    fetchAttributes();
    fetchAbout();
    fetchUservideo();
    fetchSocialMedianame();
    fetchPreferences();
    // fetchaboutme()
    fetchLocation();
    setidToken("");
    if(editUserData){
      fetchAchievements()
    }
  }, []);

  useEffect(() => {
    if (attributes.length > 0) {
      fetchUserAttributes();
    }
  }, [attributes]);

  useEffect(() => {
    if (preference.length > 0) {
      fetchPreferencesUser();
    }
  }, [preference]);

  useEffect(() => {
    if (sociaMedia.length > 0) {
      fetchUserSocialMedia();
    }
  }, [sociaMedia]);

  useEffect(() => {
    fetchstates();
    fetchCities();
  }, [selectedstate]);

  const handleNavigateToUsers = () => {
    navigate(`${process.env.PUBLIC_URL}`);
  };

  //user role dropdown

  const fetchuserroles = useCallback(async () => {
    try {
      const response = await get(API_URL.USER_ROLE.USER_ROLE_GET);
      const user_role = response?.data?.map((i) => ({
        label: i.role,
        value: i.id,
      }));
      console.log(user_role);

      user_role.unshift({ label: "Select user role", value: "" });
      if (user_role.length > 0) {
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "user_role_id"
              ? { ...field, options: user_role }
              : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  // user category dropdown
  const fetchusercategory = useCallback(async () => {
    try {
      const response = await get(API_URL.USER_CATEGORY.USER_CATEGORY_GET);
      const user_category = response?.data?.map((i) => ({
        label: i.name,
        value: i.id,
      }));
      user_category.unshift({ label: "Select user category", value: "" });
      if (user_category.length > 0) {
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "category_id"
              ? { ...field, options: user_category }
              : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const fetchAttributes = useCallback(async () => {
    try {
      const response = await get(API_URL.ATTRIBUTES.GET_ATTRIBUTES);
      console.log(response);

      const attributes_values = response?.data?.map((i) => ({
        label: i.attribute_name,
        value: i.id,
      }));
      // attributes_values.unshift({ label: "Select Attribute", value: "" });
      if (attributes_values.length > 0) {
        setAttributes(attributes_values);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  console.log(attributes);

  //preference
  const fetchPreferences = useCallback(() => {
    get(API_URL.PREFERENCES.GET_PREFERENCES)
      .then((response) => {
        console.log(response);

        const preference_values = response?.data?.map((i) => ({
          label: i.name,
          value: i.id,
        }));
        // preference_values.unshift({ label: "Select Preference", value: "" });
        if (response?.data.length > 0) setPreference(preference_values);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log(preference);

  const fetchPreferencesUser = useCallback(() => {
    get(API_URL.USER_PREFERENCES.GET_USER_PREFERENCES)
      .then((response) => {
        console.log(response);

        if (response && response.data.length > 0) {
          // Filter all preferences for the current user
          const matchedUsers = response.data.filter(
            (user) => user.user_name === editUserData.name
          );

          console.log("matchedUsers", matchedUsers);

          // Map each matched preference to an individual configuration object
          const updatedPreferences = matchedUsers.map((matchedUser) => {
            const matchPreference = preference.find(
              (pref) => pref.label === matchedUser.preference_name
            );

            console.log("matchPreference", matchPreference);

            return {
              id: matchedUser.id || "",
              user_name: editUserData.name || "",
              // user_uuid: matchedUser.user_uuid || "",
              preference_ids: matchPreference.value || "",
              value: matchedUser.value || "",
            };
          });
          setPreferenceConfigue(updatedPreferences);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [preference]);

  console.log(preferenceConfigue);
  console.log(socialConfigue);
  console.log(attributeConfig);

  const fetchUserAttributes = useCallback(() => {
    get(API_URL.USER_ATTRIBUTES.GET_USER_ATTRIBUTES)
      .then((response) => {
        console.log(response);

        if (response && response.data) {
          const matchedUsers = response.data.filter(
            (user) => user.user_name === editUserData.name
          );
          console.log("matchedUsers", matchedUsers);

          const updatedAttributes = matchedUsers.map((matchedUser) => {
            const matchAttribute = attributes.find(
              (attr) => attr.label === matchedUser.attribute_name
            );
            console.log("matchAttribute", matchAttribute);

            return {
              id: matchedUser.id || "",
              user_name: editUserData.name || "",
              attribute_id: matchAttribute.value || "",
              value: matchedUser.value || "",
            };
          });

          // Update the attributeConfig state with the matched attributes
          setAttributeConfig(updatedAttributes);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [attributes]);

  //fetch social media
  const fetchSocialMedianame = useCallback(() => {
    get(API_URL.SOCIAL_MEDIA_NAME.GET_SOCIALMEDIA_NAME)
      .then((response) => {
        console.log(response);

        const user_socialmedia = response?.data?.map((i) => ({
          label: i.name,
          value: i.id,
        }));

        if (user_socialmedia.length > 0) {
          setFormDataConfig((prevConfig) => ({
            ...prevConfig,
            fields: prevConfig.fields.map((field) =>
              field.name === "social_media_id"
                ? { ...field, options: user_socialmedia }
                : field
            ),
          }));
          setSocialMedia(user_socialmedia);
        }
      })
      .catch((error) => {
        console.error("Error fetching user preferences:", error);
      });
  }, []);

  console.log(sociaMedia);


  const fetchAchievements = async()=>{
    await get(API_URL.ACHIEVEMENTS.GET_ACHIEVEMENTS(editUserData.uuid))
    .then((response)=>{
      console.log(response);
      setAchievementConfig(response.data)
    }).catch((err)=>{
      console.log(err);
    })
  }

  console.log(achievementConfig);
  

  // states dropdown
  const fetchstates = useCallback(async () => {
    try {
      const response = await get(API_URL.STATES.STATES_GET);

      const states = response?.data?.map((i) => ({
        label: i.name,
        value: i.id,
      }));
      states.unshift({ label: "Select state", value: "" });
      if (states.length > 0) {
        setLocationConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "state_id" ? { ...field, options: states } : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const fetchCities = useCallback(async () => {
    try {
      const response = await get(
        API_URL.CITIES.CITIES_GET_BY_ID(selectedstate)
      );
      const cities = response?.data?.map((i) => ({
        label: i.name,
        value: i.id,
      }));
      cities.unshift({ label: "Select City", value: "" });
      if (cities.length > 0) {
        setLocationConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "city_id" ? { ...field, options: cities } : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [selectedstate]);

  console.log("selected state", selectedstate);

  // country dropdown
  const fetchcountries = useCallback(async () => {
    try {
      const response = await get(API_URL.COUNTRIES.COUNTRIES_GET);
      const countries = response?.data?.map((i) => ({
        label: i.name,
        value: i.id,
      }));
      countries.unshift({ label: "Select country", value: "" });
      if (countries.length > 0) {
        setLocationConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "country_id"
              ? { ...field, options: countries }
              : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  // hearaboutus dropdown
  const fetchhearaboutus = useCallback(async () => {
    try {
      const response = await get(API_URL.HEAR_ABOUT_US.HEAR_ABOUT_US_GET);
      const hearaboutus = response?.data?.map((i) => ({
        label: i.aboutus,
        value: i.id,
      }));
      hearaboutus.unshift({ label: "Select Hear About Us", value: "" });
      if (hearaboutus.length > 0) {
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "hearaboutus_id"
              ? { ...field, options: hearaboutus }
              : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const fetchaddress = useCallback(() => {
    get(API_URL.ADDRESS.GET_ADDRESS)
      .then((response) => {
        if (response?.data.length > 0) {
          const userAddress = response.data.find(
            (address) => address.uuid === editUserData?.uuid
          );
          console.log("useraddress", userAddress);
          console.log("response", response);

          if (userAddress) {
            setAddressConfig((prevConfig) => ({
              ...prevConfig,
              initialState: {
                id: userAddress?.id,
                uuid: userAddress?.uuid,
                address_line1: userAddress?.address_line1,
                address_line2: userAddress?.address_line2,
                pincode: userAddress?.pincode,
              },
            }));
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log(sociaMedia);

  const fetchLocation = useCallback(() => {
    get(API_URL.USERS.GET_USERS)
      .then((response) => {
        if (response?.data.length > 0) {
          const userLocation = response.data.find(
            (location) => location.uuid === editUserData?.uuid
          );
          console.log("useralocation", userLocation);
          console.log("response", response);

          if (userLocation) {
            setLocationConfig((prevConfig) => ({
              ...prevConfig,
              initialState: {
                id: userLocation?.id,
                uuid: userLocation?.uuid,
                country_id: userLocation?.country_id,
                state_id: userLocation?.state_id,
                city_id: userLocation?.city_id,
              },
            }));
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  //socialmedia
  const fetchUserSocialMedia = useCallback(() => {
    get(API_URL.SOCIAL_MEDIA.GET_SOCIAL_MEDIA)
      .then((response) => {
        console.log(response);

        if (response.data) {
          // Filter all matching user attributes
          const matchedUsers = response.data.filter(
            (user) => user.user_name === editUserData.name
          );
          console.log("matchedUsers", matchedUsers);

          // Map the matched users to the attributeConfig array
          const updatedSocialmedia = matchedUsers.map((matchedUser) => {
            const matchSocialmedia = sociaMedia.find(
              (social) => social.label === matchedUser.socialmedia_name
            );
            console.log("matchAttribute", matchSocialmedia);

            return {
              id: matchedUser.id || "",
              user_name: editUserData.name || "",
              socialmedia_id: matchSocialmedia.value || "",
              link: matchedUser.link || "",
            };
          });

          // Update the attributeConfig state with the matched attributes
          setSocialConfigue(updatedSocialmedia);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [attributes]);

  const fetchAbout = useCallback(() => {
    get(API_URL.PROFILE.GET_PROFILE)
      .then((response) => {
        if (response?.data.length > 0) {
          const userabout = response.data.find(
            (about) => about.user_uuid === editUserData?.uuid
          );
          console.log("userabout", response);

          if (userabout) {
            setAboutConfig((prevConfig) => ({
              ...prevConfig,
              initialState: {
                // id: userabout?.id,
                uuid: userabout?.user_uuid,
                about: userabout?.about,
              },
            }));
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchUservideo = useCallback(() => {
    get(API_URL.USERS.GET_USER_VIDEO)
      .then((response) => {
        if (response?.data.length > 0) {
          const uservideo = response.data.find(
            (video) => video.user_uuid === editUserData?.uuid
          );
          console.log("userabout", response);

          if (uservideo) {
            setUserVideoConfig((prevConfig) => ({
              ...prevConfig,
              initialState: {
                id: uservideo?.id,
                uuid: uservideo?.user_uuid,
                video_url: uservideo?.video_url,
              },
            }));
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log("aboutmeconfig", AboutConfig);

  console.log("addressconfig", addressConfig);
  console.log("userconfig", UserVideoConfig);

  const fetchUserPreferences = useCallback(async () => {
    try {
      const response = await get(API_URL.PREFERENCES.GET_PREFERENCES); // Use the same endpoint as in Preference
      let preferences = response?.data?.map((preference) => ({
        label: preference.name,
        value: preference.id,
      }));

      // Add default option if no preferences are available
      if (!preferences || preferences.length === 0) {
        preferences = [{ label: "No preferences available", value: "" }];
      } else {
        preferences.unshift({ label: "Select preference", value: "" });
      }

      // Update form configuration with the preferences
      setFormDataConfig((prevConfig) => ({
        ...prevConfig,
        fields: prevConfig.fields.map((field) =>
          field.name === "user_preference"
            ? { ...field, options: preferences }
            : field
        ),
      }));
    } catch (error) {
      console.error("Error fetching preferences:", error);

      // Set a default option in case of an error
      setFormDataConfig((prevConfig) => ({
        ...prevConfig,
        fields: prevConfig.fields.map((field) =>
          field.name === "user_preference"
            ? {
                ...field,
                options: [{ label: "Error loading preferences", value: "" }],
              }
            : field
        ),
      }));
    }
  }, []);

  console.log(attributeConfig);
  console.log(preferenceConfigue);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // convert FileList to an array
    const names = files.map((file) => file.name); // get the file names

    const imageUrl = files.map((file) => URL.createObjectURL(file));
    setImagePreview(imageUrl);

    setFileNames(files);
  };

  const onSave = (
    formData,
    fileNames,
    videoFile,
    aboutMe,
    address_line1,
    address_line2,
    pincode,
    addressFormData,
    aboutFormData,
    VideoFormData
  ) => {
    console.log(addressFormData);

    console.log(formData);

    // Declare submitUserdata before using it
    const submitUserdata = (formData, firebase_user_id = null) => {
      // Merge firebase_user_id only if provided (for new user creation)
      const updatedUserdata = firebase_user_id
        ? { ...formData, firebase_user_id }
        : { ...formData, firebase_user_id: idToken };
      console.log("updatedUserdata", updatedUserdata);

      const data = constructFormData(updatedUserdata, [
        "cover_image",
        "profile_image",
        "gallery",
        "about",
        "address_line1",
        "address_line2",
        "pincode",
      ]);

      console.log("data", data);

      if (formData?.uuid) {
        // Edit existing user
        const editdata = constructFormData(formData, [
          "cover_image",
          "profile_image",
          "gallery",
          "about",
          "address_line1",
          "address_line2",
          "pincode",
        ]);

        if (addressFormData.id) {
          console.log("address", addressFormData.id);
          console.log("addressformdata", addressFormData);

          const address = constructFormData(addressFormData);
          console.log("constructed", address);
          console.log(
            "PATCH URL of address:",
            API_URL.ADDRESS.PATCH_ADDRESS(addressFormData.id),
            address
          );

          // Edit existing address
          patch(API_URL.ADDRESS.PATCH_ADDRESS(addressFormData.id), address)
            .then((addressResponse) => {
              console.log("Address data updated:", addressResponse);
            })
            .catch((error) => {
              console.error("Error updating address data:", error);
            });
        }

        console.log(attributeConfig);

        if (attributeConfig?.length > 0) {
          console.log(attributeConfig);

          attributeConfig.forEach((config) => {
            console.log(config);

            if (config.id) {
              const formDaata = new FormData();
              formDaata.append("attribute_id", config.attribute_id);
              formDaata.append("value", config.value);

              patch(
                API_URL.USER_ATTRIBUTES.PATCH_USER_ATTRIBUTES(config?.id),
                formDaata
              )
                .then((response) => {
                  console.log(response);
                })
                .catch((error) => {
                  console.error("Error updating data:", error);
                });
            } else {
              const formDaata = new FormData();
              formDaata.append("user_uuid", editUserData.uuid);
              attributeConfig.forEach((config) => {
                console.log(config);

                formDaata.append("attribute_id", config.attribute_id);
                formDaata.append("value", config.value);
              });

              post(API_URL.USER_ATTRIBUTES.POST_USER_ATTRIBUTES, formDaata)
                .then((response) => {
                  console.log(response);
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          });
        }

        console.log(preferenceConfigue);
        if (preferenceConfigue?.length > 0) {
          preferenceConfigue.forEach((config) => {
            console.log(config);

            if (config.id) {
              const formDaata = new FormData();
              formDaata.append("preference_id", config.preference_ids);

              console.log(formDaata);

              patch(
                API_URL.USER_PREFERENCES.PATCH_USER_PREFERENCES(config.id),
                formDaata
              )
                .then((response) => {
                  console.log(`Updated config with id ${config.id}:`, response);
                })
                .catch((error) => {
                  console.error(
                    `Error updating data for id ${config.id}:`,
                    error
                  );
                });
            } else {
              const formDaata = new FormData();
              formDaata.append("user_uuid", editUserData.uuid);
              preferenceConfigue.forEach((config) => {
                formDaata.append("preference_ids", config.preference_ids);
              });

              console.log(formDaata);

              try {
                post(API_URL.USER_PREFERENCES.POST_USER_PREFERENCES, formDaata)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              } catch (error) {
                console.error("Error saving data:", error);
              }
            }
          });
        }

        if (aboutFormData.uuid) {
          const about = constructFormData(aboutFormData);

          console.log("aboutFormData before sending:", aboutFormData);
          console.log("Constructed about data:", about);
          console.log(
            "PATCH URL:",
            API_URL.PROFILE.PATCH_PROFILE(aboutFormData.uuid)
          );

          // Edit existing address
          patch(API_URL.PROFILE.PATCH_PROFILE(aboutFormData.uuid), about)
            .then((aboutResponse) => {
              console.log("About data updated:", aboutResponse);
            })
            .catch((error) => {
              console.error("Error updating About data:", error);
            });
        }
        
        if (VideoFormData) {
          
          console.log(VideoFormData);
          
          const videoData = {
            ...VideoFormData,
            user_uuid: formData.uuid,
            id: VideoFormData.id || null, // Ensure id is null if not provided
          };
        
          const video = constructFormData(videoData);
        
          if (VideoFormData.id) {
            // Update existing video (PATCH)
            console.log(
              "PATCH URL of Video:",
              API_URL.USERS.PATCH_USERVIDEO(VideoFormData.uuid, VideoFormData.id)
            );
        
            patch(API_URL.USERS.PATCH_USERVIDEO(VideoFormData.uuid, VideoFormData.id), video)
              .then((videoResponse) => {
                console.log("Video data updated successfully:", videoResponse);
              })
              .catch((error) => {
                console.error("Error updating video data:", error);
              });
          } else {
            // Create new video (POST)
            console.log("POST URL of Video:", API_URL.USERS.USER_VIDEO);
        
            post(API_URL.USERS.USER_VIDEO, video)
              .then((videoResponse) => {
                console.log("Video data created successfully:", videoResponse);
                setUserVideoConfig(videoResponse); // Update state for new video
              })
              .catch((error) => {
                console.error("Error creating video data:", error);
              });
          }
        }
        
        console.log(formData);

        console.log(socialConfigue);

        if (socialConfigue?.length > 0) {
          socialConfigue.forEach((config) => {
            console.log(config);

            if (config.id) {
              const formDaata = new FormData();
              formDaata.append("socialmedia_id", config.socialmedia_id);
              formDaata.append("link", config.link);

              patch(
                API_URL.SOCIAL_MEDIA.PATCH_SOCIAL_MEDIA(config.id),
                formDaata
              )
                .then((response) => {
                  console.log(response);
                })
                .catch((err) => {
                  console.error(err);
                });
            } else {
              const formDaata = new FormData();
              formDaata.append("user_uuid", editUserData?.uuid);
              formDaata.append("socialmedia_id", config.socialmedia_id);
              formDaata.append("link", config.link);

              try {
                post(API_URL.SOCIAL_MEDIA.POST_SOCIAL_MEDIA, formDaata)
                  .then((response) => {
                    console.log(response);
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              } catch (error) {
                console.error("Error saving data:", error);
              }
            }
          });
          // toast.success("Updated successfully",{autoClose:1000});
          // setEditUserSocial(null);
          // fetchSocialMedia();
          // userSocialToggle();

          // Call fetchSocialMedia with await now
        }

        if (achievementConfig?.length > 0) {
          achievementConfig.forEach((config) => {
            console.log(config.id);

            if (config.id) {
              const formDaata = new FormData();
              formDaata.append("name", config.name);

              patch(
                API_URL.ACHIEVEMENTS.PATCH_ACHIEVEMENTS(formData.uuid,config.id),
                formDaata
              )
                .then((response) => {
                  console.log(response);
                })
                .catch((err) => {
                  console.error(err);
                });
            } else {
              const formDaata = new FormData();
                formDaata.append("user_uuid", formData.uuid);
                formDaata.append("name", config.name);

              try {
                post(API_URL.ACHIEVEMENTS.POST_ACHIEVEMENTS, formDaata)
                  .then((response) => {
                    console.log(response);
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              } catch (error) {
                console.error("Error saving data:", error);
              }
            }
          });
          // toast.success("Updated successfully",{autoClose:1000});
          // setEditUserSocial(null);
          // fetchSocialMedia();
          // userSocialToggle();

          // Call fetchSocialMedia with await now
        }

        patch(API_URL.USERS.USERS_PATCH(formData?.uuid), editdata)
          .then((response) => {
            toast.success("Updated successfully", { autoClose: 1000 });
            // handleNavigateToUsers();
          })
          .catch((error) => {
            console.error("Error updating data:", error);
            toast.error("Oops! something went wrong, try again", {
              autoClose: 1000,
            });
          });
      } else {

        // Create new user

        post(API_URL.USERS.USERS_POST, data)
          .then((response) => {
            console.log(response);

            const addressData = {
              ...addressFormData,
              user_uuid: response.data.uuid,
            };

            console.log("addressdata", addressData);
            console.log("onsaveadress", addressConfig);

            const address = constructFormData(addressData);
            console.log("addressconstruct", address);

            if (addressFormData) {
              // Add new address
              post(API_URL.ADDRESS.POST_ADDRESS, address)
                .then((addressResponse) => {
                  console.log("Address data saved:", addressResponse);
                  setAddressConfig(addressResponse);
                })
                .catch((error) => {
                  console.error("Error saving address data:", error);
                });
            }

            if (attributeConfig) {
              const formDaata = new FormData();
              formDaata.append("user_uuid", response.data?.uuid);
              attributeConfig.forEach((config) => {
                console.log(config);

                formDaata.append("attribute_id", config.attribute_id);
                formDaata.append("value", config.value);
              });

              post(API_URL.USER_ATTRIBUTES.POST_USER_ATTRIBUTES, formDaata)
                .then((response) => {
                  console.log(response);
                })
                .catch((err) => {
                  console.error(err);
                });
            }

            if (preferenceConfigue) {
              const formDaata = new FormData();
              formDaata.append("user_uuid", response.data?.uuid);
              preferenceConfigue.forEach((config) => {
                formDaata.append("preference_ids", config.preference_ids);
              });

              console.log(formDaata);

              try {
                post(API_URL.USER_PREFERENCES.POST_USER_PREFERENCES, formDaata)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              } catch (error) {
                console.error("Error saving data:", error);
              }
            }

            if (socialConfigue) {
              console.log(socialConfigue);

              socialConfigue.forEach((config) => {
                const formDaata = new FormData();
                formDaata.append("user_uuid", response.data?.uuid);

                formDaata.append("socialmedia_id", config.socialmedia_id);
                formDaata.append("link", config.link);

                const socialvalues = {
                  user_uuid: response.data.uuid,
                  socialmedia_id: formData?.social_media_id,
                  link: formData?.social_media_link,
                };
                console.log(socialvalues);

                try {
                  post(API_URL.SOCIAL_MEDIA.POST_SOCIAL_MEDIA, formDaata)
                    .then((response) => {
                      console.log(response);
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                } catch (error) {
                  console.error("Error saving data:", error);
                }
              });
            }

            if (achievementConfig) {
              console.log(achievementConfig);

              achievementConfig.forEach((config) => {
                const formDaata = new FormData();
                formDaata.append("user_uuid", response.data?.uuid);
                formDaata.append("name", config.name);

                try {
                  post(API_URL.ACHIEVEMENTS.POST_ACHIEVEMENTS, formDaata)
                    .then((response) => {
                      console.log(response);
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                } catch (error) {
                  console.error("Error saving data:", error);
                }
              });
            }

            const aboutData = {
              ...aboutFormData,
              user_uuid: response.data.uuid,
            };
            console.log("aboutdata", aboutData);
            console.log("onsaveabout", aboutFormData);

            const about = constructFormData(aboutData);
            console.log("aboutconstruct", about);

            if (aboutFormData) {
              // Add new address
              post(API_URL.PROFILE.POST_PROFILE, about)
                .then((aboutResponse) => {
                  console.log("About data saved:", aboutResponse);
                  setAddressConfig(aboutResponse);
                })
                .catch((error) => {
                  console.error("Error saving About data:", error);
                });
            }

            // Handle Gallery images
            if (fileNames) {
              for (let i = 0; i < fileNames.length; i++) {
                const formDataa = new FormData();
                formDataa.append("image", fileNames[i]);
                formDataa.append("user_uuid", response.data.uuid);
                console.log(formDataa);

                post(API_URL.USERS.USER_GALLERY, formDataa)
                  .then((response) => {
                    console.log(response);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }
            }

            // Handle Video files
            // if (videoFile) {
            //   for (let i = 0; i < videoFile.length; i++) {
            //     const formDataa = new FormData();
            //     formDataa.append("user_uuid", response.data.uuid);
            //     formDataa.append("video_url", videoFile[i]);

            //     post(API_URL.USERS.USER_VIDEO, formDataa)
            //       .then((response) => {
            //         console.log(response);
            //       })
            //       .catch((error) => {
            //         console.error(error);
            //       });
            //   }
            // }

            // if (videoFile) {
            //   const formDataa = new FormData();
            //   formDataa.append("user_uuid", response.data.uuid);
            //   formDataa.append("video_url", videoFile); // Use the single video URL
            
            //   post(API_URL.USERS.USER_VIDEO, formDataa)
            //     .then((response) => {
            //       console.log(response);
            //     })
            //     .catch((error) => {
            //       console.error(error);
            //     });
            // }

            const videoData = {
              ...VideoFormData,
              user_uuid: response.data.uuid,
            };
            console.log("videoData", videoData);
            console.log("onsavevideo", VideoFormData);

            const video = constructFormData(videoData);
            console.log("videoconstruct", video);

            if (VideoFormData) {
              // Add new address
              post(API_URL.USERS.USER_VIDEO, video)
                .then((videoResponse) => {
                  console.log("Video data saved:", videoResponse);
                  setUserVideoConfig(videoResponse);
                })
                .catch((error) => {
                  console.error("Error saving Video data:", error);
                });
            }
            

            // Save "About Me" data
            // const aboutMeData = new FormData();
            // aboutMeData.append('user_uuid', response.data.uuid);
            // aboutMeData.append('about', aboutMe);

            // post(API_URL.PROFILE.POST_PROFILE, aboutMeData)
            //   .then((aboutMeResponse) => {
            //     console.log("About Me data saved:", aboutMeResponse);
            //   })
            //   .catch((error) => {
            //     console.error("Error saving About Me data:", error);
            //   });
            setIsPreview(true);
            toast.success("User Registered successfully", { autoClose: 1000 });
            setTimeout(() => {
              // handleNavigateToUsers();
            }, 500);
          })
          .catch((error) => {
            console.error(error.response.data);
            toast.error("Oops something went wrong", { autoClose: 1000 });
          });
      }
    };

    console.log(idToken);

    if (!idToken && !formData.uuid) {
      const phoneNumber = {
        phone_number: `${formData.phone_number}`,
      };
      console.log(phoneNumber);

      post(API_URL.USERS.USER_FIREBASE, phoneNumber)
        .then((response) => {
          console.log(response.data);
          const idToken = response.data.uid;
          setidToken(idToken);

          // Call submitUserdata with Firebase ID
          submitUserdata(formData, idToken);
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.response.data.error);
        });
    } else {
      // If editing user (uuid exists), directly call submitUserdata
      submitUserdata(formData, null); // Pass null or omit Firebase ID
    }
  };

  return (
    <div>
      <FormCommon
        isGallery={true}
        isModal={false}
        isaboutme={true}
        isaddresline1={true}
        isaddresline2={true}
        ispincode={true}
        isEditing={isEditing}
        title={`${editUserData ? "Edit" : "Add"} User Details`}
        formDataConfig={formDataConfig}
        PlaceConfig={formDataConfig ? formDataConfig : true}
        setFormDataConfig={setFormDataConfig}
        onSave={onSave}
        addressConfig={addressConfig ? addressConfig : true}
        AboutConfig={AboutConfig ? AboutConfig : true}
        VideoConfig={UserVideoConfig ? UserVideoConfig : true}
        LocationConfig={LocationConfig ? LocationConfig : true}
        CheckboxConfig={CheckboxConfig ? CheckboxConfig : true}
        attribute={attributes}
        setAttributeConfig={setAttributeConfig}
        attributeConfig={attributeConfig}
        preference={preference}
        preferenceConfigue={preferenceConfigue}
        setPreferenceConfigue={setPreferenceConfigue}
        sociaMedia={sociaMedia}
        socialConfigue={socialConfigue}
        setSocialConfigue={setSocialConfigue}
        isPreview={isPreview}
        setIsPreview={setIsPreview}
        achievements={achievements}
        setAchievements={setAchievements}
        achievementConfig={achievementConfig}
        setAchievementConfig={setAchievementConfig}

        // editMode={!!formDataConfig?.initialState?.id}
      />
      <div className="px-3 pb-5 d-flex justify-content-between "></div>
    </div>
  );
};

export default NewUser;
