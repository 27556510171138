  import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { userstableColumns } from "../common/mockData";
import { useNavigate } from "react-router";
import { Btn, H4 } from "../../../../AbstractElements";
import "../../styles/user-table.css";
import EditCommonModal from "../common/EditCommonModal";
import FilterForm from "./FilterForm";
import { CSVLink } from "react-csv";
import { DELETE, get, post } from "../config";
import { API_URL } from "../config/api_urls";
import { toast, ToastContainer } from "react-toastify";
import { Button, Modal, Row } from "react-bootstrap";
import { Card, CardActionArea, CardMedia } from "@mui/material";
import { Col } from "reactstrap";
import ConfirmationModal from "../common/Confiemationdeletemodal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./UserTable.css";

function UsersTable() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [userRole, setUserRole] = useState();
  const [gender, setGender] = useState("");
  const [csvData, setCsvData] = useState(data);
  const [editUsersList, setEditUsersList] = useState();
  const [userCategory, setUserCategory] = useState([]);
  const [countries, setCountries] = useState();
  const [states, setStates] = useState([]);
  // const [cities,setCities] = useState()
  const [city, setcity] = useState([]);
  const [hearAboutus, setHearAboutus] = useState();
  const [modalShow, setModalShow] = useState(false);
  // const [selectedRow, setSelectedRow] = useState(null);
  const [userGalleryImages, setUserGalleryImages] = useState([]);
  const [uuid, setUuid] = useState("");
  const [galleryImage, setGalleryImage] = useState([]);
  // const [modalShow, setModalShow] = React.useState(false);

  const [userPreferences, setUserPreferences] = useState([]);
  const [userAttributes, setUserAttributes] = useState([]);
  const [userSocial, setUserSocial] = useState([]);
  const [uservideo, setUservideo] = useState([]);
  const [userabout, setUserabout] = useState([]);
  const [useridcards, setuseridcards] = useState([]);

  const [selected,setSelected] = useState(false)
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  //For Filter
  const [userRegional, setUserRegional] = useState([]);
  const [username, setUsername] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [cities, setcities] = useState("");
  const [selectedRegionalPartner, setSelectedRegionalPartner] = useState("");
  const [userCategories, setUserCategories] = useState();

  // const [selectedRow, setSelectedRow] = useState(null);
  // const [userGalleryImages, setUserGalleryImages] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [uuid,setUuid] = useState('')
  // const [galleryImage,setGalleryImage] = useState([])
  const [selectedUser, setSelectedUser] = useState(null);
  // const [modalShow, setModalShow] = React.useState(false);

  //modal
  function MyVerticallyCenteredModal({ uuid, ...props }) {
    const [gallery, setGallery] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const fetchGallery = useCallback(() => {
      get(API_URL.USERS.USER_GALLERY)
        .then((response) => {
          console.log(response.data);

          if (response?.data.length > 0) {
            setGallery(response.data);
            // setOriginalData(response.data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, []);

    console.log(gallery);

    const filteredImage = gallery.filter((images) => images.user_uuid === uuid);

    console.log(filteredImage);
    // setGalleryImage(filteredImage)

    const handleAddGallery = (event) => {
      const files = Array.from(event.target.files);
      const names = files.map((file) => file.name);

      // setGallery(files);

      if (uuid) {
        for (let i = 0; i < files.length; i++) {
          const formDataa = new FormData();
          formDataa.append("image", files[i]);
          formDataa.append("user_uuid", uuid);

          post(API_URL.USERS.USER_GALLERY, formDataa)
            .then((response) => {
              console.log(response);
              fetchGallery();
              toast.success("Image added succesfully", { autoClose: 1000 });
            })
            .catch((error) => {
              console.error(error);
              toast.error("Error adding image", { autoClose: 1000 });
            });
        }
      }
    };

    const handleImageClick = () => {
      document.getElementById("file-input").click();
    };

    const handleDeleteGallary = (id) => {
      console.log(id);

      if (uuid && id) {
        DELETE(API_URL.USERS.GALLERY_DELETE(uuid, id))
          .then((response) => {
            console.log(response);
            fetchGallery();
            toast.success("Deleted successfully", { autoClose: 1000 });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };

    useEffect(() => {
      fetchGallery();
    }, [setGallery]);

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mt-2" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Gallery Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {filteredImage?.length >= 0 &&
              filteredImage.map((img, index) => (
                <div
                  key={index}
                  className="image-container"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <img
                    className="image"
                    src={img.image}
                    alt={`gallery-img-${index}`}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                  />
                  {hoveredIndex === index && (
                    <button
                      onClick={() => handleDeleteGallary(img.id)}
                      className="hover-button"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  )}
                </div>
              ))}
            <div
              style={{ border: "1px dotted" }}
              className="d-flex flex-column align-items-center justify-content-center p-3 rounded"
            >
              <input
                id="file-input"
                type="file"
                style={{ display: "none" }}
                onChange={handleAddGallery}
              />
              <div
                className="d-flex flex-column align-items-center justify-content-center"
                onClick={handleImageClick}
                style={{ cursor: "pointer" }}
              >
                <p>Add image Gallery</p>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  useEffect(() => {
    fetchUsersList();
    fetchUserRoles();
    fetchCategory();
    fetchCountry();
    fetchStates();
    fetchCities();
    fetchHearAbout();
    setCsvData(data);
    fetchUserRegional();
    fetchUserVideo();
    fetchIdCardList();
  }, []);

  //console

  const handleDeleteConfirmation = (row) => {
    setRowToDelete(row);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(false);
    handleDeletedata(rowToDelete);
  };

  const handleProfileResponse = (profilesResponse) => {
    const profiles = profilesResponse?.data || [];
    console.log("Fetched User seprate Preferences:", profiles);
    setUserPreferences(profiles);
  };

  const handleAttributesResponse = (attributesResponse) => {
    const attributes = attributesResponse?.data || [];
    console.log("Fetched User Attributes:", attributes);
    setUserAttributes(attributes);
  };

  const handleSocialmediaResponse = (SocialResponse) => {
    const socialmedia = SocialResponse?.data || [];
    console.log("Fetched User Social Media:", socialmedia);
    setUserSocial(socialmedia);
  };

  const handleUservideoResponse = (videoResponse) => {
    const Uservideo = videoResponse?.data || [];
    console.log("Fetched User Video Media:", Uservideo);
    setUservideo(Uservideo);
  };

  const handleUseraboutResponse = (aboutResponse) => {
    const Userabout = aboutResponse?.data || [];
    console.log("Fetched User Video Media:", Userabout);
    setUserabout(Userabout);
  };

  // 	const fetchUsersList = useCallback(() => {
  // 		get(API_URL.USERS.GET_USERS)
  // 			.then((response) => {
  // 				if (response?.data.length >= 0) {
  // 					setData(response.data);
  // 					setOriginalData(response.data);
  // 				}
  // 			})
  // 			.catch((error) => {
  // 				console.error("Error:", error);
  // 			});
  // 	}, []);

  const fetchUsersList = useCallback(async () => {
    try {
      const usersResponse = await get(API_URL.USERS.GET_USERS);
      console.log(usersResponse.data);
      
      const reversedData = [...usersResponse.data].reverse();
      console.log(reversedData);
      
      const users = usersResponse?.data || [];

      setOriginalData(reversedData);
      setData(reversedData);

      const profilesResponse = await get(API_URL.PROFILE.GET_PROFILE);
      const profiles = profilesResponse?.data || [];
      handleProfileResponse(profilesResponse);

      const attributesResponse = await get(
        API_URL.USER_ATTRIBUTES.GET_USER_ATTRIBUTES
      );
      const attributes = attributesResponse?.data || [];
      handleAttributesResponse(attributesResponse);

      const socialmediaResponse = await get(
        API_URL.SOCIAL_MEDIA.GET_SOCIAL_MEDIA
      );
      const socialmedia = socialmediaResponse?.data || [];
      handleSocialmediaResponse(socialmediaResponse);

      const videoResponse = await get(API_URL.PROFILE.GET_PROFILE);
      const Uservideo = videoResponse?.data || [];
      handleUservideoResponse(videoResponse);

      const useraboutResponse = await get(API_URL.PROFILE.GET_PROFILE);
      const about = useraboutResponse?.data || [];
      handleUseraboutResponse(useraboutResponse);

      console.log("Fetched User Preferences:", profiles);
      setData(reversedData);
      setUserPreferences(profiles);
    } catch (error) {
      console.error("Error fetching users or preferences:", error);
    }
  }, []);

  const fetchUserRegional = useCallback(() => {
    get(API_URL.USER_REGIONAL_PARTNERS.GET_REGIONALS)
      .then((response) => {
        if (response?.data.length > 0) {
          setUserRegional(response.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchUserRoles = useCallback(() => {
    get(API_URL.USER_ROLE.USER_ROLE_GET)
      .then((response) => {
        if (response?.data.length > 0) setUserRoles(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchCategory = useCallback(() => {
    get(API_URL.USER_CATEGORY.USER_CATEGORY_GET)
      .then((response) => {
        if (response?.data.length > 0) setUserCategory(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchCountry = useCallback(() => {
    get(API_URL.COUNTRIES.COUNTRIES_GET)
      .then((response) => {
        if (response?.data.length > 0) setCountries(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchStates = useCallback(() => {
    get(API_URL.STATES.STATES_GET)
      .then((response) => {
        if (response?.data.length > 0) setStates(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchCities = useCallback(() => {
    get(API_URL.CITIES.CITIES_GET)
      .then((response) => {
        console.log(response.data);

        if (response?.data.length > 0) setcity(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchHearAbout = useCallback(() => {
    get(API_URL.HEAR_ABOUT_US.HEAR_ABOUT_US_GET)
      .then((response) => {
        if (response?.data.length > 0) setHearAboutus(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  //modal click event

  const fetchIdCardList = useCallback(() => {
    get(API_URL.IDCARDS.GET_IDCARDS)
      .then((response) => {
        if (response?.data.length > 0) {
          setuseridcards(response.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log("user id card", useridcards);

  const idcard = useridcards.map((id) => {
    return id.user_uuid;
  });

  const matcheduseridcard = useridcards.filter((id) => {
    // data.forEach(user => console.log('user.uuid', user.uuid));

    return data.some((user) => user.uuid === id.user_uuid);
  });

  console.log("matchedidcard", matcheduseridcard);

  const handleNavigateToProfile = (id) => {
    console.log("id of idprofile", matcheduseridcard);

    const url = `/idcards/idcardprofile/${id}`

    window.open(url, '_blank')

    // navigate(`/idcards/idcardprofile/${id}`);
  };

  const handleNavigate = () => {
    navigate(`${process.env.PUBLIC_URL}/users/new/home`);
  };

  const categoryToggle = () => {
    setCategoryModal(!categoryModal);
  };

  const handleEditdata = (row) => {
    console.log(row);

    navigate(`${process.env.PUBLIC_URL}/users/new/home`, {
      state: { userData: row },
    });
  };

  // const = DELETE(API_URL.USERS.DELETE_USER_FIREBASE(row?.firebase_user_id))

  const handleDeletedata = (row) => {
    console.log(row);

    if (row?.uuid) {
      DELETE(API_URL.USERS.DELETE_USER_FIREBASE(row?.firebase_user_id))
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.error("error_firebase", err);
        });
      DELETE(API_URL.USERS.USERS_DELETE(row?.uuid))
        .then((response) => {
          console.log("delete");

          toast.success("Deleted successfully", { autoClose: 1000 });
          fetchUsersList();
        })
        .catch((error) => {
          toast.error("Oops! something went wrong", { autoClose: 1000 });
        });
    }
  };

  const handleImageClick = (row) => {
    setUserGalleryImages(row.gallery); // Set the clicked row's data
    setUuid(row.uuid);
    setModalShow(true); // Show the modal
  };

  useEffect(() => {
    console.log("Uservideo State:", uservideo);
  }, [uservideo]);
  // const handleOpenModal = (user) => {
  //   console.log('Navigating to user details page with user:', user);
  //   navigate(`/users/usersdetails/${user.uuid}`, { state: { userData: user } });
  // };

  const handleOpenModal = (user) => {
    // Find the user's role from the `userRoles` list
    const userRole =
      userRoles.find((role) => role.id === user.user_role_id)?.role ||
      "No Role";

    // Add `userRole` to the user object
    const enrichedUser = {
      ...user,
      userRole,
      // Add userRole to the user object
    };

    console.log("Navigating to user details page with user:", enrichedUser);

    setSelectedUser(enrichedUser);

    // Pass the enrichedUser object in state during navigation
    navigate(`/userdetails/${user.uuid}`, {
      state: { userData: enrichedUser },
    });
  };
  const handleOpen = (user) => {
    // Log user data
    console.log('userdata', user);
    
    // Construct the URL
    const url = `${process.env.PUBLIC_URL}/userdetails/${user.uuid}`;
    
    // Open the URL in a new tab
    window.open(url, '_blank');
  };

  console.log("filter", selectedRegionalPartner);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const enrichedData = data.map((user) => {
    const preference = userPreferences.find(
      (pref) => pref.user_name === user.name
    );
    const attribute = userAttributes.find(
      (attr) => attr.user_name === user.name
    );
    const socialmedia = userSocial.find(
      (media) => media.user_name === user.name
    );
    const Videolink = uservideo.find((video) => video.user_name === user.name);
    const About = userabout.find((about) => about.user_name === user.name);

    // console.log('chek this',uservideo);

    const videoLinks = Videolink?.video_links || []; // Get the video_link array
    const firstVideoLink = videoLinks.length > 0 ? videoLinks[0] : null;
    // console.log('this is video',videoLinks);

    return {
      ...user,
      preference: preference?.preference_name || "No Preference",
      attribute: attribute?.attribute_name || "No Attribute",
      media: socialmedia?.link || null,
      Video: firstVideoLink || "No Video",
      about: About?.about || "No About",
    };
  });

  console.log(enrichedData);

  useEffect(() => {
    if (editUsersList) {
      navigate(`${process.env.PUBLIC_URL}/users/new/home`, {
        state: { userData: editUsersList },
      });
    }
  }, [editUsersList, navigate]);

  const sendMessage = (row, message) => {
    console.log(row);
    message = "jhbdhjabds,am";
    const url = `https://wa.me/${row.whatsapp_number}?text=${encodeURIComponent(
      message
    )}`;

    window.open(url, "_blank");
  };

  const handleSave = () => {
    const filteredData = originalData.filter((item) => {
      console.log(item);
      
      const roleMatches = userRole
        ? String(item.user_role_id) === String(userRole)
        : true;
      const genderMatches = gender ? item.gender === gender : true;
      const usernameMatches = username
        ? item.name
          ? item.name.includes(username)
          : false
        : true;
      const PhonennumberMatches = whatsappNumber
        ? item.phone_number
          ? item.phone_number.includes(whatsappNumber)
          : false
        : true;
      console.log("Role Matches:", roleMatches);

      console.log("item", item);

      const regionalPartner = userRegional.find(
        (partner) => partner.id == selectedRegionalPartner
      );
      const regionalPartnerUuid = regionalPartner
        ? regionalPartner.user_uuid
        : null;

      // Compare the item's uuid with the regional partner's uuid
      const regionalPartnerMatches = regionalPartnerUuid
        ? String(item.uuid) === String(regionalPartnerUuid)
        : true;
      
      // Fixing country and state filter comparison
      const countryMatches = country ? item.country_name === country : true;
      const cityMatches =
        cities.length > 0 ? cities.includes(item.city_id) : true;
      const stateMatches =
        state.length > 0 ? state.includes(item.state_id) : true;
      const categoryMatches = userCategories
        ? String(item.category_id) === String(userCategories)
        : true;

      return (
        roleMatches &&
        genderMatches &&
        usernameMatches &&
        PhonennumberMatches &&
        countryMatches &&
        stateMatches &&
        categoryMatches &&
        regionalPartnerMatches &&
        cityMatches
      );
    });


    console.log(filteredData);
    

    setData(filteredData);
    setCsvData(filteredData);
    categoryToggle();
    setUserRole("");
    setGender("");
    setUsername("");
    setWhatsappNumber("");
    setCountry("");
    setState("");
    setUserCategories("");
  };

  console.log(data);

  //selected row
  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
    setSelected(true)
  };

  console.log(selectedRows);
  console.log(selected);
  

  const handleCancel = () => {
    categoryToggle();
    setUserRole("");
    setGender("");
    setUsername("");
    setWhatsappNumber("");
    setCountry("");
    setState("");
    setUserCategories("");
    setData(originalData);
    setCsvData(originalData);
  };
 
  const handleExport = () => {
    console.log(data);
  
    // Helper function to reassign IDs in numeric order
    const reassignIds = (rows) =>
      rows.map((row, index) => ({
        ...row,
        id: index + 1, // Assign numeric IDs starting from 1
      }));
  
    if (selectedRows.length > 0) {
      console.log('Selected rows for export');
      const updatedRows = reassignIds(selectedRows); // Reassign IDs
      setCsvData(updatedRows); // Set updated data for CSV export
    } else {
      console.log('Exporting all data');
      const updatedData = reassignIds(data); // Reassign IDs
      setCsvData(updatedData); // Set updated data for CSV export
    }
  };

  const handleRefresh = () => {
    fetchUsersList();
  };

  const fetchUserVideo = useCallback(() => {
    get(API_URL.USERS.USER_VIDEO)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log(data);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between  p-4">
        <H4 attrH4={{ className: "text-muted m-0" }}>All Users Details</H4>
        <div className="button-group">
          <div>
            <button
              type="button"
              className="btn mx-2"
              style={{
                width: "30px",
                height: "38px",
                padding: "0",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleRefresh}
            >
              <i className="fa fa-refresh " style={{ fontSize: "18px" }}></i>
            </button>
            <Btn
              attrBtn={{ color: "primary", onClick: () => handleNavigate() }}
            >
              + Add New User
            </Btn>
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn ms-2",
                onClick: categoryToggle,
              }}
            >
              <span className="title">Filters</span>
            </Btn>
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn ms-2",
                onClick: () => handleExport(),
              }}
            >
              <CSVLink
                data={csvData}
                filename={"users-data.csv"}
                style={{ color: "white" }}
              >
                Export
              </CSVLink>
            </Btn>
          </div>
          <EditCommonModal
            isOpen={categoryModal}
            toggle={categoryToggle}
            title="Apply filters"
            onSave={handleSave}
            onCancel={handleCancel}
          >
            <FilterForm
              userRole={userRole}
              userRoles={userRoles}
              setUserRole={setUserRole}
              gender={gender}
              setGender={setGender}
              username={username}
              setUsername={setUsername}
              whatsappNumber={whatsappNumber}
              setWhatsappNumber={setWhatsappNumber}
              country={country}
              setCountry={setCountry}
              state={state}
              setState={setState}
              city={city}
              cities={cities}
              setcities={setcities}
              usercategories={userCategories}
              userCategory={userCategory}
              setUserCategories={setUserCategories}
              countries={countries}
              states={states}
              userRegional={userRegional}
              selectedRegionalPartner={selectedRegionalPartner}
              setSelectedRegionalPartner={setSelectedRegionalPartner}
            />
          </EditCommonModal>
        </div>
      </div>
      <div className="table-wrapper">
        <DataTable
          data={enrichedData}
          columns={userstableColumns(
            handleEditdata,
            handleDeleteConfirmation,
            userRoles,
            userCategory,
            countries,
            states,
            city,
            handleImageClick,
            sendMessage,
            handleOpen,
            handleOpenModal,
            hearAboutus,
            handleNavigateToProfile,
            matcheduseridcard
          )}
          striped={true}
          center={true}
          pagination
          selectableRows={true}
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggleDelet}
          fixedHeader
          fixedHeaderScrollHeight="80vh"
        />
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        galleryImages={userGalleryImages}
        uuid={uuid}
      />

      {/* {isModalOpen && (
        <UserDetailsModal
          isOpen={isModalOpen}
          user={selectedUser}
          onClose={handleCloseModal}
        />
      )} */}

      {/* 

        show={modalShow}
        onHide={() => setModalShow(false)}
        galleryImages={userGalleryImages}
        uuid={uuid}

      /> */}

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        onConfirm={handleConfirmDelete}
        message="Are you sure to delete?"
      />
    </>
  );
}
<ToastContainer autoClose={1000} closeOnClick />;

export default UsersTable;
