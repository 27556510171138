import React, { useCallback, useEffect, useState } from 'react';
import { DELETE, get, patch, post } from '../config'; 
import { API_URL } from '../config/api_urls';
import Modal from 'react-modal';
import './popupimage.css'; 
import { toast } from 'react-toastify';

const customModalStyles = {
  content: {
    top: '60%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxHeight: '80vh', 
    padding: '20px',
    overflowY: 'auto',   },
};

const EventBanner = () => {
  const [banners, setBanners] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [formData, setFormData] = useState(new FormData()); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [images, setImages] = useState([]); 
  const [selectedImage, setSelectedImage] = useState(null); // State to store image being edited
  const [editFormData, setEditFormData] = useState(new FormData());
  const [isViewModalOpen, setIsViewModalOpen] = useState(false); // New state for viewing larger image
  const [imageToView, setImageToView] = useState(null); 

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null); // Reset selected image when closing modal

  };

  const openViewModal = (imageUrl) => {
    setImageToView(imageUrl); // Set the image URL for larger view
    setIsViewModalOpen(true); // Open the view modal
  };

  const closeViewModal = () => {
    setIsViewModalOpen(false);
    setImageToView(null); // Reset the image for viewing
  };

  const fetchEventImages = useCallback(() => { 
    get(API_URL.POPUPIMAGE.GET_IMAGE)
      .then((response) => {
        if (response?.data.length >= 0) {
          setImages(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching event images:", error);
      });
  }, []);

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this image?")) return;

    try {
      const response = await DELETE(API_URL.POPUPIMAGE.DELETE_IMAGE(id));
      console.log("Delete response:", response);

      setImages(images.filter((image) => image.id !== id));
      toast.success("Image deleted successfully!",{autoClose:1000});

    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Failed to delete image. Please try again.");
    }
  };


  console.log('banner images:', images);

  const handleBannerUpload = (event) => {
    const files = Array.from(event.target.files);
    
    files.forEach(file => {
      formData.append('popupimage', file); 
    });

    setBanners((prevBanners) => [...prevBanners, ...files.map(file => URL.createObjectURL(file))]); 
  };

  const handleSubmit = async () => {
    if (banners.length === 0) {
      alert("Please upload at least one banner image before submitting.");
      return;
    }

    try {
      setUploading(true); 
      const response = await post(API_URL.POPUPIMAGE.POST_IMAGE, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log("API Response:", response); 

      // alert("Images uploaded successfully!");
      toast.success("Images uploaded successfully!",{autoClose:1000});

      setBanners([]); 
      formData.delete('popupimage'); 
      closeModal(); 
      fetchEventImages(); 

    } catch (error) {
      console.error("Error uploading images:", error);
      alert("Failed to upload images. Please try again.");
    } finally {
      setUploading(false); 
    }
  };

  const openEditModal = (image) => {
    setSelectedImage(image); // Set the selected image for editing
    setIsModalOpen(true); // Open the modal for editing
  };

  const handleEditUpload = (event) => {
    const file = event.target.files[0];
    editFormData.set('popupimage', file); // Append the new image to the form data
  };

  const handleEditSubmit = async () => {
    if (!selectedImage) return;

    try {
      const response = await patch(API_URL.POPUPIMAGE.PATCH_IMAGE(selectedImage.id), editFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log("Patch response:", response);
      toast.success("Image updated successfully!", { autoClose: 1000 });

      setEditFormData(new FormData()); // Reset the form
      closeModal();
      fetchEventImages(); // Refetch the images after the update
    } catch (error) {
      console.error("Error updating image:", error);
      alert("Failed to update image. Please try again.");
    }
  };

  useEffect(() => {
    fetchEventImages(); 
  }, [fetchEventImages]);

  return (
    <div className="event-banner-container">
      <h1 className="event-banner-header mt-3" style={{position:"relative",top:"10px"}}>Event Popup Images</h1>

      <button className="btn btn-primary" style={{position:'relative',left:"400px",top:"-40px"}} onClick={openModal}>
        Upload New Banners
      </button>

      {/* <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Upload Event Banners"
      >
        <h2>Upload Event Popup Images</h2>
        
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleBannerUpload}
          className="banner-upload-input"
        />
        {uploading && <p>Uploading images...</p>}
        
        <div className=" ">
        <button className="btn btn-primary" onClick={handleSubmit} disabled={uploading}>
          Submit Banners
        </button>
        <button className="btn btn-secondary" style={{position:"relative",left:"10px"}} onClick={closeModal} disabled={uploading}>
          Close
        </button>
        </div>
       
      </Modal> */}
       <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel={selectedImage ? "Edit Event Image" : "Upload Event Banners"} // Modal label
      >
        <h5>{selectedImage ? "Edit Event Image" : "Upload Event Popup Images"}</h5>

        <input
          type="file"
          accept="image/*"
          multiple={!selectedImage} // Allow multiple only if not editing
          onChange={selectedImage ? handleEditUpload : handleBannerUpload} // Different handler based on editing or uploading
          className="banner-upload-input"
        />
        {uploading && <p>Uploading images...</p>}

        <div className="button-group">
          <button
            className="btn btn-primary"
            onClick={selectedImage ? handleEditSubmit : handleSubmit}
            disabled={uploading}
            style={{position:"relative",left:"300px"}}
          >
            {selectedImage ? "Submit Changes" : "Submit Banners"}
          </button>
          <button className="btn btn-secondary" style={{position:"relative",left:"70px"}} onClick={closeModal} disabled={uploading}>
            Close
          </button>
        </div>
      </Modal>


      {/* <Modal
        isOpen={isViewModalOpen}
        onRequestClose={closeViewModal}
        style={customModalStyles}
        contentLabel="View Image"
      >
        <h5>Image Preview</h5>
        {imageToView && (
          <img src={imageToView} alt="Larger view" style={{ width: '100%', height: 'auto' }} />
        )}
        <button className="btn btn-secondary" onClick={closeViewModal}>Close</button>
      </Modal> */}
      {isViewModalOpen && (
  <div 
    className="view-image-overlay"
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}
  >
    <div 
      className="view-image-container"
      style={{
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        width: '60%',
        height: '80%',
        overflow: 'auto',
        position: 'relative',
      }}
    >
      {/* <h5>Image Preview</h5> */}
      {imageToView && (
        <img 
          src={imageToView} 
          alt="Larger view" 
          style={{ width: '850px', height: '500px',position:"relative",top:"40px" }} 
        />
      )}
      <button 
        className="btn " 
        onClick={closeViewModal} 
        style={{ marginTop: '10px', position: 'absolute', top: '0px', right: '10px',fontSize:"18px" }}
      >
       <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>
)}



      <div className="event-banner-gallery">
        {banners.map((banner, index) => (
          <div key={index} className="banner-item">
            <img src={banner} alt={`Banner ${index}`} className="banner-image" />
          </div>
        ))}
      </div>

      <div className="event-images-gallery">
  {/* <h2>All Event Images</h2> */}
  <div className="event-images">
  {images.map((image, index) => (
    <div key={index} className="image-item">
      <div className="image-wrapper" style={{position:"relative",left:"-200px"}}>
        <img src={image.popupimage} alt={`Event Image ${index}`} className="event-image" style={{height:"250px",width:"350px",objectFit:"cover"}}  onClick={() => openViewModal(image.popupimage)} 
 />
        <button className="delete-icon" onClick={() => handleDelete(image.id)}>
          <i className="fas fa-trash"></i> 
        </button>
        <button className="edit-icon" onClick={() => openEditModal(image)} >
                  <i className="fas fa-edit"></i> 
                </button>
      </div>
    </div>
  ))}
</div>

</div>

    </div>
  );
};

export default EventBanner;
