import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ConfirmationModal = ({ isOpen, toggle, onConfirm, message }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader  toggle={toggle}>Confirm Delete</ModalHeader>
      <ModalBody style={{fontWeight:"bolder"}}>{message}</ModalBody>
      <ModalFooter>
        <Button style={{background:"blue"}} onClick={toggle}>
          Cancel
        </Button>
        <Button color="primary" onClick={onConfirm}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
