import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { eventcategorytabledata, eventcategorytableColumns } from '../common/mockData';
import { Btn, H4 } from '../../../../AbstractElements';
import EditCommonModal from '../common/EditCommonModal';
import NewJobCategory from './NewJobCategory';
import { API_URL } from '../config/api_urls';
import { DELETE, get, patch, post } from '../config';
import { toast, ToastContainer } from 'react-toastify';
import ConfirmationModal from '../common/Confiemationdeletemodal'; 


function EventCategory() {
  const [data, setData] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [eventcategoryModal,setEventcategoryModal] = useState(false);
  const [editEventCategory,setEditEventCategory] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); 
  const [rowToDelete, setRowToDelete] = useState(null);


  useEffect(() => {
    fetchEventCategory();
  }, [])
  
  const fetchEventCategory = useCallback(() => {
    get(API_URL.EVENT_CATEGORY.GET_EVENT_CATEGORY)
      .then((response) => {
        if (response?.data.length >= 0)
          setData(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  


  const jobCtegroryToggle =()=>{
    setEventcategoryModal(!eventcategoryModal)
  }

  const editmodalToggle = (row)=>{
    setEventcategoryModal(!eventcategoryModal)
    setEditEventCategory(row)
  }
  
  const handleDeleteConfirmation = (row) => {
    setRowToDelete(row);
    setIsDeleteModalOpen(true); 
  };
  
  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(false); 
    handleDeletedata(rowToDelete);
  };

     
  const handleSave = async (formData) => {
		if (formData?.id) {
			patch(API_URL.EVENT_CATEGORY.PATCH_EVENT_CATEGORY(formData?.id), formData)
				.then((response) => {
					toast.success("Updated successfully",{autoClose:1000});
          setEventcategoryModal(!eventcategoryModal)
          setEditEventCategory(null)
          fetchEventCategory();
				})
				.catch((error) => {
					console.error("Error updating data:", error);
					toast.error("Oops! something went wrong",{autoClose:1000});
				});
		}else{
      try {
        await post(API_URL.EVENT_CATEGORY.POST_EVENT_CATEGORY, formData);
        toast.success("Event Category created successfully",{autoClose:1000});
        setEventcategoryModal(!eventcategoryModal)
        setEditEventCategory(null)
        fetchEventCategory();
      } catch (error) {
        console.error("Error saving data:", error);
        toast.error("Oops! something went wrong",{autoClose:1000});
      }
    }
		
	};


  const handleDeletedata = (row)=>{
    if(row?.id){
     DELETE(API_URL.EVENT_CATEGORY.DELETE_EVENT_CATEGORY(row?.id))
     .then((response) => {
       toast.success("Deleted successfully",{autoClose:1000});
       fetchEventCategory();
     })
     .catch((error) => {
       toast.error("Oops! something went wrong",{autoClose:1000});
     });
    }
   }



  
  const handleCancel = () => {
    setEventcategoryModal(!eventcategoryModal)
    setEditEventCategory(null)
     };



  return (
    <>
       <div>
         <div className={`d-flex align-items-center justify-content-between  p-4`}>
                      <H4 attrH4={{ className: 'text-muted m-0' }}>Event Category</H4>
                      <Btn attrBtn={{ color: 'primary', onClick:jobCtegroryToggle}}>+ Add New Event Category </Btn>
                      
                         <NewJobCategory
                         isOpen={eventcategoryModal}
                         toggle={jobCtegroryToggle}
                         title="Event Category"
                         eventCategoryData={editEventCategory}
                         onSave={handleSave}
                         onCancel={handleCancel}
                         />
                      
                  </div>
      <div>
         <DataTable
                  data={data}
                  columns={eventcategorytableColumns(editmodalToggle,handleDeleteConfirmation)}
                  striped={true}
                  center={true}
                  pagination
                  selectableRows={false}
                  clearSelectedRows={toggleDelet}
              />
      </div>
       </div>

       <ConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        onConfirm={handleConfirmDelete}
        message="Are you sure to delete?"
      />


    </>
   
  )
}

export default EventCategory