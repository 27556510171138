import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { dummybusinessdata, businesstableColumns } from "../common/mockData";
import { Btn, H4 } from "../../../../AbstractElements";
import { Search, X } from "react-feather";
import { Input } from "reactstrap";
import "../../styles/order.css";
import EditCommonModal from "../common/EditCommonModal";
import OrderFilter from "./OrderFilter";
import { useNavigate } from "react-router";
import { API_URL } from "../config/api_urls";
import { DELETE, get } from "../config";
import { toast, ToastContainer } from "react-toastify";

function Order() {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [date, setDate] = useState("");
  const [orderFilterModal, setOrderFilterModal] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [editOrder, setEditOrder] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    if (editOrder) {
      navigate(`${process.env.PUBLIC_URL}/business-section/order/edit/home`, {
        state: { orderData: editOrder },
      });
    }
  }, [editOrder, navigate]);

  const fetchOrders = useCallback(() => {
    get(API_URL.ORDERS.GET_ORDERS)
      .then((response) => {
        console.log(response.data);
        
        if (response?.data.orders.length >= 0) {
          setData(response.data.orders);
          setOriginalData(response.data.orders);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log(data);
  

  const handleInput = (event) => {
    setSearchValue(event.target.value);
  };

  const doSearch = (searchValue) => {
    const filteredData = searchValue
      ? originalData.filter(
          (item) =>
            item.order_id.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
        )
      : data;
    setData(filteredData);
  };

  const handleSearch = () => {
    doSearch(searchValue);
  };

  const handleClear = () => {
    doSearch("");
    setSearchValue("");
    setData(originalData);
  };

  const handleDate = (event) => {
    setDate(event.target.value);
  };

  const dateSearch = () => {
    const inputDate = new Date(date).toDateString();

    const searchedData = date
      ? data.filter((item) => {
          const formatedItemDate = item.order_date
            .split("-")
            .reverse()
            .join("-");
          const itemDate = new Date(formatedItemDate).toDateString();
          return itemDate === inputDate;
        })
      : data;

    setData(searchedData);
  };

  const orderToggle = () => {
    setOrderFilterModal(!orderFilterModal);
  };

  const handleSave = () => {
    orderToggle();
    const filteredData = data.filter((item) => {
      return (
        (orderStatus ? item.delivery_status === orderStatus : true) &&
        (paymentStatus ? item.payment_status === paymentStatus : true)
      );
    });
    setData(filteredData);
  };

  const handleCancel = () => {
    orderToggle();
    setOrderStatus("");
    setPaymentStatus("");
    setData(originalData);
  };

  const handleEditdata = (row) => {
    setEditOrder(row);
  };

  const handleRefresh = () => {
    fetchOrders();
  };

  const handleDeletedata = (row) => {
    if (row?.id) {
      DELETE(API_URL.ORDERS.DELETE_ORDER(row?.id))
        .then((response) => {
          toast.success("Deleted successfully", { autoClose: 1000 });
          fetchOrders();
        })
        .catch((error) => {
          toast.error("Oops! something went wrong", { autoClose: 1000 });
        });
    }
  };

  return (
    <>
      <div>
        <div
          className={`d-flex align-items-center justify-content-between p-4`}
        >
          <H4 attrH4={{ className: "text-muted m-0" }}>All Orders</H4>
          <div className="btns-grp">
            <div className="faq-form d-flex">
              <button
                type="button"
                className="btn mx-2"
                style={{
                  width: "30px",
                  height: "38px",
                  padding: "0",
                  borderRadius: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleRefresh}
              >
                <i className="fa fa-refresh " style={{ fontSize: "18px" }}></i>
              </button>
              <Input
                className="form-control pe-5 mx-2"
                type="text"
                placeholder="Search by order Id"
                value={searchValue}
                onChange={handleInput}
              />
              {searchValue ? (
                <X className="clear-icon" onClick={handleClear} />
              ) : null}
              <Search className="search-icon" onClick={handleSearch} />
            </div>
            <div className="d-flex align-items-center justify-content-center mx-2">
              <Input
                className="form-control digits"
                type="date"
                onChange={handleDate}
              />
              <Btn
                attrBtn={{
                  color: "primary",
                  height: "40px",
                  onClick: dateSearch,
                }}
              >
                Search
              </Btn>
            </div>
            <div>
              <Btn
                attrBtn={{
                  color: "primary",
                  width: "100%",
                  onClick: orderToggle,
                }}
              >
                Filter
              </Btn>
              <EditCommonModal
                isOpen={orderFilterModal}
                toggle={orderToggle}
                title="Filter"
                onSave={handleSave}
                onCancel={handleCancel}
              >
                <OrderFilter
                  orderStatus={orderStatus}
                  setOrderStatus={setOrderStatus}
                  paymentStatus={paymentStatus}
                  setPaymentStatus={setPaymentStatus}
                />
              </EditCommonModal>
            </div>
          </div>
        </div>
        <div>
          <DataTable
            data={data}
            columns={businesstableColumns(handleEditdata, handleDeletedata)}
            striped={true}
            center={true}
            pagination
            selectableRows={false}
            clearSelectedRows={toggleDelet}
          />
        </div>
      </div>
    </>
  );
}

export default Order;
