import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { appliedEventsColumn } from "../common/mockData";
import { API_URL } from "../config/api_urls";
import { DELETE, get, patch, post } from "../config";
import { useParams } from "react-router";
import { Btn, H4 } from "../../../../AbstractElements";
import { CSVLink } from "react-csv";
import { toast, ToastContainer } from "react-toastify";
import AddApplication from "./AddApplication";

function AppliedEvents() {
  const { uuid } = useParams();

  console.log(uuid);

  const [toggleDelet, setToggleDelet] = useState(false);
  const [data, setData] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [csvData, setCsvData] = useState(data);
  const [filterModal, setFilterModal] = useState(false);
  const [applicationModal, setApplicationModal] = useState(false);
  const [editApplicationData, setEditApplicationData] = useState();

  const fetchEvents = useCallback(() => {
    get(API_URL.EVENTS.GET_COUNT_DETAILS(uuid))
      .then((response) => {
        if (response?.data.applications.length > 0)
          console.log(response.data.applications);

        setData(response.data.applications);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchUserRoles = useCallback(() => {
    get(API_URL.USER_ROLE.USER_ROLE_GET)
      .then((response) => {
        if (response?.data.length > 0) setUserRoles(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log(data);

  const handleExport = () => {
    setCsvData(data);
  };
  console.log(csvData);
  

  const handleNavigate = () => {};

  const categoryToggle = () => {};

  const editmodalToggle = (row) => {
    console.log(row);

    setEditApplicationData(row);
    setApplicationModal(!applicationModal);
  };

  const handleCancel = () => {
    setApplicationModal(!applicationModal);
  };

  const handleSave = async (formData) => {
    console.log(formData);

    const values = {
      id: formData?.id || "",
      user: formData?.user_name?.value,
      user_role: formData?.user_role_name?.value,
      event_uuid: formData?.event?.value,
      status: formData?.status,
    };

    console.log("VALUES", values);

    if (formData?.id) {
      console.log(formData);

      patch(
        API_URL.EVENT_APPLICATION.PATCH_EVENT_APPLICATION(formData?.id),
        values
      )
        .then((response) => {
          console.log(response);

          toast.success("Updated successfully",{autoClose:1000});
          setApplicationModal(!applicationModal);
          setEditApplicationData(null);
          fetchEvents();
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          toast.error("Oops! something went wrong",{autoClose:1000});
        });
    } else {
      try {
        await post(API_URL.EVENT_APPLICATION.POST_EVENT_APPLICATION, values);
        console.log(values);

        toast.success("Application Submited successfully",{autoClose:1000});
        setApplicationModal(!applicationModal);
        setEditApplicationData(null);
        fetchEvents();
      } catch (error) {
        console.error("Error saving data:", error);
        toast.error("Oops! something went wrong",{autoClose:1000});
      }
    }
  };

  const handleDeletedata = (row) => {
    console.log(row);

    if (row?.id) {
      DELETE(API_URL.EVENT_APPLICATION.DELETE_EVENT_APPLICATION(row?.id))
        .then((response) => {
          toast.success("Deleted successfully",{autoClose:1000});
          fetchEvents();
        })
        .catch((error) => {
          toast.error("Oops! something went wrong",{autoClose:1000});
        });
    }
  };

  const applicationToggle = () => {
    setApplicationModal(!applicationModal);
  };

  const handleCancelFilter = () => {
    filterToggle();
    // setData(originalData);
  };

  const filterToggle = () => {
    setFilterModal(!filterModal);
  };

  useEffect(() => {
    fetchEvents();
    fetchUserRoles();
    setCsvData(data);
  }, []);

  return (
    <div>
      <div>
        <div className="d-flex align-items-center justify-content-between  p-4">
          <H4 attrH4={{ className: "text-muted m-0" }}>
            Interested Applications
          </H4>
          <div className="button-group">
            <Btn attrBtn={{ color: "primary", onClick: applicationToggle }}>
              + New Application
            </Btn>
            <AddApplication
              isOpen={applicationModal}
              toggle={applicationToggle}
              title="Application"
              applicationData={editApplicationData}
              onSave={handleSave}
              onCancel={handleCancel}
            />
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn mx-2",
                onClick: categoryToggle,
              }}
            >
              <span className="title">Filters</span>
            </Btn>
            <CSVLink
              data={csvData}
              filename="Applications-data.csv"
              className="btn btn-primary"
              onClick={handleExport}
            >
              Export
            </CSVLink>
          </div>
        </div>
        <div className="table-wrapper">
          <DataTable
            data={data}
            columns={appliedEventsColumn(
              userRoles,
              handleDeletedata,
              editmodalToggle
            )}
            striped={true}
            center={true}
            pagination
            selectableRows={true}
            clearSelectedRows={toggleDelet}
          />
        </div>
      </div>
    </div>
  );
}

export default AppliedEvents;
