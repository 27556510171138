import React, { useCallback, useEffect, useState } from 'react';
import EditCommonModal from '../common/EditCommonModal';
import { get, post } from '../config';
import { API_URL } from '../config/api_urls';
import { FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';

function NewUserSocialMedia({ editUserSocialMediaData, isOpen, toggle, title, onSave, onCancel }) {
  const [users, setUsers] = useState([]);
  const [socialMedia, setSocialMedia] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const [formDataConfig, setFormDataConfig] = useState({
    initialState: {
      id: null,
      user_name: null,
      social_media_name: null,
      link: ""
    },
    fields: [
      {
        name: "user_name",
        label: "User",
        type: "select",
        options: [],
        required: true,
      },
      {
        name: "social_media_name",
        label: "Social Media",
        type: "select",
        options: [],
        required: true,
      },
      {
        name: "link",
        label: "Social Media Link",
        type: "text",
        required: true,
      }
    ],
  });

  useEffect(() => {
    fetchUsers();
    fetchSocialMedia();
  }, []);

 

  useEffect(() => {
    if (editUserSocialMediaData) {
      setIsEditing(true);
      setFormDataConfig(value => ({
        ...value,
        initialState: {
          id: editUserSocialMediaData.id,
          user_name: users?.find(i => i.label === editUserSocialMediaData?.user_name),
          social_media_name: socialMedia?.find(i => i.label === editUserSocialMediaData?.socialmedia_name ),
          link: editUserSocialMediaData.link || ""
        }
      }));
    } else {
      setIsEditing(false);
      setFormDataConfig(value => ({
        ...value,
        initialState: {
          id: null,
          user_name: "",
          social_media_name: "",
          link: ""
        },
      }));
    }
  }, [editUserSocialMediaData]);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await get(API_URL.USERS.GET_USERS);
      const userOptions = response?.data?.map((i) => ({
        label: i.name,
        value: i.uuid,
      }));
      userOptions.unshift({ label: "Select User", value: "" });
      setUsers(userOptions);
      updateFieldOptions("user_name", userOptions);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, []);

  const fetchSocialMedia = useCallback(async () => {
    try {
      const response = await get(API_URL.SOCIAL_MEDIA_NAME.GET_SOCIALMEDIA_NAME);
      const socialMediaOptions = response?.data?.map((i) => ({
        label: i.name,
        value: i.id,
      }));
      socialMediaOptions.unshift({ label: "Select Social Media", value: "" });
      setSocialMedia(socialMediaOptions);
      updateFieldOptions("social_media_name", socialMediaOptions);
    } catch (error) {
      console.error("Error fetching social media:", error);
    }
  }, []);

  const updateFieldOptions = (fieldName, options) => {
    setFormDataConfig((prevConfig) => ({
      ...prevConfig,
      fields: prevConfig.fields.map((field) =>
        field.name === fieldName ? { ...field, options } : field
      ),
    }));
  };

  const handleSave = async () => {
   
    onSave(formDataConfig.initialState);

  };

  const handleCancel = () => {
    onCancel();
  };

  const setUser = (e) => {
    setFormDataConfig(prevConfig => ({
      ...prevConfig,
      initialState: {
        ...prevConfig.initialState,
        user_name: e
      }
    }));
  };

  
  const handleSocialMediaChange = (e) => {
    setFormDataConfig(prevConfig => ({
      ...prevConfig,
      initialState: {
        ...prevConfig.initialState,
        social_media_name: e
      }
    }));
  };

  const handleSocialMediaLinkChange = (e) => {
    setFormDataConfig(prevConfig => ({
      ...prevConfig,
      initialState: {
        ...prevConfig.initialState,
        link: e.target.value
      }
    }));
  };

  return (
    <EditCommonModal
      isOpen={isOpen}
      toggle={toggle}
      title={`${isEditing ? 'Edit' : 'Add'} ${title}`}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <div className='mb-4'>
        <FormGroup>
          <Label className="col-form-label">Select User</Label>
          <Select
            value={formDataConfig.initialState.user_name}
            onChange={setUser}
            options={formDataConfig.fields.find(i => i.name === "user_name").options}
            className="js-example-basic-single col-sm-12"
          />
          <Label className="col-form-label mt-3">Select Social Media</Label>
          <Select
            value={formDataConfig.initialState.social_media_name}
            onChange={handleSocialMediaChange}
            options={formDataConfig.fields.find(i => i.name === "social_media_name").options}
            className="js-example-basic-single col-sm-12"
          />
          <Label className="col-form-label mt-3">Social Media Link</Label>
          <Input
            type="text"
            value={formDataConfig.initialState.link}
            onChange={handleSocialMediaLinkChange}
            placeholder="Enter social media link"
          />
        </FormGroup>
      </div>
    </EditCommonModal>
  );
}

export default NewUserSocialMedia;
