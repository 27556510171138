import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import FormCommon from "../common/FormCommon";
import { get, patch, post } from "../config";
import { API_URL } from "../config/api_urls";
import { toast, ToastContainer } from "react-toastify";
import { constructFormData } from "../config/imageHandle";
import ReactQuill from "react-quill";

const AddMarketPlace = () => {
  const location = useLocation();
  const marketPlaceData = location.state?.marketplace;

  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);
  const [productSize, setProductSize] = useState([]);
  console.log("productSize ", productSize);
  const [productCategory, setProductCategory] = useState([]);

  const [formDataConfig, setFormDataConfig] = useState({
    initialState: {
      uuid: null,
      user_uuid: "",
      featured_image: "",
      title: "",
      subtitle: "",
      size_value: "",
      category_name: "",
      price: "",
      sale_price: "",
      gst: "",
      stock_quantity: "",
      status: "",
      description: "",
      is_featured: "",
      active: "",
    },
    fields: [
      {
        name: "user_uuid",
        label: "User",
        type: "select",
        options: [],
        required: true,
      },
      {
        name: "featured_image",
        label: "Featured Image",
        type: "file",
        required: true,
      },
      {
        name: "title",
        label: "Title",
        type: "text",
        placeholder: "Enter Title",
        required: true,
      },
      {
        name: "subtitle",
        label: "Subtitle",
        type: "text",
        placeholder: "Enter subtitle",
        required: true,
      },
      {
        name: "size_value",
        label: "Size",
        type: "multiselect",
        options: [],
        required: true,
      },
      {
        name: "category_id",
        label: "Category",
        type: "select",
        options: [],
        required: false,
      },
      {
        name: "price",
        label: "Price",
        type: "number",
        placeholder: "Enter Price",
        required: false,
      },
      {
        name: "sale_price",
        label: "Sale Price",
        type: "number",
        placeholder: "Enter Sale Price",
        required: false,
      },
      {
        name: "gst",
        label: "GST %",
        type: "number",
        placeholder: "Enter GST %",
        required: false,
      },
      {
        name: "stock_quantity",
        label: "Stock",
        type: "number",
        placeholder: "Enter Stock Quantity",
        required: false,
      },
      {
        name: "status",
        label: "Status",
        type: "select",
        options: [
          { label: "select status", disabled: true },
          { value: "available", label: "Available" },
          { value: "unavailable", label: "Unavailable" },
        ],
        required: false,
      },
      {
        name: "description",
        label: "Description",
        type: "quill-editor",
        placeholder: "Enter Description",
        required: true,
      },
      {
        name: "is_featured",
        label: "Featured",
        type: "checkbox",
        required: false,
      },
      {
        name: "active",
        label: "Active",
        type: "checkbox",
        required: false,
      },
    ],
    imageField: "image",
  });

  // console.log(marketPlaceData);

  useEffect(() => {
    if (marketPlaceData) {
      console.log("marketPlaceData ", marketPlaceData);
      console.log("productSize: ", productSize);

      const filteredSizes = productSize
        .filter((i) => marketPlaceData.sizes.includes(i.label))
        .map((i) => i.value);

      console.log("Filtered size values: ", filteredSizes);

      setFormDataConfig((value) => ({
        ...value,
        initialState: {
          ...marketPlaceData,
          size_value: filteredSizes,
          category_name: marketPlaceData?.category_name,
        },
      }));
      setIsEditing(true);
    } else {
      setIsEditing(false);
      setFormDataConfig((value) => ({
        ...value,
        initialState: {
          uuid: null,
          user_uuid: "",
          profile_image: "",
          cover_image: "",
          title: "",
          subtitle: "",
          size_value: "",
          category_id: "",
          price: "",
          sale_price: "",
          gst: "",
          stock_quantity: "",
          status: "",
          description: "",
          is_featured: false,
          active: false,
        },
      }));
    }
  }, [marketPlaceData, productSize]);

  useEffect(() => {
    fetchUsers();
    fetchMarketcategory();
    fetchSize();
  }, []);

  const handleNavigateToMarketPlace = () => {
    navigate(`${process.env.PUBLIC_URL}/market-place/home`);
  };

  //users dropdown
  const fetchUsers = useCallback(async () => {
    try {
      const response = await get(API_URL.USERS.GET_USERS);
      const user_ids = response?.data?.map((i) => ({
        label: i.name,
        value: i.uuid,
      }));
      user_ids.unshift({ label: "Select user", value: "" });
      if (user_ids.length > 0) {
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "user_uuid" ? { ...field, options: user_ids } : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  // market category dropdown
  const fetchMarketcategory = useCallback(async () => {
    try {
      const response = await get(API_URL.MARKET_CATEGORY.GET_MARKET_CATEGORY);
      console.log(response);

      const market_category = response?.data?.map((i) => ({
        label: i.name,
        value: i.id,
      }));
      market_category.unshift({ label: "Select product category", value: "" });
      if (market_category.length > 0) {
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "category_id"
              ? { ...field, options: market_category }
              : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  // size dropdown
  const fetchSize = useCallback(async () => {
    try {
      const response = await get(API_URL.MARKET_SIZES.GET_MARKET_SIZES);
      const size_ids = response?.data?.map((i) => ({
        label: i.size,
        value: i.id,
      }));
      console.log(size_ids);

      setProductSize(size_ids);
      size_ids.unshift({ label: "Select size", value: "" });
      if (size_ids.length > 0) {
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "size_value"
              ? { ...field, options: size_ids }
              : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const onSave = (formData) => {
    console.log("formData ", formData);
    const values = {
      uuid: formData?.uuid || "",
      user_uuid: formData?.user_uuid,
      size_ids: formData?.size_value,
      category_id: formData?.category_id,
      featured_image: formData?.featured_image,
      description: formData?.description,
      price: formData?.price,
      sale_price: formData?.sale_price,
      gst: formData?.gst,
      stock_quantity: formData?.stock_quantity,
      title: formData?.title,
      subtitle: formData?.subtitle,
      is_featured: formData?.is_featured,
      active: formData?.active,
    };
    console.log("values ", values);

    const data = constructFormData(values, ["featured_image"]);

    console.log(data);

    if (formData?.uuid) {
      console.log(formData);

      patch(API_URL.MARKET_PRPDUCTS.PATCH_MARKET_PRPDUCTS(formData?.uuid), data)
        .then((response) => {
          console.log(response);

          toast.success("Updated successfully", { autoClose: 1000 });
          handleNavigateToMarketPlace();
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          toast.error("Oops! something went wrong", { autoClose: 1000 });
        });
    } else {
      post(API_URL.MARKET_PRPDUCTS.POST_MARKET_PRPDUCTS, data)
        .then((response) => {
          console.log(response);

          toast.success("Product added successfully", { autoClose: 1000 });
          handleNavigateToMarketPlace();
        })
        .catch((error) => {
          console.error("Error saving data:", error);
          toast.error("Oops! something went wrong", { autoClose: 1000 });
        });
    }
  };

  return (
    <div>
      <FormCommon
        title={`${marketPlaceData ? "Edit" : "Add"} Marketplace`}
        formDataConfig={formDataConfig}
        onSave={onSave}
        // editMode={!!formDataConfig?.initialState?.id}
      />
    </div>
  );
};

export default AddMarketPlace;
