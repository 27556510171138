import React, { useCallback, useEffect, useState } from 'react'
import FormCommon from '../common/FormCommon';
import EditCommonModal from '../common/EditCommonModal';
import { FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';
import { get } from '../config';
import { API_URL } from '../config/api_urls';

function NewUserAttribute({editUserAttributeData,isOpen,toggle,title,onSave,onCancel}) {

    const [isEditing,setIsEditing] = useState(false);
    const [users,setUsers] = useState([]);
    const [attributes,setAttributes] = useState([])
    const [formDataConfig, setFormDataConfig] = useState({ 
            initialState: {
              id: null,
              user_name: "",
              attribute_name: "",
              value: ""
            },
            fields: [
              {
                name: "user_name",
                label: "Users",
                type: "select",
                options:[],
                required: true,
              },
              {
                name: "attribute_name",
                label: "Select Attribute",
                type: "select",
                options:[],
                required: true,
              },
              {
                name: "value",
                label: "Value",
                type: "number",
                placeholder: "Enter value",
                required: true,
              },
            ],
          });

          useEffect(() => {
            if (editUserAttributeData) {
              setIsEditing(true);
              setFormDataConfig(value => ({
                ...value,
                initialState: {
                  ...editUserAttributeData,
                  user_name : users?.find(i=>i.label === editUserAttributeData?.user_name),
                  attribute_name : attributes?.find(i=>i.label === editUserAttributeData?.attribute_name)
                }
              }));
            } else {
              setIsEditing(false);
              setFormDataConfig(value => ({
                ...value,
                initialState: {
                  id: null,
                  user_name: '',
                  attribute_name: ''
                },
              }));
            }
          }, [editUserAttributeData]); 


          useEffect(()=>{
            fetchUsers();
            fetchAttributes();
          },[])

          console.log('isediting',isEditing);
          

  //user dropdown
	const fetchUsers = useCallback(async () => {
		try {
			const response = await get(API_URL.USERS.GET_USERS);
      console.log(response);
      
			const users_value = response?.data?.map((i) => ({
				label: i.name,
				value: i.uuid,
			}));
			users_value.unshift({ label: "Select user", value: "" });
			if (users_value.length > 0) {
        setUsers(users_value);
				setFormDataConfig((prevConfig) => ({
					...prevConfig,
					fields: prevConfig.fields.map((field) =>
						field.name === "user_name" ? { ...field, options: users_value } : field
					),
				}));
			}

		} catch (error) {
			console.error("Error:", error);
		}
	}, []);



  //attribute dropdown
	const fetchAttributes = useCallback(async () => {
		try {
			const response = await get(API_URL.ATTRIBUTES.GET_ATTRIBUTES);
      console.log(response);
      
			const attributes_values = response?.data?.map((i) => ({
				label: i.attribute_name,
				value: i.id,
			}));
			attributes_values.unshift({ label: "Select Attribute", value: "" });
			if (attributes_values.length > 0) {
        setAttributes(attributes_values);
				setFormDataConfig((prevConfig) => ({
					...prevConfig,
					fields: prevConfig.fields.map((field) =>
						field.name === "attribute_name" ? { ...field, options: attributes_values } : field
					),
				}));
			}

		} catch (error) {
			console.error("Error:", error);
		}
	}, []);

  //ATTRIBUTES


          
      
    const handleSave = () => {
      onSave(formDataConfig.initialState);
    };
          
    const handleCancel = () => {
      onCancel();   
    };  
    
    
  
    const setUser = (e) => {
      setFormDataConfig({
        ...formDataConfig,
        initialState: {
          ...formDataConfig.initialState,
          user_name: e
        }
      })
    }

    const setAttribute = (e) => {
      setFormDataConfig({
        ...formDataConfig,
        initialState: {
          ...formDataConfig.initialState,
          attribute_name: e
        }
      })
    }

    const setValue = (e) => {
      setFormDataConfig({
        ...formDataConfig,
        initialState: {
          ...formDataConfig.initialState,
          value: e.target.value
        }
      })
    }


  return (
    <EditCommonModal
    isOpen={isOpen}
    toggle={toggle}
    title={`${isEditing ? 'Edit' : 'Add'} ${title}`}
    onSave={handleSave}
    onCancel={handleCancel}
    formDataConfig={formDataConfig}
    > 
       <div className='mb-4'>
       <Label className="col-form-label">Users</Label>
				<Select
					value={formDataConfig?.initialState?.user_name}
					onChange={setUser}
					options={formDataConfig?.fields?.find(i => i.name === "user_name")?.options}
					className="js-example-basic-single col-sm-12"
					/>

         <Label className="col-form-label">Attributes</Label>
				<Select
					value={formDataConfig?.initialState?.attribute_name}
					onChange={setAttribute}
					options={formDataConfig?.fields?.find(i => i.name === "attribute_name")?.options}
					className="js-example-basic-single col-sm-12"
					/> 

				<FormGroup>
					<Label className="col-form-label pt-0" >Value</Label>
					<Input value={formDataConfig.initialState.value} onChange={setValue} className="form-control" type="text" placeholder="Enter value" />

				</FormGroup>
				
			</div>

    </EditCommonModal>
  )
}

export default NewUserAttribute