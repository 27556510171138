import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { get, post } from "../config";
import { API_URL } from "../config/api_urls";

const UserNotification = () => {
  const [users, setUsers] = useState([]); // Holds the users list
  const [formData, setFormData] = useState({
    name: "",
    firebase_user_id: "",
    // phone_number:"",
    // email:"",
    title: "",
    body: "",
  });

  // Fetch the users list
  const fetchUsersList = useCallback(() => {
    get(API_URL.USERS.GET_USERS)
      .then((response) => {
        if (response?.data?.length >= 0) {
          setUsers(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  // const post = async (url, payload) => {
  //   try {
  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(payload),
  //     });
  //     console.log('response',response);
  //     console.log('payload',payload);

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }
  //     return response.json();
      
  //   } catch (error) {
  //     console.error("Error in post request:", error);
  //     throw error;
  //   }
  // };
  

  useEffect(() => {
    fetchUsersList();
  }, [fetchUsersList]);

  // Handle dropdown change for the user
  const handleUserChange = (e) => {
    const selectedUserId = parseInt(e.target.value);
    const selectedUser = users.find((user) => user.id === selectedUserId);

    console.log(selectedUser);
    

    if (selectedUser) {
      setFormData((prevState) => ({
        ...prevState,
        name: selectedUser.name,
        firebase_user_id: selectedUser.firebase_user_id || "",
      }));
    }
  };

  console.log('firebase id',formData.firebase_user_id);
  

  // Handle input changes for title and body
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSendNotification = async (e) => {
    e.preventDefault();
  
    const payload = {
      "firebase_user_id": formData.firebase_user_id,  
      "title": formData.title,
      "body": formData.body,
    };
  
    console.log("Sending payload:", payload); // Debugging log

    const config = {}
  
    try {
      const response = await post(API_URL.NOTIFICATION.POST_NOTIFICATION,JSON.stringify(payload),config,{"Content-Type":"application/json"});
      console.log("Response from server:", response);
  
      toast.success("Notification sent successfully!", { autoClose: 1500 });
  
      // Reset the form after successful submission
      setFormData({
        name: "",
        // firebase_user_id: "",
        title: "",
        body: "",
      });
    } catch (error) {
      console.error("Error sending notification:", error);
      toast.error("Failed to send notification. Please try again.", {
        autoClose: 2000,
      });
    }
  };

  const handleSendToAllUsers = async () => {
    const firebaseUserIds = users.map((user) => user.firebase_user_id).filter(Boolean);
  console.log('all firebase',firebaseUserIds);
  
    const payload = {
      "firebase_user_ids": firebaseUserIds,
      "title": formData.title,
      "body": formData.body
    };
    console.log('allusers',payload);
  
    const config = {}
  
    try {
      const response = await post(API_URL.NOTIFICATION.POST_NOTIFICATION_TO_ALLUSERS, JSON.stringify(payload),config,{"Content-Type":"application/json"});
      console.log("Response from server:", response);
  
      toast.success("Notification sent to all users successfully!", { autoClose: 1500 });
      setFormData({
        title: "",
        body: "",
      });
    } catch (error) {
      console.error("Error sending notification to all users:", error);
      toast.error("Failed to send notification to all users. Please try again.", {
        autoClose: 2000,
      });
    }
  };
  

  return (
    <div style={{ maxWidth: "600px", margin: "auto", padding: "20px" }}>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        Send Notification
      </h2>
      <form onSubmit={handleSendNotification}>
        {/* User Dropdown */}
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>User:</label>
          <select
            name="selectedUser"
            onChange={handleUserChange}
            required
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          >
            <option value="">Select User</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name}
              </option>
            ))}
          </select>
        </div>

        {/* Firebase User ID */}
        {/* <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>
            Firebase User ID:
          </label>
          <input
            type="text"
            value={formData.firebase_user_id}
            readOnly
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
            }}
          />
        </div> */}

        {/* Title */}
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>Title:</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            placeholder="Enter notification title"
            required
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        </div>

        {/* Body */}
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>Message:</label>
          <textarea
            name="body"
            value={formData.body}
            onChange={handleInputChange}
            placeholder="Enter notification message"
            required
            rows={5}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "blue",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
          }}
        >
          Send Notification
        </button>

        <button
          type="button"
          onClick={handleSendToAllUsers}
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "green",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
            position:"relative",
            marginTop:"20px"
          }}
        >
          Send to All Users
        </button>
      </form>
    </div>
  );
};

export default UserNotification;
