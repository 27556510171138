import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../../AbstractElements';
import NewSocialmedia from './Newsocialmedia';
import { API_URL } from '../config/api_urls';
import { DELETE, get, patch, post } from '../config';
import { toast, ToastContainer } from 'react-toastify';
import { socialMediaTableColumns } from '../common/mockData';
import ConfirmationModal from '../common/Confiemationdeletemodal'; // The new confirmation modal component


function SocialMedia() {
  const [data, setData] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [socialMediaModal, setSocialMediaModal] = useState(false);
  const [editSocialMedia, setEditSocialMedia] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State for delete modal
  const [rowToDelete, setRowToDelete] = useState(null);


  useEffect(() => {
    fetchSocialMedia();
  }, []);

  const fetchSocialMedia = useCallback(() => {
    get(API_URL.SOCIAL_MEDIA_NAME.GET_SOCIALMEDIA_NAME)
      .then((response) => {
        if (response?.data.length > 0)
          setData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const socialMediaToggle = () => {
    setSocialMediaModal(!socialMediaModal);
  };

  const editModalToggle = (row) => {
    setEditSocialMedia(row);
    setSocialMediaModal(!socialMediaModal);
  };

  const handleCancel = () => {
    setSocialMediaModal(!socialMediaModal);
    setEditSocialMedia(null);
  };


  const handleDeleteConfirmation = (row) => {
    setRowToDelete(row);
    setIsDeleteModalOpen(true); 
  };

  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(false); 
    handleDeleteData(rowToDelete);
  };


  const handleSave = async (formData) => {
    if (formData?.id) {
      patch(API_URL.SOCIAL_MEDIA_NAME.PATCH_SOCIAL_MEDIA_NAME(formData?.id), formData)
        .then((response) => {
          toast.success("Updated successfully",{autoClose:1000});
          setSocialMediaModal(!socialMediaModal);
          setEditSocialMedia(null);
          fetchSocialMedia();
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          toast.error("Oops! something went wrong",{autoClose:1000});
        });
    } else {
      try {
        await post(API_URL.SOCIAL_MEDIA_NAME.POST_SOCIAL_MEDIA_NAME, formData);
        toast.success("Social Media added successfully",{autoClose:1000});
        setSocialMediaModal(!socialMediaModal);
        setEditSocialMedia(null);
        fetchSocialMedia();
      } catch (error) {
        console.error("Error saving data:", error);
        toast.error("Oops! something went wrong",{autoClose:1000});
      }
    }
  };

  const handleDeleteData = (row) => {
    if (row?.id) {
      DELETE(API_URL.SOCIAL_MEDIA_NAME.DELETE_SOCIAL_MEDIA_NAME(row?.id))
        .then((response) => {
          toast.success("Deleted successfully",{autoClose:1000});
          fetchSocialMedia();
        })
        .catch((error) => {
          toast.error("Oops! something went wrong",{autoClose:1000});
        });
    }
  };

  return (
    <>
      <div>
        <div className={`d-flex align-items-center justify-content-between p-4`}>
          <H4 attrH4={{ className: 'text-muted m-0' }}>All Social Media</H4>
          <Btn attrBtn={{ color: 'primary', onClick: socialMediaToggle }}>+ New Social Media</Btn>
  
          <NewSocialmedia
            editPreferenceData={editSocialMedia}
            isOpen={socialMediaModal}
            toggle={socialMediaToggle}
            title="Social Media"
            onSave={handleSave}
            onCancel={handleCancel}
          />
        </div>
  
        <div>
          <DataTable
            data={data}
            columns={socialMediaTableColumns(editModalToggle, handleDeleteConfirmation)}
            striped={true}
            center={true}
            pagination
            selectableRows={false}
            clearSelectedRows={toggleDelet}
          />
        </div>
      </div>
      
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        onConfirm={handleConfirmDelete}
        message="Are you sure to delete?"
      />

    </>
  );
}

export default SocialMedia;
