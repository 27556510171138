import React, { useContext } from "react";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import { classes } from "../Data/Layouts";
import CustomizerProvider from "../_helper/Customizer/CustomizerProvider";
// import hasRouter from "../helpers/hasRouter";
import hasRouter from "../helpers/hasRouter";
import UserDetailsCopy from "../Components/Dashboard/Default/users/UserdetailsCopy";
import UserDetailsPage from "../Components/Dashboard/Default/users/UserDetailsModal";
import EventApplication from "../Components/Dashboard/Default/events/EventApplication";


// setup fake backend
const Routers = () => {
  const login = useState(JSON.parse(sessionStorage.getItem("login")))[0];
  console.log("login ", login);
  const [authenticated, setAuthenticated] = useState(false);
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  // Customizer context (if required)
  // const {isLogin, setIsLogin} = useContext(CustomizerProvider);

  useEffect(() => {
    let abortController = new AbortController();
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);

  return hasRouter(
    <BrowserRouter basename={"/"}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path={"/"} element={<PrivateRoute />}>
            {login || authenticated ? (
              <>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}`}
                  element={
                    <Navigate
                      to={`${process.env.PUBLIC_URL}/dashboard/default/${layout}`}
                    />
                  }
                />
                <Route
                  exact
                  path={`/`}
                  element={
                    <Navigate
                      to={`${process.env.PUBLIC_URL}/dashboard/default/${layout}`}
                    />
                  }
                />
              </>
            ) : (
              ""
            )}
            <Route path={`/*`} element={<LayoutRoutes />} />
          </Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/login`}
            element={<Signin />}
          />
          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
          <Route path="/userdetails/:uuid" element={<UserDetailsCopy />} />
          {/* <Route path="/profile/:uuid" element={<UserDetailsPage />} /> */}
          <Route path="/events/application" element={<EventApplication/>}/>

        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
