import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Widgets2Data, Widgets2Data2, WidgetsData, WidgetsData2, WidgetsData3, WidgetsData4 } from '../../../Data/DefaultDashboard';
import Widgets1 from '../../Common/CommonWidgets/Widgets1';
import Widgets2 from '../../Common/CommonWidgets/Widgets2';
import { get } from './config';
import "chart.js/auto"; 
import { Line } from "react-chartjs-2"; 
import { API_URL } from './config/api_urls';
import Usericon from '../../../assets/images/Statistics/users.svg'
import Eventicon from '../../../assets/images/Statistics/events.svg'
import IDcardicon from '../../../assets/images/Statistics/idcard.svg'
import Producticon from '../../../assets/images/Statistics/products.svg'
import Applicationicon from '../../../assets/images/Statistics/applicants.svg'
import { Link } from 'react-router-dom';



const WidgetsWrapper = () => {
  const [userData, setUserData] = useState([]);
  const [IDcard, setIDcard] = useState([]);
  const [Events, setEvents] = useState([]);
  const [Applicants, setApplicants] = useState([]);
  const [Products, setProducts] = useState([]);
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [inactiveUserCount, setInactiveUserCount] = useState(0);
  const [approvedIDCardCount, setApprovedIDCardCount] = useState(0); 
  const [appliedIDCardCount, setappliedIDCardCount] = useState(0); 
  const [Newevents, setNewevents] = useState(0); 



  const fetchUserData = async () => {
    try {
      const response = await get(API_URL.USERS.GET_USERS);
      const users = response.data;
      setUserData(users);
      
      setInactiveUserCount(users.filter(user => !user.is_active).length); 
    } catch (err) {
				console.error("Error:", err);
}
  };


  const fetchIDcard = async () => {
    try {
      const response = await get(API_URL.IDCARDS.GET_IDCARDS);
      const users = response.data;
      setIDcard(users);
      
      // WidgetsData.total = users.length;
      const approvedCount = users.filter(user => user.status === 'Approved').length;
      setApprovedIDCardCount(approvedCount); 
     
      const appliedCount = users.filter(user => user.status === 'Applied').length;
      setappliedIDCardCount(appliedCount);

    } catch (err) {
				console.error("Error:", err);
}
  };
  
  const fetchEventApplication = useCallback(() => {
    get(API_URL.EVENT_APPLICATION.GET_EVENT_APPLICATION)
      .then((response) => {
        console.log(response);

        if (response?.data.length >= 0) 
          setApplicants(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchMarketPlaces = useCallback(() => {
    get(API_URL.MARKET_PRPDUCTS.GET_MARKET_PRPDUCTS)
      .then((response) => {
        console.log(response);
        
        if (response?.data.length >= 0) {
          console.log(response);
          
          setProducts(response.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  



  const fetchEvents = useCallback(() => {
    get(API_URL.EVENTS.GET_EVENTS)
      .then((response) => {
        if (response?.data.length > 0)
          console.log(response.data);
          
        const Event = response.data;
        setEvents(Event);
        setNewevents(Event.filter(event => !event.is_active).length); 
   
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

console.log('events',Events);


  useEffect(() => {
    fetchUserData();
    fetchIDcard();
    fetchEvents();
    fetchEventApplication();
    fetchMarketPlaces();
  }, []);

  
  const gradientStyle = {
    borderRadius: '7px',
    padding: '10px', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position:"relative",
    left:"100px",
    top:"20px"
  };

  const boxStyle = {
    width: "270px",
    height: "120px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // background: "#70d6ff",
    marginBottom: "20px",

  };
  
  const introBoxStyle = {
    background: "#f0f0f0",
    borderRadius: "10px",
    padding: "20px",
    margin: "20px 0",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    textAlign: "center",
  };
  
  const chartBoxStyle = {
    width: "100%",
    padding: "20px",
    background: "#fff",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  };
  

  const chartData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    datasets: [
      {
        label: "User Growth",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  return (
    <>
    <div className="d-flex"  >
      <div className="">
      <Col xxl='auto' xl='3' sm='6' className='box-col-6 m-3'>
      <Row>
        <Col xxl="auto" xl="3" sm="6" className="box-col-6">
          <div style={boxStyle}>
          <div style={gradientStyle}>
                 <Link to="/users/list/home"> 
           <img 
             src={Usericon} 
             alt="Total Users" 
                    style={{ width: "30px", height: "30px" }} 
             />
         </Link>
       </div>
       <Link to="/users/list/home"> 
            <h4 style={{position:"relative",left:"-50px",top:"-30px",color:"black"}}>Total Users</h4>
            </Link>

            <h5 style={{position:"relative",right:"80px",top:"-20px"}}>{userData.length}</h5>
           
          </div>
        </Col>
        <Col xxl="auto" xl="3" sm="6" className="box-col-6">
          <div style={boxStyle}>
            <div style={gradientStyle}>
            <Link to="/users/list/home" > 
              <img src={Usericon} alt="New Users" style={{ width: "30px", height: "30px" }} />
              </Link>
            </div>
            <Link to="/users/list/home" > 

            <h4 style={{position:"relative",left:"-50px",top:"-30px",color:"black"}}>New Users</h4>
            </Link>

            <h5 style={{position:"relative",right:"80px",top:"-20px"}}>{inactiveUserCount}</h5>
            
          </div>
        </Col>
        {/* Add other 6 boxes similarly */}
      </Row>
      </Col>
      <Col xxl='auto' xl='3' sm='6' className='box-col-6 m-3'>
      <Row>
        <Col xxl="auto" xl="3" sm="6" className="box-col-6">
          <div style={
            {
              width: "270px",
              height: "120px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // background: `linear-gradient(to right, #ffcc80, #ff8c00)`,
              marginBottom: "20px",
            }
          } >
            <div  style={{
               borderRadius: '7px',
               padding: '10px', 
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               position:"relative",
               left:"100px",
               top:"10px"
            }}>
            <Link to="/id-card/home"> 
              <img src={IDcardicon} alt="Total Users" style={{ width: "30px", height: "30px" ,
              }} />
              </Link>
            </div>
            <Link to="/id-card/home"> 
            <h4 style={{position:"relative",left:"-40px",top:"-30px",color:"black"}}>Total ID Cards</h4>
            </Link>

            <h5 style={{position:"relative",right:"80px",top:"-20px"}}>{IDcard.length}</h5>
          </div>
        </Col>
        <Col xxl="auto" xl="3" sm="6" className="box-col-6">
          <div style={
             {
              width: "270px",
              height: "120px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // background: `linear-gradient(to right, #ffcc80, #ff8c00)`,
              marginBottom: "20px",
            }
          }>
            <div style={{
               borderRadius: '7px',
               padding: '10px', 
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               position:"relative",
               left:"100px",
               top:"10px"
            }}>
            <Link to="/id-card/home"> 
              <img src={IDcardicon} alt="New ID Cards" style={{ width: "30px", height: "30px" }} />
              </Link>
            </div>
            <Link to="/id-card/home"> 
             <h4 style={{position:"relative",left:"-50px",top:"-30px",color:"black"}}>New ID cards</h4>
             </Link>

             <h5 style={{position:"relative",right:"80px",top:"-20px"}}>{IDcard.length}</h5>
          </div>
        </Col>
        {/* Add other 6 boxes similarly */}
      </Row>
      </Col>
      </div>
    <div className="">
    <Col xxl='auto' xl='3' sm='6' className='box-col-6 m-3'>
      <Row>
        <Col xxl="auto" xl="3" sm="6" className="box-col-6">
          <div style={ {
              width: "270px",
              height: "120px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}>
            <div style={gradientStyle}>
            <Link to="/events/openings/home"> 
              <img src={Eventicon} alt="Total Events" style={{ width: "30px", height: "30px" }} />
              </Link>
            </div>
            <Link to="/events/openings/home"> 

             <h4 style={{position:"relative",left:"-50px",top:"-30px",color:"black"}}>Total Events</h4>
             </Link>

             <h5 style={{position:"relative",right:"80px",top:"-20px"}}>{Events.length}</h5>
          </div>
        </Col>
        <Col xxl="auto" xl="3" sm="6" className="box-col-6">
          <div style={ {
              width: "270px",
              height: "120px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // background: `linear-gradient(to right, #ff9999, #cc0000)`,
              marginBottom: "20px",
            }}>
            <div style={gradientStyle}>
            <Link to="/events/openings/home"> 
              <img src={Eventicon} alt="New Events" style={{ width: "30px", height: "30px" }} />
              </Link>
            </div>
            <Link to="/events/openings/home"> 

             <h4 style={{position:"relative",left:"-50px",top:"-30px",color:"black"}}>New Events</h4>
             </Link>

             <h5 style={{position:"relative",right:"80px",top:"-20px"}}>{Newevents}</h5>
          </div>
        </Col>
      </Row>
      </Col>
      <Col xxl='auto' xl='3' sm='6' className='box-col-6 m-3'>
      <Row>
        <Col xxl="auto" xl="3" sm="6" className="box-col-6">
          <div style={ {
              width: "270px",
              height: "120px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // background: `linear-gradient(to right, #00bfff, #005f99)`,
              marginBottom: "20px",
            }}>
            <div style={{
               borderRadius: '7px',
               padding: '10px', 
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               position:"relative",
               left:"110px",
               top:"10px"
            }}>
            <Link to="/events/application/home"> 
              <img src={Applicationicon} alt="Total Application" style={{ width: "30px", height: "30px" }} />
              </Link>
            </div>
            <Link to="/events/application/home"> 

             <h4 style={{position:"relative",left:"-23px",top:"-30px",color:"black"}}>Event Application</h4>
             </Link>

             <h5 style={{position:"relative",right:"80px",top:"-20px"}}>{Applicants.length}</h5>
          </div>
        </Col>
        <Col xxl="auto" xl="3" sm="6" className="box-col-6">
          <div style={{
              width: "270px",
              height: "120px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // background: `linear-gradient(to right, #99ff99, #006600)`,
              marginBottom: "20px",
            }}>
            <div style={gradientStyle}>
            <Link to="/market-place/home"> 
              <img src={Producticon} alt="Total Products" style={{ width: "30px", height: "30px" }} />
              </Link>
            </div>
            <Link to="/market-place/home"> 
             <h4 style={{position:"relative",left:"-40px",top:"-30px",color:"black"}}>Total Products</h4>
             </Link>

             <h5 style={{position:"relative",right:"80px",top:"-20px"}}>{Products.length}</h5>
          </div>
        </Col>
      </Row>
      </Col>
    </div>

     
    </div>
      
      {/* <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
        <Row>
          <Col xl='12'>
            <div
   className="mt-3"
  style={{
    width: "270px",
    height: "180px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    padding: "10px", 
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background:"#57cc99"
  }}
>
  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
    <h5 style={{ margin: 0 }}>Total ID cards</h5>
    <div className="mt-3" style={gradientStyle}>
    <img src={IDcardicon} alt="ID cards" style={{ width: "30px", height: "30px" }} />

    </div>
  </div>
  <div >
    <p style={{ fontSize: "24px", margin: 0 ,position:"relative",left:"-50px" }}>{approvedIDCardCount}</p>

  </div>
</div>


          </Col>
          <Col xl="12">
  <div
    className="mt-3"
    style={{
      width: "270px",
      height: "180px",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      padding: "10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background:"#57cc99"
    }}
  >
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
      <h5 style={{ margin: 0 }}>New ID cards</h5>
      <div className="mt-3" style={gradientStyle}>
      <img src={IDcardicon} alt="New ID cards" style={{ width: "30px", height: "30px" }} />

      </div>
    </div>
    <div >
      <p style={{ fontSize: "24px", margin: 0 ,position:"relative",left:"-50px" }}>{appliedIDCardCount}</p>

    </div>
  </div>
</Col>

        </Row>
      </Col> */}
      {/* <Col xxl='auto' xl='12' sm='6' className='box-col-6'>
        <Row>
        <Col xxl="12" xl="6" className="box-col-12">
        <div
          className="mt-3"
          style={{
            width: "270px",
            height: "180px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background:"#ef233c"

          }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <h5 style={{ margin: 0 }}>Total Events</h5>
            <div className="mt-3" style={gradientStyle}>
            <img src={Eventicon} alt="Events" style={{ width: "30px", height: "30px" }} />

            </div>
          </div>
          <div >
            <p style={{ fontSize: "24px", margin: 0 ,position:"relative",left:"-50px"}}>{Events.length}</p>

          </div>
        </div>
      </Col>
          <Col xxl='12' xl='6' className='box-col-12'>
            <div
          className="mt-3"
          style={{
            width: "270px",
            height: "180px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background:"#ef233c"

          }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <h5 style={{ margin: 0 }}>New Events</h5>
            <div className="mt-3" style={gradientStyle}>
            <img src={Eventicon} alt="New Events" style={{ width: "30px", height: "30px" }} />

            </div>
          </div>
          <div >
            <p style={{ fontSize: "24px", margin: 0 ,position:"relative",left:"-50px" }}>{Newevents}</p>

          </div>
        </div>

          </Col>


        </Row>
      </Col> */}
      {/* <Col xxl='auto' xl='12' sm='6' className='box-col-6'>
        <Row>
          <Col xxl='12' xl='6' className='box-col-12'>
            <div
          className="mt-3"
          style={{
            width: "300px",
            height: "180px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background:"#f7b801"
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <h5 style={{ margin: 0 }}>Event Application</h5>
            <div className="mt-3" style={gradientStyle}>
            <img src={Applicationicon} alt="New Events" style={{ width: "30px", height: "30px" }} />

            </div>
          </div>
          <div >
            <p style={{ fontSize: "24px", margin: 0 ,position:"relative",left:"-50px" }}>{Products.length}</p>

          </div>
        </div>
          </Col>
          <Col xxl='12' xl='6' className='box-col-12'>
            <div
          className="mt-3"
          style={{
            width: "300px",
            height: "180px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background:"#f7b801"

          }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <h5 style={{ margin: 0 }}>Products</h5>
            <div className="mt-3" style={gradientStyle}>
            <img src={Producticon} alt="New Events" style={{ width: "30px", height: "30px" }} />

            </div>
          </div>
          <div >
            <p style={{ fontSize: "24px", margin: 0 ,position:"relative",left:"-50px" }}>{Products.length}</p>

          </div>
        </div>

          </Col>

          
        </Row>
      </Col> */}
      <div className="">
      <div style={chartBoxStyle}>
        <Line data={chartData} />
      </div>
      </div>
      
    </>
  );
};

export default WidgetsWrapper;
