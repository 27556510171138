import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const EventFilter = ({ 
    isId, userRole, userRoles, setUserRole, 
    gender, setGender, 
    username, setUsername, 
    whatsappNumber, setWhatsappNumber, 
    country, setCountry, 
    state, setState, 
    usercategories, userCategory, setUserCategories,
    countries, states,  userRegional, 
    selectedRegionalPartner, setSelectedRegionalPartner,setcities, cities ,city,setEventTitle,eventTitle
}) => 
    
    {
        console.log(usercategories);
        
        
    return (
        <>
            <FormGroup>
                <Label for="category">Role</Label>
                <Input
                    type="select"
                    name="category"
                    id="category"
                    className="form-control digits"
                    value={userRole}
                    onChange={(e) => setUserRole(e.target.value)}
                >
                    <option value="">Select Role</option>
                    {userRoles && userRoles.map((item, index) => (
                        <option key={index} value={item.role}>{item.role}</option>
                    ))}
                </Input>
            </FormGroup>
          
            <FormGroup>
                <Label for="username">Username</Label>
                <Input
                    type="text"
                    name="username"
                    id="username"
                    className="form-control"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter username"
                />
            </FormGroup>

            {/* <FormGroup>
                <Label for="eventtitle">Event Title</Label>
                <Input
                    type="text"
                    name="eventtitle"
                    id="eventtitle"
                    className="form-control"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter username"
                />
            </FormGroup> */}

            <FormGroup>
                <Label for="whatsappNumber">Phone Number</Label>
                <Input
                    type="text"
                    name="whatsappNumber"
                    id="whatsappNumber"
                    className="form-control"
                    value={whatsappNumber}
                    onChange={(e) => setWhatsappNumber(e.target.value)}
                    placeholder="Enter Phone Number"
                />
            </FormGroup>

            <FormGroup>
                <Label for="state">State</Label>
                <Input
                    type="text"
                    name="state"
                    id="state"
                    className="form-control"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    placeholder="Enter state"
                />
            </FormGroup>

            {/* <FormGroup>
                <Label for="state">State</Label>
                <Input
                    type="select"
                    name="state"
                    id="state"
                    className="form-control digits"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                >
                   <option value="">Select State</option>
                    {states.map((state, index) => (
                        <option key={index} value={state.id}>{state.name}</option>
                    ))}
                </Input>
            </FormGroup> */}

            <FormGroup>
                <Label for="city">City</Label>
                <Input
                    type="text"
                    name="city"
                    id="city"
                    className="form-control"
                    value={cities}
                    onChange={(e) => setcities(e.target.value)}
                    placeholder="Enter city"
                />
            </FormGroup>

            {/* <FormGroup>
                <Label for="country">City</Label>
                <Input
                    type="select"
                    name="city"
                    id="city"
                    className="form-control digits"
                    value={cities}
                    onChange={(e) => setcities(e.target.value)}
                >
                    <option value="">Select City</option>
                     {city.map((city, index) => (
                     <option key={index} value={city.id}>{city.name}</option>
                   ))} 
                </Input>
            </FormGroup> */}

            <FormGroup>
                <Label for="eventtitle">Event Title</Label>
                <Input
                    type="text"
                    name="eventtitle"
                    id="eventtitle"
                    className="form-control"
                    value={eventTitle}
                    onChange={(e) => setEventTitle(e.target.value)}
                    placeholder="Enter event type"
                />
            </FormGroup>
            <FormGroup>
                <Label for="category">Event type</Label>
                <Input
                    type="text"
                    name="category"
                    id="category"
                    className="form-control"
                    value={usercategories}
                    onChange={(e) => setUserCategories(e.target.value)}
                    placeholder="Enter event type"
                />
            </FormGroup>           
        </>
    );
};

export default EventFilter;