import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useLocation, useNavigate } from "react-router";
import FormCommon from "../common/FormCommon";
import { get, patch, post } from "../config";
import { API_URL } from "../config/api_urls";
import { toast, ToastContainer } from "react-toastify";
import { constructFormData } from "../config/imageHandle";
import { Button, Modal } from "react-bootstrap";
import EditCommonModal from "../common/EditCommonModal";

const Newidcard = ({ editIdcardData }) => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    handleUserChange();
  }, []);

  console.log("editeddata", editIdcardData);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await get(API_URL.USERS.GET_USERS);
      const users_ids = response?.data?.map((i) => ({
        label: i.name,
        value: i.uuid,
      }));
      users_ids.unshift({ label: "Select User", value: "" });
      if (users_ids.length > 0) {
        setUsers(users_ids);
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "user_uuid"
              ? { ...field, options: users_ids }
              : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const handleUserChange = async (event) => {
    const selectedUserUuid = event;
    console.log("Selected User UUID:", selectedUserUuid);

    try {
      const response = await get(API_URL.USERS.GET_USERS);
      const users_ids = response?.data?.map((i) => ({
        label: i.name,
        value: i.uuid,
      }));

      console.log("Fetched Users inside handle:", users_ids);

      const selectedUser = users_ids.find(
        (user) => user.value === selectedUserUuid
      );

      if (selectedUser) {
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          initialState: {
            ...prevConfig.initialState,
            user_uuid: selectedUserUuid,
            name: selectedUser.label,
          },
        }));
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const [formDataConfig, setFormDataConfig] = useState({
    initialState: {
      id: null,
      user_uuid: "",
      name: "",
      image: "",
      user_role_id: "",
      email: "",
      dob: "",
      phone: "",
      aadhar_front_image: "",
      aadhar_back_image: "",
      payment_status: "",
      qr_code_image: "",
    },
    fields: [
      {
        name: "user_uuid",
        label: "User",
        type: "select",
        options: [],
        required: true,
        onChange: (value) => {
          console.log("User dropdown event:", value);
          handleUserChange(value);
        },
      },
      {
        name: "name",
        label: "FullName",
        type: "text",
        placeholder: "Enter Full Name",
        required: true,
      },
      {
        name: "image",
        label: "Image",
        type: "file",
        required: true,
      },
      {
        name: "user_role_id",
        label: "User Role",
        type: "select",
        options: [],
        required: true,
      },
      {
        name: "email",
        label: "Email",
        type: "email",
        placeholder: "Enter Email ID",
        required: true,
      },
      {
        name: "dob",
        label: "Date Of Birth",
        type: "date",
        required: true,
      },
      {
        name: "phone",
        label: "Phone Number",
        type: "tel",
        placeholder: "Enter Phone Number",
        required: true,
      },
      {
        name: "aadhar_front_image",
        label: "Aadhar Front Image",
        type: "file",
        required: true,
      },
      {
        name: "aadhar_back_image",
        label: "Aadhar Back Image",
        type: "file",
        required: true,
      },
      {
        name: "qr_code_image",
        // label: "Qr code",
        type: "hidden",
        required: true,
      },
      {
        name: "payment_status",
        label: "Payment status",
        type: "select",
        options: [
          { value: "", label: "Select Status" },
          { value: "free", label: "Free" },
          { value: "online", label: "Online" },
        ],
        required: true,
      },
    ],
  });

  useEffect(() => {
    fetchUserRoles();
  }, []);

  //user role dropdown
  const fetchUserRoles = useCallback(async () => {
    try {
      const response = await get(API_URL.USER_ROLE.USER_ROLE_GET);
      const userRole_ids = response?.data?.map((i) => ({
        label: i.role,
        value: i.id,
      }));
      userRole_ids.unshift({ label: "Select User Role", value: "" });
      if (userRole_ids.length > 0) {
        setUserRoles(userRole_ids);
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "user_role_id"
              ? { ...field, options: userRole_ids }
              : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const handleNavigateToIdCards = () => {
    navigate(`${process.env.PUBLIC_URL}/id-card/home`);
  };

  const handleNavigateToTerms = () => {
    navigate(`${process.env.PUBLIC_URL}/payment-terms/home`);
  };

  const onSave = (formData) => {
    console.log(formData);

    // handleShow()
    const values = {
      image: formData?.image,
      name: formData?.name,
      user_uuid: formData?.user_uuid,
      user_role_id: formData?.user_role_id,
      email: formData?.email,
      dob: formData?.dob,
      phone: formData?.phone,
      aadhar_front_image: formData?.aadhar_front_image,
      aadhar_back_image: formData?.aadhar_back_image,
      payment_status: formData?.payment_status,
      // Qr_Code : formData?.qr_code_image
    };
    const data = constructFormData(values, [
      "image",
      "aadhar_front_image",
      "aadhar_back_image",
      "qr_code_image",
    ]);

    post(API_URL.IDCARDS.POST_IDCARD, data)
      .then((response) => {
        console.log(response);

        const order = {
          membership_uuid: response.data.membership_uuid,
          user_uuid: response.data.user_uuid,
          terms_accepted: "yes",
        };

        post(API_URL.ORDERS.POST_ORDER, order)
          .then((response) => {
            console.log(response);
            
          })
          .catch((error) => {
            console.error(error);
          });

        toast.success("Membership created successfully", { autoClose: 1000 });
        handleNavigateToTerms();

        // handleNavigateToIdCards();
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        toast.error("Oops! something went wrong, try again", {
          autoClose: 1000,
        });
      });
  };

  return (
    <div>
      <div>
        <FormCommon
          title={"Add Membership"}
          formDataConfig={formDataConfig}
          onSave={onSave}
        />
      </div>
    </div>
  );
};

export default Newidcard;
