import { Card, CardActionArea, CardMedia } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Col, Row } from "reactstrap";
import { get } from "../config";
import { API_URL } from "../config/api_urls";
import ImodaIcon from '../../../../assets/images/Imoda-logo.svg'
import { Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

function UserVideo() {

    const {user_uuid} = useParams()

    const [videos,setvideos] = useState([])

    console.log(user_uuid);
    

    const fetchvideos = useCallback(() => { 
        get(`${API_URL.PROFILE.GET_PROFILE}`)
        .then((response) => {
          if (response?.data.length > 0) {
            setvideos(response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching social media:", error);
        });
      }, []);
  
  
      const filteredvideo = videos.filter(video => video.user_uuid === user_uuid)

      console.log('full videos',videos);
      
      console.log('filteres',filteredvideo);

      useEffect(()=>{
        fetchvideos();
      },[])
       

  return (
    <div>
        <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">
            <Link to={`${process.env.PUBLIC_URL}/home`}>
                <img
                  src={ImodaIcon}
                  width="150"
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
            </Link>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Row className="m-3 shadow rounded mx-5 ">
        <h4 className="m-3">USER VIDEOS</h4>
        <Col className="d-flex m-3">
          {filteredvideo?.length>=0 && filteredvideo.map((vid,index)=>(
            <Card className="m-2" sx={{ maxWidth: 345 }}>
            <CardActionArea>
              <CardMedia
                component="video"
                height="140"
                image={vid.video_links}
                alt="green iguana"
              />
            </CardActionArea>
          </Card>))}
        </Col>
      </Row>
    </div>
  );
}

export default UserVideo;
