import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardText, CardTitle } from "reactstrap";
import { Btn, H4, P } from "../../../../AbstractElements";
import { Box, Button, Modal, Typography } from "@mui/material";
import { API_URL } from "../config/api_urls";
import { DELETE, get, post } from "../config";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function SavedMessages({ selectedRows }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState([]);

  console.log(selectedRows);
  console.log(data);

  const fetchMessageReview = useCallback(() => {
    get(API_URL.WHATSAPP_MESSAGE.MESSAGE_REVIEW)
      .then((response) => {
        console.log(response.data);

        if (response?.data.length > 0) setData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const splitBody = data.map((data) => {
    const [text, link] = data.message_body.split("\nCheck this out:");
    return {
      ...data,
      text,
      link,
    };
  });

  console.log(splitBody);

  const handleOpen = (data) => {
    console.log(data);

    if (selectedRows.length > 0) {
      setOpen(true);
      setModalData(data);
    } else {
      alert("Please select users to message.");
    }
  };

  console.log(modalData);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    fetchMessageReview();
  }, []);

  const bulkMessage = () => {
    selectedRows.forEach((row) => {
      const phoneNumber = row.phone_number;

      // const message = encodeURIComponent(editorContent);
      // console.log(message);

      // const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${editorContent}`;
      // setEditorContent('')
      // window.open(url, "_blank");

      console.log(phoneNumber);
      console.log(modalData.text);
      console.log(modalData.link);

      const formDataa = new FormData();
      formDataa.append("recipient_number", phoneNumber);
      formDataa.append("message_body", modalData.text);
      // formDataa.append('media_url',modalData.media_urls[0])
      formDataa.append("link", modalData.link);

      // console.log(galleryImg);

      console.log(formDataa);

      post(API_URL.WHATSAPP_MESSAGE.POST_MESSAGE, formDataa)
        .then((response) => {
          console.log(response);
          handleClose();
          toast.success("send", { autoClose: 1000 });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  //smsmessage
  const bulkSms = async () => {
    const phoneNumber = selectedRows.map((row) => row.phone_number);
    console.log("Collected Phone Numbers:", phoneNumber);

    try {
      const smsData = new FormData();
      smsData.append("recipients", phoneNumber);
      smsData.append("body", modalData.text);
      smsData.append("link", modalData.link);

      const smdata = {
        "recipients":phoneNumber,
        "body":modalData.text,
        "link":modalData.link
      }

      const config = {}

      console.log(JSON.stringify(smdata));

      const response = await post(API_URL.SMS_MESSAGE.POST_SMS, JSON.stringify(smdata),config,{"Content-Type":"application/json"});
      console.log(response);
      handleClose();
      toast.success("send", { autoClose: 1000 });
    } catch (error) {
      console.error("Error sending SMS:", error);
      toast.error("SMS not sent", { autoClose: 1000 });
    }
  };

  const handleDelete = (id) => {
    console.log(id);

    DELETE(API_URL.WHATSAPP_MESSAGE.DELETE_MESSAGE(id))
      .then((response) => {
        console.log(response);

        fetchMessageReview();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      <H4 attrH4={{ className: "text-muted mt-5 ms-4 text-center" }}>
        Message History
      </H4>
      <div style={{ display: "flex", flexWrap: "wrap-reverse" }}>
        {splitBody.length > 0 ? (
          splitBody.map((data) => (
            <Card
              className="m-2"
              color="primary"
              outline
              style={{
                width: "15rem",
              }}
            >
              <CardHeader>
                <Button onClick={() => handleDelete(data.id)}>
                  <i class="fa-solid fa-trash"></i>
                </Button>
              </CardHeader>
              <img src={data.media_url} />
              <CardBody onClick={() => handleOpen(data)}>
                <CardTitle tag="h6">{data.text}</CardTitle>
                <CardText>{data.link}</CardText>
              </CardBody>
            </Card>
          ))
        ) : (
          <p>No message history</p>
        )}
        <div>
          {modalData && (
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {modalData.text}
                  {modalData.link}
                </Typography>

                <div className="d-flex align-items-center justify-content-between">
                  <Button onClick={bulkMessage}>Send whatsapp</Button>
                  <Button onClick={bulkSms}>Send sms</Button>
                </div>
              </Box>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
}

export default SavedMessages;
