import { Btn, H6 } from "../../../../AbstractElements";
import {
  PersonalDetails,
  FullName,
  Email,
  Password,
  RepeatPassword,
  BirthDate,
  PhoneNumber,
} from "../../../../Constant";
import { Typeahead } from "react-bootstrap-typeahead";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useLocation, useNavigate } from "react-router";
import FormCommon from "../common/FormCommon";
import { get, patch, post } from "../config";
import { API_URL } from "../config/api_urls";
import { constructFormData } from "../config/imageHandle";
import { toast, ToastContainer } from "react-toastify";

const NewAttribute = () => {
  // eslint-disable-next-line
  const [multiple, setMultiple] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const editAttributeData = location.state?.attributeData;

  const [userRoles, setUserRoles] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [userselectedRoles, setuserselectedRoles] = useState([]);
  const [showRangeField, setShowRangeField] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    handleUserRoleChange();
  }, []);

  useEffect(() => {
    fetchUserRoles();
  }, []);

  const fetchUserRoles = useCallback(async () => {
    try {
      const response = await get(API_URL.USER_ROLE.USER_ROLE_GET);
      const userRole_ids = response?.data?.map((i) => ({
        label: i.role,
        value: i.id,
      }));
      userRole_ids.unshift({ label: "Select Users Role", value: "" });
      if (userRole_ids.length > 0) {
        setUserRoles(userRole_ids);
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "user_role_name"
              ? { ...field, options: userRole_ids }
              : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const handleUserRoleChange = (value) => {
    console.log("Selected User Role value:", value);
    setSelectedUserRole(value);

    setFormDataConfig((prevConfig) => ({
      ...prevConfig,
      initialState: {
        ...prevConfig.initialState,
        user_role_name: value, // Update with the selected value
      },
    }));

    get(API_URL.USER_ROLE.USER_ROLE_GET)
      .then((response) => {
        const userRoleOptions = response.data.map((i) => ({
          label: i.role,
          value: i.id,
        }));

        console.log("Fetched User Roles from API:", userRoleOptions);
        setuserselectedRoles(userRoleOptions);

        const matchedRole = userRoleOptions.find((role) => role.value == value);
        const isModelRole =
          matchedRole && matchedRole.label.toLowerCase() === "model";

        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: [
            ...prevConfig.fields.filter((field) => field.label !== "Range"),
            ...(isModelRole
              ? [
                  {
                    label: "Range",
                    type: "group",
                    fields: [
                      {
                        name: "enter_startpoint",
                        placeholder: "Enter startpoint",
                        type: "number",
                      },
                      {
                        name: "enter_endpoint",
                        placeholder: "Enter endpoint",
                        type: "number",
                      },
                      {
                        name: "enter_unit",
                        placeholder: "Enter unit",
                        type: "text",
                      },
                    ],
                    required: false,
                  },
                ]
              : []),
          ],
        }));

        console.log("Matched User Role is 'model':", isModelRole);
      })
      .catch((error) => {
        console.error("Error fetching user roles:", error);
      });
  };

  const [formDataConfig, setFormDataConfig] = useState({
    initialState: {
      id: null,
      user_role_name: "",
      attribute_name: "",
      image: "",
      order_number: "",
      enter_startpoint: "",
      enter_endpoint: "",
      enter_unit: "",
      email: "",
    },
    fields: [
      {
        name: "user_role_name",
        label: "User Role",
        type: "select",
        options: [],
        required: true,
        onChange: (value) => {
          console.log("log", value);

          handleUserRoleChange(value);
        },
      },

      {
        name: "attribute_name",
        label: "Attribute",
        type: "text",
        placeholder: "Enter Attribute",
        required: true,
      },

      // {
      //   label: "range",
      //   type: "group",
      //   fields: [
      //     {
      //       name: "enter_startpoint",
      //       placeholder: "Enter startpoint",
      //       type: "number",
      //     },
      //     {
      //       name: "enter_endpoint",
      //       placeholder: "Enter endpoint",
      //       type: "number",
      //     },
      //     {
      //       name: "enter_unit",
      //       placeholder: "Enter unit",
      //       type: "text",
      //     }
      //   ],
      //   required: false,
      // },
      //   ...(showRangeField ? [{
      //     label: "Range",
      //     type: "group",
      //     fields: [
      //         {
      //             name: "enter_startpoint",
      //             placeholder: "Enter startpoint",
      //             type: "number",
      //         },
      //         {
      //             name: "enter_endpoint",
      //             placeholder: "Enter endpoint",
      //             type: "number",
      //         },
      //         {
      //             name: "enter_unit",
      //             placeholder: "Enter unit",
      //             type: "text",
      //         },
      //     ],
      //     required: false,
      // }] : []),
      {
        name: "order_number",
        label: "Order number",
        type: "number",
        placeholder: "Enter Order number",
        required: false,
      },

      {
        name: "image",
        label: "Image",
        type: "file",
        required: false,
      },
    ],
    imageField: "image",
  });

  useEffect(() => {
    fetchUserRoles();
    fetchAttributes();
  }, []);

  console.log("formdata", formDataConfig);

  useEffect(() => {
    if (editAttributeData) {
      setIsEditing(true);

      setFormDataConfig((value) => ({
        ...value,
        initialState: {
          ...editAttributeData,
          user_role_name: editAttributeData?.user_role_name,
        },
      }));
    } else {
      setIsEditing(false);
      setFormDataConfig((value) => ({
        ...value,
        initialState: {
          id: null,
          image: "",
          user_role_name: "",
          attribute_name: "",
        },
      }));
    }
  }, [editAttributeData]);

  const handleNavigateToAttributes = () => {
    navigate(`${process.env.PUBLIC_URL}/attributes/home`);
  };

  //user role dropdown
  // const fetchUserRoles = useCallback(async () => {
  //   try {
  //     const response = await get(API_URL.USER_ROLE.USER_ROLE_GET);
  //     const userRole_ids = response?.data?.map((i) => ({
  //       label: i.role,
  //       value: i.id
  //     }));
  //     userRole_ids.unshift({ label: "Select User Role", value: "" });
  //     if (userRole_ids.length > 0) {
  //       setUserRoles(userRole_ids);
  //       setFormDataConfig((prevConfig) => ({
  //         ...prevConfig,
  //         fields: prevConfig.fields.map((field) =>
  //           field.name === "user_role_name" ? { ...field, options: userRole_ids } : field
  //         ),
  //       }));
  //     }

  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // }, []);

  const fetchAttributes = useCallback(async () => {
    try {
      const response = await get(API_URL.ATTRIBUTES.GET_ATTRIBUTES);
      const attributes_values = response?.data?.map((i) => ({
        label: i.attribute_name,
        value: i.id,
      }));
      attributes_values.unshift({ label: "Select Attribute", value: "" });
      if (attributes_values.length > 0) {
        setAttributes(attributes_values);
        setFormDataConfig((prevConfig) => ({
          ...prevConfig,
          fields: prevConfig.fields.map((field) =>
            field.name === "attribute_name"
              ? { ...field, options: attributes_values }
              : field
          ),
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const onSave = (formData) => {
    const values = {
      id: formData?.id || "",
      user_role: formData?.user_role_name,
      attribute_name: formData?.attribute_name,
      image: formData?.image,
      enter_startpoint: formData?.enter_startpoint || "",
      enter_endpoint: formData?.enter_endpoint || "",
      enter_unit: formData?.enter_unit || "",
    };

    const data = constructFormData(values, ["image"]);

    if (formData?.id) {
      patch(API_URL.ATTRIBUTES.PATCH_ATTRIBUTES(formData?.id), data)
        .then((response) => {
          console.log(response);

          toast.success("Updated successfully", { autoClose: 1000 });
          handleNavigateToAttributes();
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          toast.error("Oops! something went wrong", { autoClose: 1000 });
        });
    } else {
      post(API_URL.ATTRIBUTES.POST_ATTRIBUTES, data)
        .then((response) => {
          toast.success("Attribute created successfully", { autoClose: 1000 });
          handleNavigateToAttributes();
        })
        .catch((error) => {
          console.error("Error saving data:", error);
          toast.error("Oops! something went wrong", { autoClose: 1000 });
        });
    }
  };

  return (
    <div>
      <FormCommon
        isModel={true}
        title={`${editAttributeData ? "Edit" : "Add"} Attribute`}
        formDataConfig={formDataConfig}
        onSave={onSave}
        userRoles={userRoles}

        // editMode={!!formDataConfig?.initialState?.id}
      />
    </div>
  );
};

export default NewAttribute;
