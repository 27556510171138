import React, { useCallback, useEffect, useState } from "react";
import { get, patch, post } from "../config";
import { API_URL } from "../config/api_urls";
import { toast } from "react-toastify";
import { Btn } from "../../../../AbstractElements";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

const ContactUs = () => {
  const [content, setContent] = useState("");
  const [id, setId] = useState("");
  const [contact, setContact] = useState({
    phone_number1: "",
    phone_number2: "",
    email: "",
    address1: "",
  });

  useEffect(() => {
    fetchContactUs();
  }, []);

  const fetchContactUs = useCallback(() => {
    get(API_URL.CONTACT_US.GET_CONTACT_US)
      .then((response) => {
        if (response?.data) {
          setContent(response.data.content);
          setId(response.data.id);
          setContact({
            phone_number1: response.data.phone_number1 || "",
            phone_number2: response.data.phone_number2 || "",
            email: response.data.email || "",
            address1: response.data.address1 || "",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContact((prevContact) => ({
      ...prevContact,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const payload = {
      id,
      content,
      ...contact,
    };

    if (id) {
      console.log("Updating Contact Us details...", { url: API_URL.CONTACT_US.PATCH_CONTACT_US(id), payload });
  
      patch(API_URL.CONTACT_US.PATCH_CONTACT_US(id), payload)
        .then((response) => {
          console.log("PATCH Contact Us Response:", response);
          toast.success("Updated successfully");
          fetchContactUs();
        })
        .catch((error) => {
          console.error("Error updating Contact Us details:", error);
          toast.error("Oops! something went wrong");
        });
    } else {
      console.log("Saving new Contact Us details...", { url: API_URL.CONTACT_US.POST_CONTACT_US, payload });
  
      try {
        const response = await post(API_URL.CONTACT_US.POST_CONTACT_US, payload);
        console.log("POST Contact Us Response:", response);
        toast.success("Contact Us saved successfully");
        fetchContactUs();
      } catch (error) {
        console.error("Error saving Contact Us details:", error);
        toast.error("Oops! something went wrong");
      }
    }
  };

  return (
    <div>
      <h3 className="title p-4">Contact Us</h3>
      <Form className="p-4">
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="phonenumber1">Phone Number1</Label>
              <Input
                onChange={handleInputChange}
                id="phonenumber1"
                name="phone_number1"
                placeholder="Enter phone number"
                type="tel"
                value={contact.phone_number1}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="phonenumber2">Phone Number2</Label>
              <Input
                onChange={handleInputChange}
                id="phonenumber2"
                name="phone_number2"
                placeholder="Enter phone number"
                type="tel"
                value={contact.phone_number2}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                id="email"
                type="email"
                placeholder="Enter email"
                name="email"
                onChange={handleInputChange}
                value={contact.email}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="address">Address</Label>
              <Input
                id="address"
                placeholder="Enter Address"
                type="textarea"
                name="address1"
                onChange={handleInputChange}
                value={contact.address1}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
      <div className="d-flex justify-content-end">
        <Btn attrBtn={{ color: "primary", onClick: handleSave }}>
          Save Changes
        </Btn>
      </div>
    </div>
  );
};

export default ContactUs;
