import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { userpreferencestableColumns, usersocialMediaTableColumns } from '../common/mockData';
import { Btn, H4 } from '../../../../AbstractElements';
import NewUserPreference from './NewUserSocialmedia'; // Ensure this is the correct component for handling user preferences
import { API_URL } from '../config/api_urls';
import { DELETE, get, patch, post } from '../config';
import ConfirmationModal from '../common/Confiemationdeletemodal'; // The new confirmation modal component

import { toast, ToastContainer } from 'react-toastify';

function UserSocialmedia() {
    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    // const [userPreferenceModal, setUserPreferenceModal] = useState(false);
    // const [editUserPreference, setEditUserPreference] = useState();
    const [editUserSocial, setEditUserSocial] = useState();
    const [userSocialModal, setUserSocialModal] = useState(false);
    // const [socialMediaNames, setSocialMediaNames] = useState({});
    const [socialMedia, setSocialMedia] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State for delete modal
    const [rowToDelete, setRowToDelete] = useState(null);



    useEffect(() => {
        // fetchUserPreferences();
        fetchUsersList();
        fetchSocialMedia();
        fetchSocialMedianame();
    }, []);

    // const fetchUserPreferences = useCallback(() => {
    //     get(API_URL.SOCIAL_MEDIA.GET_SOCIAL_MEDIA)
    //         .then((response) => {
    //             if (response?.data.length > 0) {
    //                 setData(response.data);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching user preferences:", error);
    //         });
    // }, []);

    console.log('data',data);

    // 
    // const fetchSocialMedia = useCallback(async () => {
    //     try {
    //         const response = await get(API_URL.SOCIAL_MEDIA.GET_SOCIAL_MEDIA);
    //         if (response?.data.length > 0) {
    //             setData(response.data);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching user preferences:", error);
    //     }
    // }, []);

    const fetchSocialMedia = useCallback(() => {
        get(API_URL.SOCIAL_MEDIA.GET_SOCIAL_MEDIA)
          .then((response) => {
            if (response?.data.length > 0)
              setData(response.data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }, []);

    
    const fetchSocialMedianame = useCallback(() => {
        get(API_URL.SOCIAL_MEDIA.GET_SOCIAL_MEDIA)
            .then((response) => {
                console.log(response);
                
                if (response?.data.length > 0) {
                    setSocialMedia(response.data);
                }
            })
            .catch((error) => {
                console.error("Error fetching user preferences:", error);
            });
    }, []);
    console.log('socialmedia',socialMedia);
    

    const fetchUsersList = useCallback(() => {
        get(API_URL.USERS.GET_USERS)
            .then((response) => {
                if (response?.data.length > 0) {
                    setUsers(response.data);
                }
            })
            .catch((error) => {
                console.error("Error fetching users:", error);
            });
    }, []);

  

    const userSocialToggle = () => {
        setUserSocialModal(!userSocialModal);
    };

    const editModalToggle = (row) => {
        setEditUserSocial(row);
        setUserSocialModal(!userSocialModal);
    };

    const handleCancel = () => {
        setEditUserSocial(null);
        setUserSocialModal(!userSocialModal);
    };

    const handleSave = async (formData) => {
        const values = {
            id: formData?.id || '',
            user_uuid: formData?.user_name?.value,
            socialmedia_id: formData?.social_media_name?.value,
            link: formData?.link
        };

        console.log(values);
        
    
        try {
            if (formData?.id) {
                await patch(API_URL.SOCIAL_MEDIA.PATCH_SOCIAL_MEDIA(formData.id), values);
                toast.success("Updated successfully",{autoClose:1000});
                setEditUserSocial(null);
                fetchSocialMedia();
                userSocialToggle();
        
                // Call fetchSocialMedia with await now
            } else {
                await post(API_URL.SOCIAL_MEDIA.POST_SOCIAL_MEDIA, values);
                toast.success("User Socialmedia created successfully",{autoClose:1000});
            }
    
            setEditUserSocial(null);
            userSocialToggle();
    
            // Call fetchSocialMedia with await now
             fetchSocialMedia();
    
        } catch (error) {
            console.error("Error saving data:", error);
            toast.error("Oops! something went wrong",{autoClose:1000});
        }
    };

    
    const handleDeleteConfirmation = (row) => {
        setRowToDelete(row);
        setIsDeleteModalOpen(true); // Open the confirmation modal
      };
    
      const handleConfirmDelete = () => {
        setIsDeleteModalOpen(false); // Close the modal after confirming
        handleDeleteData(rowToDelete);
      };
    
    

    const handleDeleteData = (row) => {
        if (row?.id) {
            DELETE(API_URL.SOCIAL_MEDIA.DELETE_SOCIAL_MEDIA(row.id))
                .then(() => {
                    toast.success("Deleted successfully",{autoClose:1000});
                    fetchSocialMedia();
                })
                .catch((error) => {
                    console.error("Error deleting data:", error);
                    toast.error("Oops! something went wrong",{autoClose:1000});
                });
        }
    };

    return (
        <>
            <div>
                <div className="d-flex align-items-center justify-content-between p-4">
                    <H4 attrH4={{ className: 'text-muted m-0' }}>All Social Media's</H4>
                    <Btn attrBtn={{ color: 'primary', onClick: userSocialToggle }}>+ New Social Media</Btn>
                    <NewUserPreference
                        editUserSocialMediaData={editUserSocial}
                        isOpen={userSocialModal}
                        toggle={userSocialToggle}
                        title="User Preference"
                        onSave={handleSave}
                        onCancel={handleCancel}
                    />
                </div>
                <div>
                    <DataTable
                        data={data}
                        columns={usersocialMediaTableColumns(editModalToggle, handleDeleteConfirmation,socialMedia)}
                        striped={true}
                        center={true}
                        pagination
                        selectableRows={false}
                        clearSelectedRows={toggleDelet}
                    />
                </div>
            </div>
            
            <ConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        onConfirm={handleConfirmDelete}
        message="Are you sure to delete?"
      />

        </>
    );
}

export default UserSocialmedia;