// import React from 'react';
// import { FormGroup, Label, Input } from 'reactstrap';

// const FilterForm = ({
//     userRole, userRoles, setUserRole,
//     gender, setGender,
//     username, setUsername,
//     whatsappNumber, setWhatsappNumber,
//     country, setCountry,
//     state, setState,
//     usercategories, userCategory, setUserCategories,
//     countries, states,
// }) =>

//     {
//         // console.log(usercategories);
//         console.log('this is usercategory',userCategory);
//         // console.log(usercategor);

//     return (
//         <>
//             <FormGroup>
//                 <Label for="category">Role</Label>
//                 <Input
//                     type="select"
//                     name="category"
//                     id="category"
//                     className="form-control digits"
//                     value={userRole}
//                     onChange={(e) => setUserRole(e.target.value)}
//                 >
//                     <option value="" disabled>Select Role/Category</option>
//                     {userRoles && userRoles.map((item, index) => (
//                         <option key={index} value={item.id}>{item.role}</option>
//                     ))}
//                 </Input>
//             </FormGroup>

//             <FormGroup>
//                 <Label for="gender">Gender</Label>
//                 <Input
//                     type="select"
//                     name="gender"
//                     id="gender"
//                     className="form-control digits"
//                     value={gender}
//                     onChange={(e) => setGender(e.target.value)}
//                 >
//                     <option value="" disabled>Select Gender</option>
//                     <option value="Female">Female</option>
//                     <option value="Male">Male</option>
//                     <option value="Other">Other</option>
//                 </Input>
//             </FormGroup>

//             <FormGroup>
//                 <Label for="username">Username</Label>
//                 <Input
//                     type="text"
//                     name="username"
//                     id="username"
//                     className="form-control"
//                     value={username}
//                     onChange={(e) => setUsername(e.target.value)}
//                     placeholder="Enter username"
//                 />
//             </FormGroup>

//             <FormGroup>
//                 <Label for="whatsappNumber">WhatsApp Number</Label>
//                 <Input
//                     type="text"
//                     name="whatsappNumber"
//                     id="whatsappNumber"
//                     className="form-control"
//                     value={whatsappNumber}
//                     onChange={(e) => setWhatsappNumber(e.target.value)}
//                     placeholder="Enter WhatsApp number"
//                 />
//             </FormGroup>

//             <FormGroup>
//                 <Label for="country">Country</Label>
//                 <Input
//                     type="select"
//                     name="country"
//                     id="country"
//                     className="form-control digits"
//                     value={country}
//                     onChange={(e) => setCountry(e.target.value)}
//                 >
//                     <option value="">Select Country</option>
//     {countries.map((country, index) => (
//         <option key={index} value={country.name}>{country.name}</option>
//     ))}
//                 </Input>
//             </FormGroup>

//             <FormGroup>
//                 <Label for="state">State</Label>
//                 <Input
//                     type="select"
//                     name="state"
//                     id="state"
//                     className="form-control digits"
//                     value={state}
//                     onChange={(e) => setState(e.target.value)}
//                 >
//                    <option value="">Select State</option>
//     {states.map((state, index) => (
//         <option key={index} value={state.name}>{state.name}</option>
//     ))}
//                 </Input>
//             </FormGroup>
//             <FormGroup>
//                 <Label for="category">Category</Label>
//                 <Input
//                     type="select"
//                     name="category"
//                     id="category"
//                     className="form-control digits"
//                     value={usercategories}
//                     onChange={(e) => setUserCategories(e.target.value)}
//                 >
//                     <option value="" disabled>Select Category</option>
//                     {userCategory.map(category => (
//                         <option key={category.id} value={category.id}>{category.name}</option>
//                     ))}
//                 </Input>
//             </FormGroup>

//         </>
//     );
// };

// export default FilterForm;

////////////////////////////////////////

import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

const FilterForm = ({
  isId,
  userRole,
  userRoles,
  setUserRole,
  gender,
  setGender,
  username,
  setUsername,
  whatsappNumber,
  setWhatsappNumber,
  country,
  setCountry,
  state,
  setState,
  usercategories,
  userCategory,
  setUserCategories,
  countries,
  states,
  userRegional,
  selectedRegionalPartner,
  setSelectedRegionalPartner,
  setcities,
  cities,
  city,
  isEvent,
  setEventCategory,
}) => {
  return (
    <>
      <FormGroup>
        <Label for="category">Role</Label>
        {isId ? (
          <Input
            type="select"
            name="category"
            id="category"
            className="form-control digits"
            value={userRole}
            onChange={(e) => setUserRole(e.target.value)}
          >
            <option value="" disabled>
              Select Role
            </option>
            {userRoles &&
              userRoles.map((item, index) => (
                <option key={index} value={item.role}>
                  {item.role}
                </option>
              ))}
          </Input>
        ) : (
          <Input
            type="select"
            name="category"
            id="category"
            className="form-control digits"
            value={userRole}
            onChange={(e) => setUserRole(e.target.value)}
          >
            <option value="">Select Role</option>
            {userRoles &&
              userRoles.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.role}
                </option>
              ))}
          </Input>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="username">Username</Label>
        <Input
          type="text"
          name="username"
          id="username"
          className="form-control"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Enter username"
        />
      </FormGroup>

      {/* <FormGroup>
                <Label for="eventtitle">Event Title</Label>
                <Input
                    type="text"
                    name="eventtitle"
                    id="eventtitle"
                    className="form-control"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter username"
                />
            </FormGroup> */}

      <FormGroup>
        <Label for="whatsappNumber">Phone Number</Label>
        <Input
          type="text"
          name="whatsappNumber"
          id="whatsappNumber"
          className="form-control"
          value={whatsappNumber}
          onChange={(e) => setWhatsappNumber(e.target.value)}
          placeholder="Enter Phone Number"
        />
      </FormGroup>

      <FormGroup>
        <Label for="state">State</Label>
        <Input
          type="select"
          name="state"
          id="state"
          className="form-control digits"
          value={state}
          onChange={(e) => setState(e.target.value)}
        >
          <option value="">Select State</option>
          {states.map((state, index) => (
            <option key={index} value={state.id}>
              {state.name}
            </option>
          ))}
        </Input>
      </FormGroup>

      <FormGroup>
        <Label for="country">City</Label>
        <Input
          type="select"
          name="city"
          id="city"
          className="form-control digits"
          value={cities}
          onChange={(e) => setcities(e.target.value)}
        >
          <option value="">Select City</option>
          {city.map((city, index) => (
            <option key={index} value={city.id}>
              {city.name}
            </option>
          ))}
        </Input>
      </FormGroup>
      {isEvent ? (
        <FormGroup>
        <Label for="category">Event type</Label>
        <Input
            type="text"
            name="category"
            id="category"
            className="form-control"
            value={usercategories}
            onChange={(e) => setUserCategories(e.target.value)}
            placeholder="Enter event type"
        />
    </FormGroup>
      ) : (
        <FormGroup>
          <Label for="category">Category</Label>
          <Input
            type="select"
            name="category"
            id="category"
            className="form-control digits"
            value={usercategories}
            onChange={(e) => setUserCategories(e.target.value)}
          >
            <option value="" disabled>
              Select Category
            </option>
            {userCategory.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Input>
        </FormGroup>
      )}

      {isEvent &&
        <FormGroup>
        <Label for="category">Event category</Label>
        <Input
            type="text"
            name="category"
            id="category"
            className="form-control"
            value={userCategory}
            onChange={(e) => setEventCategory(e.target.value)}
            placeholder="Enter event type"
        />
    </FormGroup>  
    }

      <FormGroup>
        <Label for="regionalPartner">Regional Partner</Label>
        <Input
          type="select"
          name="regionalPartner"
          id="regionalPartner"
          className="form-control digits"
          value={selectedRegionalPartner}
          onChange={(e) => setSelectedRegionalPartner(e.target.value)}
        >
          <option value="" disabled>
            Select Regional Partner
          </option>
          {userRegional.map((regional, index) => (
            <option key={index} value={regional.id}>
              {regional.regional_name}
            </option>
          ))}
        </Input>
      </FormGroup>
    </>
  );
};

export default FilterForm;
