import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { hearabouttabledata, hearabouttableColumns } from '../common/mockData';
import { Btn, H4 } from '../../../../AbstractElements';
import NewHearAboutUs from './NewHearAboutUs';
import { API_URL } from '../config/api_urls';
import { DELETE, get, patch, post } from '../config';
import { toast, ToastContainer } from 'react-toastify';
import ConfirmationModal from '../common/Confiemationdeletemodal'; 


function HearAboutUs() {
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [hearaboutusModal, setHearaboutusModal] = useState(false);
  const [editHearAbout, setEditHearAbout] = useState()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);


  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  useEffect(() => {
    fetchHearAboutUs();
  }, [])



const fetchHearAboutUs = useCallback(() => {
  get(API_URL.HEAR_ABOUT_US.HEAR_ABOUT_US_GET)
    .then((response) => {
      console.log(response);
      
      if (response?.data.length >= 0)
        setData(response.data)
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}, []);

console.log("Data for Table: ", data);


const hearaboutToggle =()=>{
  setHearaboutusModal(!hearaboutusModal);
  setEditHearAbout(null);
}

const editmodalToggle =(row)=>{
  setHearaboutusModal(!hearaboutusModal);
  setEditHearAbout(row)
}

const handleDeleteConfirmation = (row) => {
  setRowToDelete(row);
  setIsDeleteModalOpen(true); 
};

const handleConfirmDelete = () => {
  setIsDeleteModalOpen(false);
  handleDeletedata(rowToDelete);
};


  const handleSave = async (formData) => {
    console.log(formData);
    
    if (formData?.id) {
      patch(API_URL.HEAR_ABOUT_US.HEAR_ABOUT_US_PATCH(formData?.id), formData)
        .then((response) => {
          toast.success("Updated successfully",{autoClose:1000});
          setHearaboutusModal(!hearaboutusModal);
          setEditHearAbout(null)
          fetchHearAboutUs();
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          toast.error("Oops! something went wrong",{autoClose:1000});
        });
    } else {
      try {
        await post(API_URL.HEAR_ABOUT_US.HEAR_ABOUT_US_POST, formData);
        toast.success("User Created created successfully",{autoClose:1000});
        setHearaboutusModal(!hearaboutusModal);
        setEditHearAbout(null)
        fetchHearAboutUs();
      } catch (error) {
        console.error("Error saving data:", error);
        toast.error("Oops! something went wrong",{autoClose:1000});
      }
    }
  }


const handleDeletedata = (row)=>{
  if(row?.id){
   DELETE(API_URL.HEAR_ABOUT_US.HEAR_ABOUT_US_DELETE(row?.id))
   .then((response) => {
     toast.success("Deleted successfully:",{autoClose:1000});
     fetchHearAboutUs()
   })
   .catch((error) => {
     toast.error("Oops! something went wrong",{autoClose:1000});
   });
  }
}


const handleCancel = () => {
  setHearaboutusModal(!hearaboutusModal);
  setEditHearAbout(null)
};


  return (
    <>
      <div>
        <div className={`d-flex align-items-center justify-content-between  p-4`}>
          <H4 attrH4={{ className: 'text-muted m-0' }}>HEAR ABOUT US</H4>
          <Btn attrBtn={{ color: 'primary', onClick: hearaboutToggle }}>+ Add New </Btn>
          <NewHearAboutUs
            isOpen={hearaboutusModal}
            toggle={hearaboutToggle}
            title="Our Channels"
            hearAboutUsData={editHearAbout}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        </div>
        <div>
          <DataTable
            data={data}
            columns={hearabouttableColumns(editmodalToggle, handleDeleteConfirmation)}
            striped={true}
            center={true}
            pagination
            selectableRows={false}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleDelet}
          />
        </div>
      </div>
      
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        onConfirm={handleConfirmDelete}
        message="Are you sure to delete?"
      />

    </>
  )

}

export default HearAboutUs

