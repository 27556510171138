import React, { useCallback, useEffect, useState } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { Btn, H6, P } from '../../../../AbstractElements';
import '../../styles/user-settings.css';
import { API_URL } from '../config/api_urls';
import { get, patch, post } from '../config';
import { toast } from 'react-toastify';
import "./userSettings.css"
import { constructFormData } from '../config/imageHandle';
import ReactQuill from 'react-quill';

const mediaUrl = "http://127.0.0.1:8000"


const UserSettings = () => {
  const [data, setData] = useState([]);
  const [formDataConfig, setFormDataConfig] = useState({
    initialState: [
      {
        id: null,
        title: "",
        description: "",
        image: "",
        color_theme: "",
      },
      {
        id: null,
        title: "",
        description: "",
        image: "",
        color_theme: "",
      },
      {
        id: null,
        title: "",
        description: "",
        image: "",
        color_theme: "",
      }
    ]
  });
  console.log("formDataConfig ", formDataConfig.initialState);

  useEffect(() => {
    fetchUsersSettings();
  }, []);

  const fetchUsersSettings = useCallback(() => {
    get(API_URL.USERS_SETTINGS.GET_USERS_SETTINGS)
      .then((response) => {
        console.log(response);
        
        if (response?.data && response.data.length > 0){
          setData(response.data);
        }
        else{
          console.log('no response');
          setData(formDataConfig.initialState) 
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("error fetching user settings")
      });
  }, []);

  
  
  useEffect(()=>{
    if (data.length > 0) {
      setFormDataConfig((prevValue) => ({
        ...prevValue,
        initialState: data
      }));
    }
  },[data])
  console.log(data);
  
  

  // const handleInputChange = (e, index) => {    
  //   const { name, value,files} = e.target;
  //   const updatedFormData = [...formDataConfig.initialState];
  //   updatedFormData[index] = {
  //     ...updatedFormData[index],
  //     [name]: name === 'image' ? files[0] : value, 
  //   };
  //   setFormDataConfig({ initialState: updatedFormData });
  // };

  const handleInputChange = (e, index, field = null) => {
    const updatedFormData = [...formDataConfig.initialState];
    
    if (field === 'description') {
      updatedFormData[index] = {
        ...updatedFormData[index],
        description: e, // e is the new description value from ReactQuill
      };
    } else {
      const { name, value, files } = e.target;
      updatedFormData[index] = {
        ...updatedFormData[index],
        [name]: name === 'image' ? files[0] : value, 
      };
    }
    
    setFormDataConfig({ initialState: updatedFormData });
  };
  

  const renderForms = () => {
    return formDataConfig.initialState.map((group, index) => (
      <div className='form-container' key={index}>
        <H6>Intro Slider {index + 1}</H6>
        <FormGroup>
          <Label className="col-form-label">Title</Label>
          <Input
            className="form-control"
            type="text"
            placeholder="your Name"
            name='title'
            value={group.title}
            onChange={(e) => handleInputChange(e, index)}
          />
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">Description</Label>
          <ReactQuill
            value={group.description}
            onChange={(value) => handleInputChange(value, index, 'description')}
            placeholder="Enter description"
          />
          {/* <Input
            className="form-control"
            type="textarea"
            placeholder="Enter description"
            name='description'
            value={group.description}
            onChange={(e) => handleInputChange(e, index)}
          /> */}
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">Image</Label>
          <div className="file-input-wrapper">
            <Input
              className="form-control"
              type="file"
              name='image'
              onChange={(e) => handleInputChange(e, index)}
            />
            {group.image && (
               <img 
               src={mediaUrl + group.image} 
               alt={`IntroSlider${index + 1} Image`} 
               className="image-preview"
             />
            )}
          </div>
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">ColorTheme</Label>
          <Input
            className="form-control"
            type="text"
            placeholder="Enter color theme"
            name='color_theme'
            value={group.color_theme}
            onChange={(e) => handleInputChange(e, index)}
          />
        </FormGroup>
      </div>
    ));
  };

  


  const prepairFormData = (data)=>{
    const formData = new FormData();
    data.forEach((item) => {
      formData.append(`id`, item.id);
      formData.append(`title`, item.title);
      formData.append(`description`, item.description);
      if (item.image) {
        formData.append(`image`, item.image);
      }
      formData.append(`colortheme`, item.color_theme);
    });
    return formData;
  }


  const handleSave = async () => {
    
   
    const datawithId = formDataConfig.initialState.filter(i=>i.id)

    const datawithoutId = formDataConfig.initialState.filter(i=>!i.id)

    try {
      if (datawithId) {
        datawithId.map(i=>{
          const item = {...i};
          if(typeof i.image === "string"){
            delete item.image;
          }
           patch(API_URL.USERS_SETTINGS.PATCH_USERS_SETTINGS(i.id), prepairFormData([item]));
        })
        toast.success("Updated successfully");
      } else {
        await post(API_URL.USERS_SETTINGS.POST_USERS_SETTINGS, prepairFormData(datawithoutId));
        toast.success("Users Settings Created successfully");
      }
      fetchUsersSettings();
    } 
    catch (error) {
      console.error("Error saving data:", error);
      toast.error("Oops! something went wrong");
    }
  };

  return (
    <div className='p-3'>
      {formDataConfig.initialState.length > 0? 
      <Form className="theme-form mega-form">
        {renderForms()}
        <div className='save-btn'>
          <Btn attrBtn={{ color: 'primary', onClick: handleSave }}>Save Changes</Btn>
        </div>
      </Form>:
      <p>Loading....</p>
      }
    </div>
  );
};

export default UserSettings;
