// dashbaord
import Crypto from "../Components/Dashboard/Crypto";
import Default from "../Components/Dashboard/Default";
import Ecommerce from "../Components/Dashboard/Ecommerce";
import OnlineCourse from "../Components/Dashboard/OnlineCourse";
import Social from "../Components/Dashboard/Social";

// //widgets
import Chart from "../Components/Widgets/Chart";
import General from "../Components/Widgets/General";

// //page-layout
// import FooterLight from '../Components/PageLayout/FooterLight';

// import FooterDark from '../Components/PageLayout/FooterDark';
// //ui-kits
import Typography from "../Components/UiKits/Typography";
import Avatars from "../Components/UiKits/Avtars";
import HelperClasses from "../Components/UiKits/HelperClass";
import Grid from "../Components/UiKits/Grid";
import TagAndPills from "../Components/UiKits/TagAndPills";
import Progressbars from "../Components/UiKits/Progress";
import Modals from "../Components/UiKits/Modals";
import Alert from "../Components/UiKits/Alert";
import Popover from "../Components/UiKits/Popover";
import Tooltips from "../Components/UiKits/Tooltip";
import Spinners from "../Components/UiKits/Spinners";
import Dropdowns from "../Components/UiKits/Dropdown";
import Accordian from "../Components/UiKits/Accordian";
import TabBootstrap from "../Components/UiKits/Tabs/BootstrapTab";
import TabLine from "../Components/UiKits/Tabs/LineTab";
import Navs from "../Components/UiKits/Navs";
import Shadow from "../Components/UiKits/Shadows";
import List from "../Components/UiKits/Lists";

// //Bonus-ui
import Scrollable from "../Components/Bonus-Ui/Scrollable";
import TreeView from "../Components/Bonus-Ui/TreeView";
import BootstrapNotify from "../Components/Bonus-Ui/BootstrapNotify";
import RatingClass from "../Components/Bonus-Ui/Rating";
import Dropzones from "../Components/Bonus-Ui/Dropzones";
import Tours from "../Components/Bonus-Ui/Tour";
import SweetAlerts from "../Components/Bonus-Ui/SweetAlert";
import Carousels from "../Components/Bonus-Ui/Carousel";
import Ribbons from "../Components/Bonus-Ui/Ribbons";
import Paginations from "../Components/Bonus-Ui/Pagination";
import Steps from "../Components/Bonus-Ui/Steps";
import BreadcrumbClass from "../Components/Bonus-Ui/Breadcrumb";
import RangeSlider from "../Components/Bonus-Ui/RangeSlider";
import Imagecropper from "../Components/Bonus-Ui/ImageCropper";
import Sticky from "../Components/Bonus-Ui/Sticky";
import DragAndDrop from "../Components/Bonus-Ui/DragAndDrop";
import UploadImage from "../Components/Bonus-Ui/ImageUpload";
import BasicCards from "../Components/Bonus-Ui/Cards/BasicCards/index";
import CreativeCards from "../Components/Bonus-Ui/Cards/CreativeCards";
import TabCard from "../Components/Bonus-Ui/Cards/TabCard";
import Timeline1 from "../Components/Bonus-Ui/Timeline/Timeline1";
// // Icons
import WeatherIcon from "../Components/Icons/WeatherIcon";
import FeatherIcon from "../Components/Icons/FeatherIcon";
import ThemifyIcon from "../Components/Icons/ThemifyIcon";
import IcoIcon from "../Components/Icons/IcoIcon";
import FontawesomeIcon from "../Components/Icons/FontawesomeIcon";
import FlagIcon from "../Components/Icons/FlagIcon";

// // Buttons
import DefaultButton from "../Components/Buttons/Default";
import FlatButton from "../Components/Buttons/FlatBtn/index";
import EdgeButton from "../Components/Buttons/Edge/index";
import RaisedButton from "../Components/Buttons/RaisedBtn/index";
import GroupButton from "../Components/Buttons/GroupBtn/index";

// //chart
import ApexCharts from "../Components/Charts/ApexCharts";
import ChartJs from "../Components/Charts/ChartsJs";
import GoogleCharts from "../Components/Charts/GoogleCharts";

// //forms
import FormValidation from "../Components/Forms/FormControl/FormValidation";
import BaseInput from "../Components/Forms/FormControl/BaseInput";
import CheckboxandRadio from "../Components/Forms/FormControl/CheckRadio";
import InputGroups from "../Components/Forms/FormControl/InputGroups";
import MegaOption from "../Components/Forms/FormControl/MegaOption";
import Datepicker from "../Components/Forms/FormWidget/DatePicker";
import TypeaheadComp from "../Components/Forms/FormWidget/TypeaheadComponent/Typeahead";
import FormDefault from "../Components/Forms/FormLayout/Default";
import FormWizard from "../Components/Forms/FormLayout/FormWizard1/FormWizard1";
import BasicTables from "../Components/Tables/Reactstrap/BasicTable";

import DataTables from "../Components/Tables/DataTable";

// //Application
import Newproject from "../Components/Application/Project/Newproject";
import Project from "../Components/Application/Project/Project";
import Chat from "../Components/Application/Chat/ChatApp";
import VideoChat from "../Components/Application/Chat/VideoChat";

import Contact from "../Components/Application/Contact/ContactApp";
import Task from "../Components/Application/Task";
import TodoContain from "../Components/Application/Todo";

// //Gallary
import ImageGallery from "../Components/Gallery/ImageGallery";
import MesonryDesc from "../Components/Gallery/MesonryDesc";
import MasonryGallery from "../Components/Gallery/MesonryGallery";
import ImageWithDesc from "../Components/Gallery/ImageWithDesc";
import ImageHover from "../Components/Gallery/ImageHover";

// //Blog
import BlogDetail from "../Components/Blog/BlogDetail";
import BlogPost from "../Components/Blog/BlogPost";
import BlogSingle from "../Components/Blog/BlogSingle";

// //Pages
import SamplePage from "../Components/Pages/Sample";
// // import SearchPage from '../Components/Application/SearchResult'

// //Book mark
import BookmarksContain from "../Components/Application/BookMarks";

// //User app
import UsersCardssContain from "../Components/Application/Users/UsersCards";
import UsersEditContain from "../Components/Application/Users/UsersEdit";
import UsersProfileContain from "../Components/Application/Users/UsersProfile";

// //social app
import SocialAppContain from "../Components/Application/SocialApp";

// //calender
// import CalenderContain from '../Components/Application/Calender/Index';

// //Email
import MailInboxContain from "../Components/Application/Email/MailInbox";

// //File Manager
import FileManagerContain from "../Components/Application/FileManager";
import SearchResultContain from "../Components/Application/SearchResult";

// //E-commerce
import Products from "../Components/Application/Ecommerce/Products";
import ProductCart from "../Components/Application/Ecommerce/ProductCart";
import WishList from "../Components/Application/Ecommerce/Wishlist";
import CheckOut from "../Components/Application/Ecommerce/CheckOut";
import Invoice from "../Components/Application/Ecommerce/Invoice";
import OrderHistory from "../Components/Application/Ecommerce/OrderHistory";
import ProductPage from "../Components/Application/Ecommerce/ProductPage";
import PricingMemberShip from "../Components/Application/Ecommerce/PricingMemberShip";
import PaymentDetails from "../Components/Application/Ecommerce/PaymentDetails";
import ProductListContain from "../Components/Application/Ecommerce/ProductList";
import DraggableContain from "../Components/Application/Calender/DraggableCalender";

// //Job Search
import JobCards from "../Components/JobSearch/JobCardView";
import ListView from "../Components/JobSearch/ListView";
import JobDetails from "../Components/JobSearch/JobDetail";
import Apply from "../Components/JobSearch/Apply";

// //Learning
import LearningList from "../Components/Learning/LearningList";
import DetailCourse from "../Components/Learning/DetailCourse";

// //Map
import GoogleMaps from "../Components/Map/GoogleMap";
import PigeonMap from "../Components/Map/Pigeon Map";

// //Editor
import CkEditorContain from "../Components/Editor/CkEditor";
import MdeEDitorContain from "../Components/Editor/MDEEditor/MDEEditor";
import ACEEditorContain from "../Components/Editor/AceCodeEditor/ACECodeEditor";

import KnowledgeBaseContain from "../Components/Knowledgebase/KnowledgeBase";
import KnowledgeCategoryContain from "../Components/Knowledgebase/KnowledgeCategory";
import KnowledgeDetailContain from "../Components/Knowledgebase/KnowledgeDetails";
import SupportTickitContain from "../Components/SupportTicket";
import DateTimeContain from "../Components/Forms/FormWidget/DateandTime";
import FormDateRangepickerContain from "../Components/Forms/FormWidget/FormDateRangePicker";
import FormSelectContain from "../Components/Forms/FormWidget/FormSelect2";
import FormSwitchContain from "../Components/Forms/FormWidget/FormSwitch";
import FormTouchspinContain from "../Components/Forms/FormWidget/FormTouchspin";
import FormClipboardContain from "../Components/Forms/FormWidget/FormClipboard";
import FAQContent from "../Components/FAQ";
import UsersTable from "../Components/Dashboard/Default/users/UsersTable";
import NewUser from "../Components/Dashboard/Default/users/NewUser";
import UserRole from "../Components/Dashboard/Default/users/UserRole";
import UserEdit from "../Components/Dashboard/Default/users/UserEdit";
import EventCategory from "../Components/Dashboard/Default/events/EventCategory";
import EventOpenings from "../Components/Dashboard/Default/events/EventOpenings";
import UserCategory from "../Components/Dashboard/Default/users/UserCategory";
import HearAboutUs from "../Components/Dashboard/Default/users/HearAboutUs";
import RegionalPartners from "../Components/Dashboard/Default/users/RegionalPartners";
import AddRegionalPartners from "../Components/Dashboard/Default/users/AddRegionalPartners";
import EventApplication from "../Components/Dashboard/Default/events/EventApplication";
import AddApplication from "../Components/Dashboard/Default/events/AddApplication";
import JobType from "../Components/Dashboard/Default/events/JobType";
import MarketPlace from "../Components/Dashboard/Default/marketPlace/MarketPlace";
import SizeChart from "../Components/Dashboard/Default/marketPlace/SizeChart";
import MarketCategory from "../Components/Dashboard/Default/marketPlace/MarketCategory";
import AddMarketPlace from "../Components/Dashboard/Default/marketPlace/AddMarketPlace";
import Order from "../Components/Dashboard/Default/businessSection/Order";
import Idcard from "../Components/Dashboard/Default/idCard/Idcard";
import Attributes from "../Components/Dashboard/Default/attributes/Attributes";
import UserAttributes from "../Components/Dashboard/Default/attributes/UserAttributes";
import Preference from "../Components/Dashboard/Default/preference/Preference";
import UserPreference from "../Components/Dashboard/Default/preference/UserPreference";
import Notification from "../Components/Dashboard/Default/notification/Notification";
import UserSettings from "../Components/Dashboard/Default/settings/UserSettings";
import AppVersion from "../Components/Dashboard/Default/settings/AppVersion";
import PaymentSettings from "../Components/Dashboard/Default/settings/PaymentSettings";
import TermsCondition from "../Components/Dashboard/Default/settings/TermsCondition";
import PaymentTerms from "../Components/Dashboard/Default/settings/PaymentTerms";
import AboutUs from "../Components/Dashboard/Default/settings/AboutUs";
import Whatsapp from "../Components/Dashboard/Default/settings/Whatsapp";
import ContactUs from "../Components/Dashboard/Default/settings/ContactUs";
import DashboardSettings from "../Components/Dashboard/Default/settings/DashboardSettings";
import ProfilePage from "../Components/Dashboard/Default/accountPages/ProfilePage";
import NewJobOpening from "../Components/Dashboard/Default/events/NewJobOpening";
import Newidcard from "../Components/Dashboard/Default/idCard/Newidcard";
import NewAttribute from "../Components/Dashboard/Default/attributes/NewAttribute";
import IdCardSettings from "../Components/Dashboard/Default/settings/IdCardSettings";
import Login from "../Components/Dashboard/Default/Auth/Login";
import EditOrder from "../Components/Dashboard/Default/businessSection/EditOrder";
import EditIdcard from "../Components/Dashboard/Default/idCard/EditIdcard";
// import { useState } from "react";
import IdcardTerms from "../Components/Dashboard/Default/idCard/IdcardTerms";
import Socialmedia from "../Components/Dashboard/Default/socialmedia/Socialmedia";
import UserSocialmedia from "../Components/Dashboard/Default/socialmedia/Usersocialmedia";
import Popupimage from "../Components/Dashboard/Default/events/Popupimage";

// import UserDetailsPage from '../Components/Dashboard/Default/users/UserDetailsModal'

import { MyVerticallyCenteredModal } from "../Components/Dashboard/Default/users/Gallary";
import AppliedEvents from "../Components/Dashboard/Default/events/AppliedEvents";
import UserGallery from "../Components/Dashboard/Default/users/UserGallery";
import MessageReview from "../Components/Dashboard/Default/notification/MessageReview";
import CreateMessage from "../Components/Dashboard/Default/notification/CreateMessage";
import UserNotificationMessage from "../Components/Dashboard/Default/notification/Usernotification";

// const [isLogin,setIsLogin] = useState(false)

export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard/login`, Component: <Login /> },

  {
    path: `${process.env.PUBLIC_URL}/payment-terms/home`,
    Component: <IdcardTerms />,
  },

  { path: `${process.env.PUBLIC_URL}/home`, Component: <Default /> },

  {
    path: `${process.env.PUBLIC_URL}/users/list/home`,
    Component: <UsersTable />,
  },
  { path: `${process.env.PUBLIC_URL}/users/new/home`, Component: <NewUser /> },
  {
    path: `${process.env.PUBLIC_URL}/users/edit/home`,
    Component: <UserEdit />,
  },

  // { path: `${process.env.PUBLIC_URL}//gallery/:userId`, Component: <Gallery /> },

  // { path: `${process.env.PUBLIC_URL}//users/usersdetails/:uuid`, Component: <UserDetailsPage /> },

  {
    path: `${process.env.PUBLIC_URL}/users/role/home`,
    Component: <UserRole />,
  },

  {
    path: `${process.env.PUBLIC_URL}/users/category/home`,
    Component: <UserCategory />,
  },

  {
    path: `${process.env.PUBLIC_URL}/users/hear-about/home`,
    Component: <HearAboutUs />,
  },

  {
    path: `${process.env.PUBLIC_URL}/users/regional-partners/home`,
    Component: <RegionalPartners />,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/regional-partners/add/home`,
    Component: <AddRegionalPartners />,
  },

  {
    path: `${process.env.PUBLIC_URL}/users/gallery/:user_uuid`,
    Component: <MyVerticallyCenteredModal />,
  },

  {
    path: `${process.env.PUBLIC_URL}/events/categories/home`,
    Component: <EventCategory />,
  },

  {
    path: `${process.env.PUBLIC_URL}/events/application-count/:uuid`,
    Component: <AppliedEvents />,
  },

  {
    path: `${process.env.PUBLIC_URL}/events/popupimage/home`,
    Component: <Popupimage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/events/openings/home`,
    Component: <EventOpenings />,
  },
  {
    path: `${process.env.PUBLIC_URL}/events/openings/new/home`,
    Component: <NewJobOpening />,
  },

  {
    path: `${process.env.PUBLIC_URL}/events/jobtypes/home`,
    Component: <JobType />,
  },

  {
    path: `${process.env.PUBLIC_URL}/events/application/home`,
    Component: <EventApplication />,
  },
  {
    path: `${process.env.PUBLIC_URL}/events/application/add/home`,
    Component: <AddApplication />,
  },

  {
    path: `${process.env.PUBLIC_URL}/market-place/home`,
    Component: <MarketPlace />,
  },
  {
    path: `${process.env.PUBLIC_URL}/market-place/add-new/home`,
    Component: <AddMarketPlace />,
  },
  {
    path: `${process.env.PUBLIC_URL}/categories/home`,
    Component: <MarketCategory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/size-chart/home`,
    Component: <SizeChart />,
  },

  {
    path: `${process.env.PUBLIC_URL}/business-section/order/home`,
    Component: <Order />,
  },
  {
    path: `${process.env.PUBLIC_URL}/business-section/order/edit/home`,
    Component: <EditOrder />,
  },

  { path: `${process.env.PUBLIC_URL}/id-card/home`, Component: <Idcard /> },
  {
    path: `${process.env.PUBLIC_URL}/id-card/new/home`,
    Component: <Newidcard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/id-card/edit/home`,
    Component: <EditIdcard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings/idcard-settings/home`,
    Component: <IdCardSettings />,
  },

  {
    path: `${process.env.PUBLIC_URL}/attributes/home`,
    Component: <Attributes />,
  },
  {
    path: `${process.env.PUBLIC_URL}/attributes/new/home`,
    Component: <NewAttribute />,
  },
  {
    path: `${process.env.PUBLIC_URL}/user-attributes/home`,
    Component: <UserAttributes />,
  },

  {
    path: `${process.env.PUBLIC_URL}/preference/home`,
    Component: <Preference />,
  },
  {
    path: `${process.env.PUBLIC_URL}/user-preference/home`,
    Component: <UserPreference />,
  },

  {
    path: `${process.env.PUBLIC_URL}/socialmedia/home`,
    Component: <Socialmedia />,
  },
  {
    path: `${process.env.PUBLIC_URL}/user-socialmedia/home`,
    Component: <UserSocialmedia />,
  },

  {
    path: `${process.env.PUBLIC_URL}/create-message/home`,
    Component: <CreateMessage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/notification/home`,
    Component: <Notification />,
  },
  {
    path: `${process.env.PUBLIC_URL}/review/home`,
    Component: <MessageReview />,
  },

  {
    path: `${process.env.PUBLIC_URL}/usernotification/home`,
    Component: <UserNotificationMessage />,
  },

  {
    path: `${process.env.PUBLIC_URL}/settings/user-settings/home`,
    Component: <UserSettings />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings/app-version/home`,
    Component: <AppVersion />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings/payment-settings/home`,
    Component: <PaymentSettings />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings/terms-conditions/home`,
    Component: <TermsCondition />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings/payment-terms/home`,
    Component: <PaymentTerms />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings/contact-us/home`,
    Component: <ContactUs />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings/about-us/home`,
    Component: <AboutUs />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings/whatsapp/home`,
    Component: <Whatsapp />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings/dashboard/home`,
    Component: <DashboardSettings />,
  },

  {
    path: `${process.env.PUBLIC_URL}/accountpages/profile/home`,
    Component: <ProfilePage />,
  },

  // { path: `${process.env.PUBLIC_URL}/dashboard/default/home`, Component: <Default /> },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/e-commerce/home`,
    Component: <Ecommerce />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/online-course/home`,
    Component: <OnlineCourse />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/crypto/home`,
    Component: <Crypto />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/social/home`,
    Component: <Social />,
  },

  {
    path: `${process.env.PUBLIC_URL}/widgets/general/home`,
    Component: <General />,
  },
  {
    path: `${process.env.PUBLIC_URL}/widgets/chart/home`,
    Component: <Chart />,
  },

  {
    path: `${process.env.PUBLIC_URL}/ui-kits/typography/home`,
    Component: <Typography />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/avatar/home`,
    Component: <Avatars />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/helper-class/home`,
    Component: <HelperClasses />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/grids/home`, Component: <Grid /> },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tag-pills/home`,
    Component: <TagAndPills />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/progress/home`,
    Component: <Progressbars />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/modal/home`,
    Component: <Modals />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/alert/home`,
    Component: <Alert />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/popover/home`,
    Component: <Popover />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tooltips/home`,
    Component: <Tooltips />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/spinner/home`,
    Component: <Spinners />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/dropdown/home`,
    Component: <Dropdowns />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/accordion/home`,
    Component: <Accordian />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tabs/bootstrap/home`,
    Component: <TabBootstrap />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tabs/line/home`,
    Component: <TabLine />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/navs/home`, Component: <Navs /> },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/shadow/home`,
    Component: <Shadow />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/list/home`, Component: <List /> },

  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable/home`,
    Component: <Scrollable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/tree-view/home`,
    Component: <TreeView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/bootstrap-notify/home`,
    Component: <BootstrapNotify />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/rating/home`,
    Component: <RatingClass />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/dropzone/home`,
    Component: <Dropzones />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/tour/home`,
    Component: <Tours />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/sweet-alert/home`,
    Component: <SweetAlerts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/carousel/home`,
    Component: <Carousels />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/ribbons/home`,
    Component: <Ribbons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/pagination/home`,
    Component: <Paginations />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/steps/home`,
    Component: <Steps />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb/home`,
    Component: <BreadcrumbClass />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/rangeslider/home`,
    Component: <RangeSlider />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/imagecropper/home`,
    Component: <Imagecropper />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/stickynotes/home`,
    Component: <Sticky />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/drag_and_drop/home`,
    Component: <DragAndDrop />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/image-upload/home`,
    Component: <UploadImage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/basiccards/home`,
    Component: <BasicCards />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativecards/home`,
    Component: <CreativeCards />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabcard/home`,
    Component: <TabCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/timelines/timeline1/home`,
    Component: <Timeline1 />,
  },

  {
    path: `${process.env.PUBLIC_URL}/icons/flag_icons/home`,
    Component: <FlagIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/fontawesome_icon/home`,
    Component: <FontawesomeIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/ico_icon/home`,
    Component: <IcoIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/themify_icons/home`,
    Component: <ThemifyIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/feather_icons/home`,
    Component: <FeatherIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/weather_icons/home`,
    Component: <WeatherIcon />,
  },

  {
    path: `${process.env.PUBLIC_URL}/buttons/simplebutton/home`,
    Component: <DefaultButton />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/flat/home`,
    Component: <FlatButton />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/edge/home`,
    Component: <EdgeButton />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/raised/home`,
    Component: <RaisedButton />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/group/home`,
    Component: <GroupButton />,
  },

  {
    path: `${process.env.PUBLIC_URL}/charts/apex/home`,
    Component: <ApexCharts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/chartJs/home`,
    Component: <ChartJs />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/google/home`,
    Component: <GoogleCharts />,
  },

  {
    path: `${process.env.PUBLIC_URL}/forms/controls/validation/home`,
    Component: <FormValidation />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/controls/input/home`,
    Component: <BaseInput />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/controls/radio-checkbox/home`,
    Component: <CheckboxandRadio />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/controls/group/home`,
    Component: <InputGroups />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/controls/megaoption/home`,
    Component: <MegaOption />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/widget/datepicker/home`,
    Component: <Datepicker />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/widget/typeahead/home`,
    Component: <TypeaheadComp />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/widget/datetimepicker/home`,
    Component: <DateTimeContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/widget/rangepicker/home`,
    Component: <FormDateRangepickerContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/widget/select2/home`,
    Component: <FormSelectContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/widget/switch/home`,
    Component: <FormSwitchContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/widget/touchspin/home`,
    Component: <FormTouchspinContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/widget/clipboard/home`,
    Component: <FormClipboardContain />,
  },

  {
    path: `${process.env.PUBLIC_URL}/forms/layout/formDefault/home`,
    Component: <FormDefault />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/layout/formWizard/home`,
    Component: <FormWizard />,
  },

  // //Tables
  {
    path: `${process.env.PUBLIC_URL}/table/reactstraptable/basictable/home`,
    Component: <BasicTables />,
  },
  {
    path: `${process.env.PUBLIC_URL}/table/datatable/home`,
    Component: <DataTables />,
  },

  // //Applicatiion
  {
    path: `${process.env.PUBLIC_URL}/app/project/project-list/home`,
    Component: <Project />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/project/new-project/home`,
    Component: <Newproject />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/chat-app/chats/home`,
    Component: <Chat />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/chat-app/chat-video-app/home`,
    Component: <VideoChat />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/contact-app/contacts/home`,
    Component: <Contact />,
  },

  { path: `${process.env.PUBLIC_URL}/app/task/home`, Component: <Task /> },
  {
    path: `${process.env.PUBLIC_URL}/app/bookmark/home`,
    Component: <BookmarksContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/todo-app/todo/home`,
    Component: <TodoContain />,
  },

  {
    path: `${process.env.PUBLIC_URL}/app/users/profile/home`,
    Component: <UsersProfileContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/users/edit/home`,
    Component: <UsersEditContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/users/cards/home`,
    Component: <UsersCardssContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/social-app/home`,
    Component: <SocialAppContain />,
  },

  {
    path: `${process.env.PUBLIC_URL}/app/calendar/draggable-calendar/home`,
    Component: <DraggableContain />,
  },

  {
    path: `${process.env.PUBLIC_URL}/app/email-app/home`,
    Component: <MailInboxContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/file-manager/home`,
    Component: <FileManagerContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/search/home`,
    Component: <SearchResultContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/product/home`,
    Component: <Products />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/product-page/home/:id`,
    Component: <ProductPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/payment-details/home`,
    Component: <PaymentDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/orderhistory/home`,
    Component: <OrderHistory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/pricing/home`,
    Component: <PricingMemberShip />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/invoice/home`,
    Component: <Invoice />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/cart/home`,
    Component: <ProductCart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/wishlist/home`,
    Component: <WishList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/checkout/home`,
    Component: <CheckOut />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/product-list/home`,
    Component: <ProductListContain />,
  },
  // //Gallery
  {
    path: `${process.env.PUBLIC_URL}/app/gallery/grids/home`,
    Component: <ImageGallery />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/gallery/griddesc/home`,
    Component: <ImageWithDesc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/gallery/masonrys/home`,
    Component: <MasonryGallery />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/gallery/masonrydesc/home`,
    Component: <MesonryDesc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/gallery/hover_effect/home`,
    Component: <ImageHover />,
  },

  // //page
  {
    path: `${process.env.PUBLIC_URL}/pages/sample-page/home`,
    Component: <SamplePage />,
  },

  // //Blog
  {
    path: `${process.env.PUBLIC_URL}/app/blog/blogdetails/home`,
    Component: <BlogDetail />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/blog/blogsingle/home`,
    Component: <BlogSingle />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/blog/blogpost/home`,
    Component: <BlogPost />,
  },

  // //Job Search
  {
    path: `${process.env.PUBLIC_URL}/app/jobsearch/cardview/home`,
    Component: <JobCards />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/jobsearch/joblist/home`,
    Component: <ListView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/jobsearch/jobdetail/home`,
    Component: <JobDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/jobsearch/jobapply/home`,
    Component: <Apply />,
  },

  // //Learning
  {
    path: `${process.env.PUBLIC_URL}/app/learning/learninglist/home`,
    Component: <LearningList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/learning/learningdetail/home`,
    Component: <DetailCourse />,
  },

  // //Map
  {
    path: `${process.env.PUBLIC_URL}/app/map/googlemap/home`,
    Component: <GoogleMaps />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/map/pigeonmap/home`,
    Component: <PigeonMap />,
  },

  // //Editor
  {
    path: `${process.env.PUBLIC_URL}/editor/ckeditor/home`,
    Component: <CkEditorContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editor/mdeeditor/home`,
    Component: <MdeEDitorContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editor/aceeditor/home`,
    Component: <ACEEditorContain />,
  },

  // //Knowledge
  {
    path: `${process.env.PUBLIC_URL}/app/knowledgebase/home`,
    Component: <KnowledgeBaseContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/knowledgebase-category/home`,
    Component: <KnowledgeCategoryContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/knowledgebase-detail/home`,
    Component: <KnowledgeDetailContain />,
  },

  // //Support Ticket
  {
    path: `${process.env.PUBLIC_URL}/app/supportticket/home`,
    Component: <SupportTickitContain />,
  },

  // //Faq
  { path: `${process.env.PUBLIC_URL}/app/faq/home`, Component: <FAQContent /> },
];
