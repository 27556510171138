import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { dummyiddata, idcardtableColumns } from "../common/mockData";
import { useNavigate } from "react-router";
import { Btn, H4 } from "../../../../AbstractElements";
import EditCommonModal from "../common/EditCommonModal";
import IdCardFilter from "./IdCardFilter";
import { CSVLink } from "react-csv";
import { API_URL } from "../config/api_urls";
import { DELETE, get, patch } from "../config";
import { toast, ToastContainer } from "react-toastify";
import FilterForm from "../users/FilterForm";
import NewIdcard from "./Newidcard";
import ConfirmationModal from "../common/Confiemationdeletemodal";
import uuid from "react-uuid";
import { handleOpenModal as handleUserOpenModal } from "../users/UsersTable"; // Importing the function
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons"; // Import the faUser icon

function Idcard() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [idcardModal, setIdcardModal] = useState(false);
  const [newidcardModal, setnewidcardModal] = useState(false);

  const [regionalPartner, setRegionalPartner] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [editIdCard, setEditIdCard] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [idcardeditModal, setIdcardeditModal] = useState(false);
  const [currentEditData, setCurrentEditData] = useState(null);
  const [userData, setUserData] = useState([]);

  //userdata
  const [userPreferences, setUserPreferences] = useState([]);
  const [userAttributes, setUserAttributes] = useState([]);
  const [userSocial, setUserSocial] = useState([]);
  const [uservideo, setUservideo] = useState([]);
  const [userabout, setUserabout] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  // filter

  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [userCategory, setUserCategory] = useState([]);

  const [categoryModal, setCategoryModal] = useState(false);
  const [userRegional, setUserRegional] = useState([]);
  const [userRole, setUserRole] = useState();
  const [gender, setGender] = useState("");
  const [username, setUsername] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [selectedRegionalPartner, setSelectedRegionalPartner] = useState("");
  const [userCategories, setUserCategories] = useState();
  const [userRoles, setUserRoles] = useState([]);
  const [city, setcity] = useState([]);
  const [role, setRole] = useState([]);
  const [selectedid, setselectedid] = useState(null);

  useEffect(() => {
    fetchIdCardList();
    fetchUserRoles();
    fetchRegionalPartners();
    fetchUsersList();
  }, []);

  // const fetchIdCardList = useCallback(() => {
  // 	get(API_URL.IDCARDS.GET_IDCARDS)
  // 		.then((response) => {
  //       console.log(response);

  // 			if (response?.data.length >= 0) {
  //         const sortedData = response.data.sort((a,b) => b.
  //         id - a.id)
  //         console.log(sortedData);

  // 				setData(sortedData);
  // 				setOriginalData(sortedData);
  // 			}
  // 		})
  // 		.catch((error) => {
  // 			console.error("Error:", error);
  // 		});
  // }, []);

  // const handleOpenModal = (user) => {

  // console.log('usermodal',user);

  //   navigate(`/users/usersdetails/${user.uuid}`);
  // };

  const handleOpenModal = (row) => {
    console.log(row);

    const filteredUser = enrichedData.filter(
      (user) => user.uuid === row.user_uuid
    );

    console.log(enrichedData);

    // const userRole = userRoles.find(role => role.id === row.user_role_id)?.role || 'No Role';

    // const enrichedUser = {
    //   ...row,
    //   userRole,
    //   // Add userRole to the user object
    // };
    const url = `${process.env.PUBLIC_URL}/userdetails/${row.user_uuid}`;

    // Pass the filtered user data to the new page via state
    window.open(url, '_blank');
    // navigate(`/users/usersdetails/${row.user_uuid}`, {
    //   state: { userData: filteredUser[0] },
    // });
  };
  
  const fetchIdCardList = useCallback(() => {
    get(API_URL.IDCARDS.GET_IDCARDS)
      .then((response) => {
        console.log(response.data);
        
        if (response?.data.length > 0) {
          const reversedData = [...response.data].reverse(); // Create a reversed copy
          setData(reversedData);
          setOriginalData(reversedData);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  

  console.log("data", data);

  const fetchUserRoles = useCallback(() => {
    get(API_URL.USER_ROLE.USER_ROLE_GET)
      .then((response) => {
        if (response?.data.length > 0) console.log(response.data);

        setUserRoles(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchRegionalPartners = useCallback(() => {
    get(API_URL.REGIONAL_PARTNERS.GET_REGIONALS)
      .then((response) => {
        console.log(response);

        if (response?.data.length >= 0) setUserRegional(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log(userRegional);

  const handleProfileResponse = (profilesResponse) => {
    const profiles = profilesResponse?.data || [];
    console.log("Fetched User seprate Preferences:", profiles);
    setUserPreferences(profiles);
  };

  const handleAttributesResponse = (attributesResponse) => {
    const attributes = attributesResponse?.data || [];
    console.log("Fetched User Attributes:", attributes);
    setUserAttributes(attributes);
  };

  const handleSocialmediaResponse = (SocialResponse) => {
    const socialmedia = SocialResponse?.data || [];
    console.log("Fetched User Social Media:", socialmedia);
    setUserSocial(socialmedia);
  };

  const handleUservideoResponse = (videoResponse) => {
    const Uservideo = videoResponse?.data || [];
    console.log("Fetched User Video Media:", Uservideo);
    setUservideo(Uservideo);
  };

  const handleUseraboutResponse = (aboutResponse) => {
    const Userabout = aboutResponse?.data || [];
    console.log("Fetched User Video Media:", Userabout);
    setUserabout(Userabout);
  };

  const fetchUsersList = useCallback(async () => {
    try {
      const usersResponse = await get(API_URL.USERS.GET_USERS);
      const users = usersResponse?.data || [];
      console.log("Fetched Users:", users);

      // setOriginalData(users);
      setUserData(users);

      const profilesResponse = await get(
        API_URL.PROFILE_PREFERENCES.GET_PREFERENCES
      );
      const profiles = profilesResponse?.data || [];
      handleProfileResponse(profilesResponse);

      const attributesResponse = await get(
        API_URL.USER_ATTRIBUTES.GET_USER_ATTRIBUTES
      );
      const attributes = attributesResponse?.data || [];
      handleAttributesResponse(attributesResponse);

      const socialmediaResponse = await get(
        API_URL.SOCIAL_MEDIA.GET_SOCIAL_MEDIA
      );
      const socialmedia = socialmediaResponse?.data || [];
      handleSocialmediaResponse(socialmediaResponse);

      const videoResponse = await get(API_URL.PROFILE.GET_PROFILE);
      const Uservideo = videoResponse?.data || [];
      handleUservideoResponse(videoResponse);
      console.log("Video link", videoResponse);

      const useraboutResponse = await get(API_URL.PROFILE.GET_PROFILE);
      const about = useraboutResponse?.data || [];
      handleUseraboutResponse(useraboutResponse);

      console.log("Fetched User Preferences:", profiles);
      setUserData(users);
      setUserPreferences(profiles);
    } catch (error) {
      console.error("Error fetching users or preferences:", error);
    }
  }, []);

  const enrichedData = userData.map((user) => {
    const preference = userPreferences.find(
      (pref) => pref.user_name === user.name
    );
    const attribute = userAttributes.find(
      (attr) => attr.user_name === user.name
    );
    const socialmedia = userSocial.find(
      (media) => media.user_name === user.name
    );
    const Videolink = uservideo.find((video) => video.user_name === user.name);
    const About = userabout.find((about) => about.user_name === user.name);

    // console.log('chek this',uservideo);

    const videoLinks = Videolink?.video_links || []; // Get the video_link array
    const firstVideoLink = videoLinks.length > 0 ? videoLinks[0] : null;
    // console.log('this is video',videoLinks);

    return {
      ...user,
      preference: preference?.preference_name || "No Preference",
      attribute: attribute?.attribute_name || "No Attribute",
      media: socialmedia?.link || null,
      Video: firstVideoLink || "No Video",
      about: About?.about || "No About",
    };
  });
  const filteredRoleIds = data
    .map((userData) => {
      const filteredRole = role.find(
        (item) => item.role === userData.user_role_name
      );
      return filteredRole ? filteredRole.id : null; // Return null if no match
    })
    .filter((id) => id !== null); // Filter out null values

  console.log(filteredRoleIds);

  const handleNavigateToProfile = (id) => {
    console.log("id", id); // Log the id for debugging
    // navigate(`/idcards/idcardprofile/${id}`);
    const url = `${process.env.PUBLIC_URL}/idcards/idcardprofile/${id}`;

    // Pass the filtered user data to the new page via state
    window.open(url, '_blank');
    // Navigate to the profile page using the user ID
  };

  const handleNavigate = () => {
    navigate(`${process.env.PUBLIC_URL}/id-card/new/home`);
  };

  const onSave = (formData) => {
    if (formData.id) {
      patch(API_URL.IDCARDS.PATCH_IDCARD(formData.id), formData)
        .then((response) => {
          toast.success("Updated successfully");
          fetchIdCardList();
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          toast.error("Oops! something went wrong, try again");
        });
    } else {
      toast("Oops!something went wrong!");
    }
  };

  const handleEditdata = (row, e) => {
    console.log(row);
    console.log(e.target.value);

    const formData = {
      id: row.id,
      status: e.target.value,
    };
    onSave(formData);
  };

  const idcardToggle = () => {
    setIdcardModal(!idcardModal);
  };

  console.log(userRole);

  const handleSave = () => {
    idcardToggle();

    const filteredData = originalData.filter((item) => {
      const roleMatches = userRole
        ? String(item.user_role_name) === String(userRole)
        : true;
      const genderMatches = gender ? item.gender === gender : true;
      const usernameMatches = username
        ? item.name
          ? item.name.toLowerCase() === username.toLowerCase()
          : false
        : true;
      const PhonennumberMatches = whatsappNumber
        ? item.phone_number
          ? item.phone_number.includes(whatsappNumber)
          : false
        : true;
      console.log("Role Matches:", roleMatches); // Check if role matching works

      const regionalPartnerMatches = selectedRegionalPartner
        ? item.regional_partner_name === selectedRegionalPartner
        : true;

      // Fixing country and state filter comparison
      const countryMatches = country ? item.country_name === country : true;
      const cityMatches =
        cities.length > 0 ? cities.includes(item.city_id) : true;
      const stateMatches =
        state.length > 0 ? state.includes(item.state_id) : true;
      const categoryMatches = userCategories
        ? String(item.category_id) === String(userCategories)
        : true;

      return (
        roleMatches &&
        genderMatches &&
        usernameMatches &&
        PhonennumberMatches &&
        countryMatches &&
        stateMatches &&
        categoryMatches &&
        regionalPartnerMatches &&
        cityMatches
      );
    });

    setData(filteredData);
    setCsvData(filteredData);
    idcardToggle();
    setUserRole("");
    setGender("");
    setUsername("");
    setWhatsappNumber("");
    setCountry("");
    setState("");
    setUserCategories("");
  };

  const handleCancel = () => {
    idcardToggle();
    setUserRole("");
    setGender("");
    setUsername("");
    setWhatsappNumber("");
    setCountry("");
    setState("");
    setUserCategories("");
    setData(originalData);
    setCsvData(originalData);
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
    // setSelected(true)
  };

  const handleExport = () => {
    console.log(data);
  
    // Helper function to reassign IDs in numeric order
    const reassignIds = (rows) =>
      rows.map((row, index) => ({
        ...row,
        id: index + 1, // Assign numeric IDs starting from 1
      }));
  
    if (selectedRows.length > 0) {
      console.log('Selected rows for export');
      const updatedRows = reassignIds(selectedRows); // Reassign IDs
      setCsvData(updatedRows); // Set updated data for CSV export
    } else {
      console.log('Exporting all data');
      const updatedData = reassignIds(data); // Reassign IDs
      setCsvData(updatedData); // Set updated data for CSV export
    }
  };

  const handleRefresh = () => {
    fetchIdCardList();
  };

  const handleDeleteConfirmation = (row) => {
    setRowToDelete(row);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(false);
    handleDeletedata(rowToDelete);
  };

  const handleDeletedata = (row) => {
    console.log(row);

    if (row?.id) {
      DELETE(API_URL.IDCARDS.DELETE_IDCARD(row?.id))
        .then((response) => {
          toast.success("Deleted successfully", { autoClose: 1000 });
          fetchIdCardList();
        })
        .catch((error) => {
          console.error(error);

          toast.error("Oops! something went wrong", { autoClose: 1000 });
        });
    }
  };

  return (
    <>
      <div>
        <div
          className={`d-flex align-items-center justify-content-between  p-4`}
        >
          <H4 attrH4={{ className: "text-muted m-0" }}>All Id Card Data</H4>
          <div
            style={{
              width: "400px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Btn attrBtn={{ color: "primary", onClick: handleNavigate }}>
              + Free Id Card
            </Btn>
            <Btn attrBtn={{ color: "primary", onClick: idcardToggle }}>
              Filter
            </Btn>
            <EditCommonModal
              isOpen={idcardModal}
              toggle={idcardToggle}
              // editIdcardData ={editIdCard}
              title="Filter"
              onSave={handleSave}
              onCancel={handleCancel}
            >
              {/* <IdCardFilter
                          regionalPartner={regionalPartner}
                          setRegionalPartner={setRegionalPartner}
                          paymentStatus={paymentStatus}
                          setPaymentStatus={setPaymentStatus}
                        /> */}
              <FilterForm
                isId={true}
                userRole={userRole}
                userRoles={userRoles}
                setUserRole={setUserRole}
                gender={gender}
                setGender={setGender}
                username={username}
                setUsername={setUsername}
                whatsappNumber={whatsappNumber}
                setWhatsappNumber={setWhatsappNumber}
                country={country}
                setCountry={setCountry}
                state={state}
                setState={setState}
                city={city}
                cities={cities}
                setcities={setCities}
                usercategories={userCategories}
                userCategory={userCategory}
                setUserCategories={setUserCategories}
                countries={countries}
                states={states}
                userRegional={userRegional}
                selectedRegionalPartner={selectedRegionalPartner}
                setSelectedRegionalPartner={setSelectedRegionalPartner}
              />
            </EditCommonModal>
            <CSVLink
              data={csvData}
              filename="IdCard-data.csv"
              className="btn btn-primary"
              onClick={handleExport}
            >
              Export
            </CSVLink>
            <button
              type="button"
              className="btn mx-2"
              style={{
                width: "30px",
                height: "38px",
                padding: "0",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleRefresh}
            >
              <i className="fa fa-refresh " style={{ fontSize: "18px" }}></i>
            </button>
          </div>
        </div>
        <div>
          <DataTable
            data={data}
            columns={idcardtableColumns(
              handleEditdata,
              handleDeleteConfirmation,
              handleNavigateToProfile,
              handleOpenModal
            )}
            striped={true}
            center={true}
            pagination
            selectableRows={true}
            clearSelectedRows={toggleDelet}
            onSelectedRowsChange={handleRowSelected}
            fixedHeader
            fixedHeaderScrollHeight="80vh"
          />
        </div>
      </div>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        onConfirm={handleConfirmDelete}
        message="Are you sure to delete?"
      />
    </>
  );
}

export default Idcard;
