import ComingSimple from "../Components/Pages/ComingSoon/ComingSimple";
import CreatePwd from "../Components/Pages/Auth/CreatePwd";
import ForgetPwd from "../Components/Pages/Auth/ForgetPwd";
import LoginOne from "../Components/Pages/Auth/LoginOne";
import LoginSweetalert from "../Components/Pages/Auth/LoginSweetalert";
import LoginTooltip from "../Components/Pages/Auth/LoginTooltip";
import LoginTwo from "../Components/Pages/Auth/LoginTwo";
import LoginValidation from "../Components/Pages/Auth/LoginValidation";
import Maintenance from "../Components/Pages/Auth/Maintenance";
import RegisterBgImg from "../Components/Pages/Auth/RegisterBgImg";
import RegisterSimple from "../Components/Pages/Auth/RegisterSimple";
import RegisterVideo from "../Components/Pages/Auth/RegisterVideo";
import UnlockUser from "../Components/Pages/Auth/UnlockUser";
import ErrorPage1 from "../Components/Pages/ErrorPages/ErrorPage400";
import ErrorPage2 from "../Components/Pages/ErrorPages/ErrorPage401";
import ErrorPage3 from "../Components/Pages/ErrorPages/ErrorPage403";
import ErrorPage4 from "../Components/Pages/ErrorPages/ErrorPage404";
import Logins from "../Auth/Signin";
import LoginForm from "../Components/Pages/Auth/LoginForm";
import ComingBgImg from "../Components/Pages/ComingSoon/ComingBgImg";
import ComingBgVideo from "../Components/Pages/ComingSoon/ComingBgVideo";
import Error500 from "../Components/Pages/ErrorPages/ErrorPage500";
import Error503 from "../Components/Pages/ErrorPages/ErrorPage503";
import UserDetailsPage from "../Components/Dashboard/Default/users/UserDetailsModal";
import { Default } from "../Constant";
import UserGallery from "../Components/Dashboard/Default/users/UserGallery";
import UserVideolink from "../Components/Dashboard/Default/users/userVideolinks";
import IdCardProfile from "../Components/Dashboard/Default/idCard/Idcardprofile";

export const authRoutes = [
  { path: `${process.env.PUBLIC_URL}/login`, Component: <Logins /> },

  {
    path: `${process.env.PUBLIC_URL}/user/gallery/:user_uuid`,
    Component: <UserGallery />,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/videolinks/:user_uuid`,
    Component: <UserVideolink />,
  },
  {
    path: `${process.env.PUBLIC_URL}/idcards/idcardprofile/:id`,
    Component: <IdCardProfile />,
  },

  {
    path: `${process.env.PUBLIC_URL}/users/usersdetails/:uuid`,
    Component: <UserDetailsPage />,
  },

  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/login-simple/home`,
    Component: <LoginForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/login-img/home`,
    Component: <LoginOne />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/login-bg-img/home`,
    Component: <LoginTwo />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/login-validation/home`,
    Component: <LoginValidation />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/login-tooltip/home`,
    Component: <LoginTooltip />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/login-sweetalert/home`,
    Component: <LoginSweetalert />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/register-simple/home`,
    Component: <RegisterSimple />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/register-bg-img/home`,
    Component: <RegisterBgImg />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/register-video/home`,
    Component: <RegisterVideo />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/unlock-user/home`,
    Component: <UnlockUser />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/forget-pwd/home`,
    Component: <ForgetPwd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/create-pwd/home`,
    Component: <CreatePwd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/maintenance/home`,
    Component: <Maintenance />,
  },

  //Coming-soon
  {
    path: `${process.env.PUBLIC_URL}/pages/comingsoon/comingsoon/home`,
    Component: <ComingSimple />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/comingsoon/coming-bg-img/home`,
    Component: <ComingBgImg />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/comingsoon/coming-bg-video/home`,
    Component: <ComingBgVideo />,
  },

  //Error
  {
    path: `${process.env.PUBLIC_URL}/pages/errors/error400/home`,
    Component: <ErrorPage1 />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/errors/error401/home`,
    Component: <ErrorPage2 />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/errors/error403/home`,
    Component: <ErrorPage3 />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/errors/error404/home`,
    Component: <ErrorPage4 />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/errors/error500/home`,
    Component: <Error500 />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/errors/error503/home`,
    Component: <Error503 />,
  },
];
