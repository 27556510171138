import React, { useCallback, useEffect, useState } from 'react'
import EditCommonModal from '../common/EditCommonModal';
import { API_URL } from '../config/api_urls';
import { get } from '../config';
import { FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';

function AddUserRole({ editroleData, isOpen, toggle, title, onSave, onCancel }) { 
	const [isEditing, setIsEditing] = useState(false);
	const [formDataConfig, setFormDataConfig] = useState({

		initialState: {
			id: null,
			role: "",
			order_no:"",
			image:""
			// privileges: []
		},
		fields: [
			{
				name: "role",
				label: "Role",
				type: "text",
				placeholder: "Enter new role",
				required: false,
			},
			{
				name: "order_no",
				label: "Order No",
				type: "number",
				placeholder: "Enter new Order Number",
				required: false,
			},
            {
				name: "image",
				label: "Image",
				type: "file",
				required: false,
			},
			// {
			// 	name: "privileges",
			// 	label: "privileges",
			// 	type: "select",
			// 	options: [],
			// 	required: false,
			// },
		],
	});


	
	
	useEffect(() => {
		if (editroleData) {
			setIsEditing(true);
			setFormDataConfig(value => ({
				...value,
				initialState: {
					...editroleData,
				// privileges:editroleData.privileges.map((i) => ({
				// 	label: i.name,
				// 	value: i.id,
				// }))
				
			}
			}));
		} else {
			setIsEditing(false);
			setFormDataConfig(value => ({
				...value,
				initialState: {
					id: null,
					role: '',
					order_no:''

					// privileges: []
				},
			}));
		}
	}, [editroleData]);

	// useEffect(() => {
	// 	fetchprivilege()
	// }, [])

	//privilege dropdown
	// const fetchprivilege = useCallback(async () => {
	// 	try {
	// 		const response = await get(API_URL.PRIVILEGES.PRIVILEGES_GET);
	// 		const privilege_names = response?.data?.map((i) => ({
	// 			label: i.name,
	// 			value: i.id,
	// 		}));
	// 		privilege_names.unshift({ label: "Select privilege", value: "" });
	// 		if (privilege_names.length > 0) {
	// 			setFormDataConfig((prevConfig) => ({
	// 				...prevConfig,
	// 				fields: prevConfig.fields.map((field) =>
	// 					field.name === "privileges" ? { ...field, options: privilege_names } : field
	// 				),
	// 			}));
	// 		}

	// 	} catch (error) {
	// 		console.error("Error:", error);
	// 	}
	// }, []);

	const handleSave = () => {
		onSave(formDataConfig.initialState);
		console.log('save');
		
	};

	const handleCancel = () => {
		console.log("handleCancel");
		onCancel();
	};

	const setRole = (e) => {
		setFormDataConfig({
			...formDataConfig,
			initialState: {
				...formDataConfig.initialState,
				role: e.target.value
			}
		})
	}



	const setRorder = (e) => {
		setFormDataConfig({
			...formDataConfig,
			initialState: {
				...formDataConfig.initialState,
				order_no: e.target.value
			}
		})
	}


	const setRImage = (e) => {
		const file = e.target.files[0]; // Get the first selected file
		setFormDataConfig({
			...formDataConfig,
			initialState: {
				...formDataConfig.initialState,
				image: file // Store the file object
			}
		});
	}
	


	
	// const setPrivileges = (e) => {
	// 	setFormDataConfig({
	// 		...formDataConfig,
	// 		initialState: {
	// 			...formDataConfig.initialState,
	// 			privileges: e
	// 		}
	// 	})
	// }

	return (
		<EditCommonModal
			isOpen={isOpen}
			toggle={toggle}
			title={`${isEditing ? 'Edit' : 'Add'} ${title}`}
			onSave={handleSave}
			onCancel={handleCancel}
			formDataConfig={formDataConfig}
		>
			<div className='mb-4'>
				<FormGroup>
					<Label className="col-form-label pt-0" >Role</Label>
					<Input value={formDataConfig.initialState.role} onChange={setRole} className="form-control" type="text" placeholder="Enter User Role" />
					
					<Label className="col-form-label pt-0" >Order Number</Label>

					<Input value={formDataConfig.initialState.order_no} onChange={setRorder} className="form-control" type="number" placeholder="Enter Order Number" />

					<Label className="col-form-label pt-0" >Image</Label>
                    <Input type="file" onChange={setRImage} className="form-control" />

				</FormGroup>
				{/* <Label className="col-form-label">Set Privileges to users</Label>
				<Select
					value={formDataConfig?.initialState?.privileges}
					onChange={setPrivileges}
					options={formDataConfig?.fields?.find(i => i.name === "privileges")?.options}
					className="js-example-basic-single col-sm-12"
					isMulti /> */}
			</div>
		</EditCommonModal>

	)
}

export default AddUserRole