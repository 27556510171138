import { Card, CardActionArea, CardMedia } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Col, Row } from "reactstrap";
import { get } from "../config";
import { API_URL } from "../config/api_urls";
import ImodaIcon from '../../../../assets/images/Imoda-logo.svg'
import { Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

function UserGallery() {

    const {user_uuid} = useParams()

    const [gallery,setGallery] = useState([])

    console.log(user_uuid);
    

    const fetchGallery = useCallback(() => { 
        get(API_URL.USERS.USER_GALLERY)
          .then((response) => {
            console.log(response.data);
            
            if (response?.data.length > 0) {
              setGallery(response.data);
              // setOriginalData(response.data);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }, []);
  
      console.log(gallery);
  
      const filteredImage = gallery.filter(images => images.user_uuid === user_uuid)

      console.log(filteredImage);

      useEffect(()=>{
        fetchGallery();
      },[])
       

  return (
    <div>
        <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">
            <Link to={`${process.env.PUBLIC_URL}/home`}>
                <img
                  src={ImodaIcon}
                  width="150"
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
            </Link>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Row className="m-3 shadow rounded mx-5 ">
        <h4 className="m-3">GALLERY IMAGES</h4>
        <Col className="d-flex m-3">
          {filteredImage?.length>=0 && filteredImage.map((img,index)=>(
            <Card className="m-2" sx={{ maxWidth: 345 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="140"
                image={img.image}
                alt="green iguana"
              />
            </CardActionArea>
          </Card>))}
        </Col>
      </Row>
    </div>
  );
}

export default UserGallery;
