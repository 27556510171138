import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { reviewtableColumn, smsReviewtableColumn } from '../common/mockData'
import { API_URL } from '../config/api_urls';
import { get } from '../config';
import { Btn, H4 } from '../../../../AbstractElements';

function MessageReview() {
    const [data,setData] = useState([]);
    const [smsData,setSmsData] = useState([])

    const fetchSmsReview = useCallback(() => {
      get(API_URL.SMS_MESSAGE.REVIEW_SMS)
        .then((response) => {
          console.log(response.data);
  
          if (response?.data.length > 0) 
            setSmsData(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, []);

    const fetchMessageReview = useCallback(() => {
      get(API_URL.WHATSAPP_MESSAGE.MESSAGE_REVIEW)
        .then((response) => {
          console.log(response.data);
  
          if (response?.data.length > 0) 
            setData(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, []);

    console.log(data);

    const splitBody = data.map((data)=>{
      const [text, link] = data.message_body.split("\nCheck this out:");
    return { id: data.sid, text, link };
    })
    
    console.log(splitBody);
    

    const handleRefresh = () => {
      fetchMessageReview();
      fetchSmsReview();
    };

    useEffect(()=>{
      fetchMessageReview();
      fetchSmsReview();
    },[])

    return (
      <div>
        <div className='d-flex align-items-center justify-content-between  p-4'>
        <H4 attrH4={{ className: "text-muted m-0" }}>MESSAGE REVIEW</H4>
          <div>
            <button
              type="button"
              className="btn mx-2"
              style={{
                width: "30px",
                height: "38px",
                padding: "0",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleRefresh}
            >
              <i className="fa fa-refresh " style={{ fontSize: "18px" }}></i>
            </button>
          </div>
        </div>
        <div className='d-flex flex-column'>
          <DataTable
          data={data}
          columns={reviewtableColumn(splitBody)}
          striped={true}
          center={true}
          pagination
          selectableRows={false}
          fixedHeader
          fixedHeaderScrollHeight="80vh"
          // onSelectedRowsChange={handleRowSelected}
          // clearSelectedRows={toggleDelet}
          />
          <H4 attrH4={{ className: "text-muted m-4" }}>SMS REVIEW</H4>
          <DataTable
          data={smsData}
          columns={smsReviewtableColumn()}
          striped={true}
          center={true}
          pagination
          selectableRows={false}
          fixedHeader
          fixedHeaderScrollHeight="80vh"
          // onSelectedRowsChange={handleRowSelected}
          // clearSelectedRows={toggleDelet}
          />
        </div>
      </div>
    )
  }

  export default MessageReview