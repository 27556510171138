
import React, { useEffect, useState } from 'react' 
import FormCommon from '../common/FormCommon';
import EditCommonModal from '../common/EditCommonModal';
import { FormGroup, Input, Label } from 'reactstrap';

function NewHearAboutUs({hearAboutUsData,isOpen,toggle,title,onSave,onCancel}) {

    const [isEditing,setIsEditing] = useState(false);
    const [formDataConfig, setFormDataConfig] = useState({
      initialState: {
        id: null,
        aboutus: ""
      },
      fields: [
        {
          name: "aboutus",
          label: "Channels",
          type: "text",
          placeholder:"Enter hear About Us",
          required: true,
        }
      ],
    });

    useEffect(() => {
      if (hearAboutUsData) {
        setIsEditing(true);
        setFormDataConfig(value => ({
          ...value,
          initialState: hearAboutUsData,
        }));
      } else {
        setIsEditing(false);
        setFormDataConfig(value => ({
          ...value,
          initialState: {
            id: null,
            aboutus: ''
          },
        }));
      }
    }, [hearAboutUsData,isOpen]);
  

    const handleSave = () => {
      onSave(formDataConfig.initialState);
    };
          
    const handleCancel = () => {
      onCancel();   
    };      
  
    const setHearaboutus =(e)=>{
      setFormDataConfig({
        ...formDataConfig,
        initialState: {
          ...formDataConfig.initialState,
          aboutus: e.target.value
        }
      })
    }

  return (
    <EditCommonModal
    isOpen={isOpen}
    toggle={toggle}
    title={`${isEditing ? 'Edit' : 'Add'} ${title}`}
    onSave={handleSave}
    onCancel={handleCancel}
    formDataConfig={formDataConfig}
    > 
       <div>
       <FormGroup>
					<Label className="col-form-label pt-0" >Hear About us</Label>
					<Input value={formDataConfig.initialState.aboutus} onChange={setHearaboutus} className="form-control" type="text" placeholder="Enter where from you hear about us" />

				</FormGroup>
    </div>

    </EditCommonModal>
  )
}

export default NewHearAboutUs