import uuid from "react-uuid"

export const API_URL = {
    PRIVILEGES:{
        PRIVILEGES_GET :"/api/privileges/",
        PRIVILEGES_POST : "/api/privileges/"
    },
    USER_ROLE:{
        USER_ROLE_GET :"/api/user-roles/",
        USER_ROLE_POST :"/api/user-roles/",
        USER_ROLE_PATCH:id=>`/api/user-roles/${id}/`,
        USER_ROLE_DELETE:id=>`/api/user-roles/${id}/`
    },
    USER_CATEGORY:{
        USER_CATEGORY_GET:"/api/user/category/",
        USER_CATEGORY_POST:"/api/user/category/",
        USER_CATEGORY_PATCH:id=>`/api/user/category/${id}/`,
        USER_CATEGORY_DELETE:id=>`/api/user/category/${id}/`
    },
    COUNTRIES:{
        COUNTRIES_GET:"/api/countries/",
        COUNTRIES_POST:"/api/countries/"
    },
    STATES:{
        STATES_GET:"/api/states/",
        STATES_POST:"/api/states/"
    },
    CITIES:{
        CITIES_GET :"/api/cities/",

        CITIES_POST:"/api/cities/",
        CITIES_GET_BY_ID:id=>`/api/cities/by-state/${id}/`

    },
    HEAR_ABOUT_US:{
        HEAR_ABOUT_US_GET:"/api/user/hearaboutus/",
        HEAR_ABOUT_US_POST:"/api/user/hearaboutus/",
        HEAR_ABOUT_US_PATCH:id=>`/api/user/hearaboutus/${id}/`,
        HEAR_ABOUT_US_DELETE:id=>`/api/user/hearaboutus/${id}/`
    },
    USERS: {
        GET_USERS: "/api/users/",
        USERS_PUT: "/api/users/",
        USERS_PATCH:uuid=>`/api/users/${uuid}/`,
        USERS_POST:"/api/users/",

        USER_FIREBASE:"/api/phone-number/",
        DELETE_USER_FIREBASE:uid=>`/api/delete-number/${uid}/`,

        USER_GALLERY:"/api/gallery/",
        GALLERY_DELETE:(uuid,id)=> `/api/gallery/${uuid}/${id}`,

        USER_VIDEO:"/api/videolinks/",
        GET_USER_VIDEO:"/api/videolinks/",
        PATCH_USERVIDEO:(uuid,id)=>`/api/videolinks/${uuid}/${id}/`,

        DELETE_USERVIDEO:id=>`/api/videolinks/${id}`,

        USERS_DELETE:uuid=>`/api/users/${uuid}/`,
    },
    USER_REGIONAL_PARTNERS:{
        GET_REGIONALS :"/api/regional-partners/",
        POST_REGIONALS :"/api/regional-partners/"
    },
    
    REGIONAL_PARTNERS:{
        GET_REGIONALS :"/api/regionals/",
        POST_REGIONALS :"/api/regionals/",
        PATCH_REGIONALS :id=>`/api/regional/${id}/`,
        DELETE_REGIONALS :id=>`/api/regional/${id}/`
    },

    //ACHIEVEMENTS
    ACHIEVEMENTS:{
        GET_ACHIEVEMENTS:uuid=> `/api/achievements/${uuid}/`,
        POST_ACHIEVEMENTS:"/api/achievements/",
        PATCH_ACHIEVEMENTS:(uuid,id)=>`/api/achievements/${uuid}/${id}/`,
        PUT_ACHIEVEMENTS:(uuid,id)=>`/api/achievements/${uuid}/${id}/`,
        DELETE_ACHIVEMENTS:(uuid,id)=>`/api/achievements/${uuid}/${id}/`
    },



    //message
    WHATSAPP_MESSAGE:{
        POST_MESSAGE:"/api/send-whatsapp-message/",
        MESSAGE_REVIEW:"/api/fetch-messages-records/",
        DELETE_MESSAGE:id=>`/api/delete/message/${id}/`
    },

    SMS_MESSAGE:{
        POST_SMS:"/api/send-sms/",
        REVIEW_SMS:"/api/message-view/",
        DELETE_SMS:id=>`/api/delete-message/${id}/`
    },
 //notification
    NOTIFICATION:{
        POST_NOTIFICATION:"/api/send-push-notification/",
        POST_NOTIFICATION_TO_ALLUSERS:"/api/send-notification-to-all/",


    },
    //EVENTS------------------------------
    EVENTS:{
        GET_EVENTS:"/api/events/",
        POST_EVENT:"/api/events/",
        PATCH_EVENT:id=>`/api/events/${id}/`,
        DELETE_EVENT:id=>`/api/events/${id}/`,
        GET_COUNT_DETAILS:uuid=>`/api/applications/event/${uuid}/`,
        VIDEO_LINKS:"/api/event/video-links/",
        DELETE_VIDEOLINK:id=>`/api/event/video-links/${id}`
    },
    EVENT_CATEGORY:{
        GET_EVENT_CATEGORY:"/api/category/",
        POST_EVENT_CATEGORY:"/api/category/",
        PATCH_EVENT_CATEGORY:id=>`/api/category/${id}/`,
        DELETE_EVENT_CATEGORY:id=>`/api/category/${id}/`
    },
    EVENT_TYPES:{
        GET_EVENT_TYPES : "/api/types/",
        POST_EVENT_TYPES:"/api/types/",
        PATCH_EVENT_TYPES:id=>`/api/types/${id}/`,
        DELETE_EVENT_TYPES:id=>`/api/types/${id}/`,
    },
    EVENT_APPLICATION :{
        GET_EVENT_APPLICATION : "/api/job/applications/",
        POST_EVENT_APPLICATION: "/api/job/applications/",
        PATCH_EVENT_APPLICATION :id=>`/api/job/applications/${id}/`,
        DELETE_EVENT_APPLICATION:id=>`/api/job/applications/${id}/`
    },


//  MARKET/PRODUCT ------------------------------------

    MARKET_CATEGORY:{
        GET_MARKET_CATEGORY:"/api/product_categories/",
        POST_MARKET_CATEGORY:"/api/product_categories/",
        PATCH_MARKET_CATEGORY:id=>`/api/product_categories/${id}/`,
        DELETE_MARKET_CATEGORY:id=>`/api/product_categories/${id}/`,
    },
    MARKET_SIZES:{
        GET_MARKET_SIZES:"/api/product_types/",
        POST_MARKET_SIZE:"/api/product_types/",
        PATCH_MARKET_SIZE:id=>`/api/product_types/${id}/`,
        DELETE_MARKET_SIZE:id=>`/api/product_types/${id}/`,
    },
    MARKET_PRPDUCTS:{
        GET_MARKET_PRPDUCTS :"/api/productform/",
        POST_MARKET_PRPDUCTS :"/api/productform/create/",
        PATCH_MARKET_PRPDUCTS : uuid=>`/api/productform/${uuid}/`,
        DELETE_MARKET_PRPDUCTS: id=>`/api/productform/${id}/`,
    },
   
    // BUSINESS SECTION/ ORDERS---------------------------
    ORDERS:{
        GET_ORDERS :"/api/orders/",
        POST_ORDER : "/api/create-order/",
        PATCH_ORDER :id=> `/api/orders/${id}/`,
        DELETE_ORDER : id=> `/api/orders/${id}/`
    },




    // ID CARDS--------------------------------
    IDCARDS:{
        GET_IDCARDS :"/api/idcards/",
        POST_IDCARD :"/api/membership/",
        PATCH_IDCARD :id=>`/api/idcards/${id}/`,
        DELETE_IDCARD :id=>`/api/idcards/${id}/`
    },

    MEMBERSHIP:{
        GET_MEMBERSHIP :"/api/membership",
       
    },
   
    POPUPIMAGE:{
        GET_IMAGE :"/api/image/",
        POST_IMAGE :"/api/image/",
        DELETE_IMAGE :id=>`/api/image/${id}/`,
        PATCH_IMAGE :id=>`/api/image/${id}/`

        
    },


    // ATTRIBUTES --------------------------
    ATTRIBUTES:{
        GET_ATTRIBUTES : "/api/attributes/get/",
        POST_ATTRIBUTES : "/api/attributes/",
        PATCH_ATTRIBUTES :id=>`/api/attributes/${id}/`,
        DELETE_ATTRIBUTES :id=>`/api/attributes/${id}/`
    },
    USER_ATTRIBUTES: {
        GET_USER_ATTRIBUTES : "/api/profile-attributes/",
        POST_USER_ATTRIBUTES :"/api/profile-attributes/",
        PATCH_USER_ATTRIBUTES :id=>`/api/profile-attributes/${id}/`,
        DELETE_USER_ATTRIBUTES :id=>`/api/profile-attributes/${id}/`,
    },


    // PREFERENCES---------------------------------
    PREFERENCES:{
        GET_PREFERENCES:"/api/preferences/",
        POST_PREFERENCES:"/api/preferences/",
        PATCH_PREFERENCES:id=>`/api/preferences/${id}/`,

        GET_USER_PREFERENCES_BY_USER: userId => `/api/profile-preferences/${userId}/`, 


        DELETE_PREFERENCES:id=>`/api/preferences/${id}/`
    },
    USER_PREFERENCES:{
        GET_USER_PREFERENCES: "/api/profile-preferences/",
        POST_USER_PREFERENCES: "/api/profile-preferences/",

        GET_USER_PREFERENCES_BY_USER: userId => `/api/profile-preferences/${userId}/`, 

        PATCH_USER_PREFERENCES:id=>`/api/profile-preferences/${id}/`,
        DELETE_USER_PREFERENCES:id=>`/api/profile-preferences/${id}/`
    },

    ADDRESS :{
        GET_ADDRESS :"/api/edit/address/",
        POST_ADDRESS :"/api/edit/address/",
        PATCH_ADDRESS:id=>`/api/edit/address/${id}/`,
        DELETE_ADDRESS:id=>`/api/edit/address/${id}/`
    },

    PROFILE: {
        GET_PROFILE: "/api/profiles/", 
        POST_PROFILE: "/api/profiles/", 
        GET_PROFILE_BY_USER: uuid => `/api/profiles/${uuid}/`, 
        PATCH_PROFILE: uuid => `/api/profiles/${uuid}/`, 

    },
    PROFILE_PREFERENCES: {
        GET_PREFERENCES: "/api/profile-preferences/", 
        GET_PREFERENCE_BY_ID: (id) => `/api/profile-preferences/${id}/`, 
        CREATE_PREFERENCE: "/api/profile-preferences/", 
        UPDATE_PREFERENCE: (id) => `/api/profile-preferences/${id}/`, 
        DELETE_PREFERENCE: (id) => `/api/profile-preferences/${id}/`,
    },


    
    SOCIAL_MEDIA : {
        GET_SOCIAL_MEDIA :"/api/profile-social-media/" ,
        POST_SOCIAL_MEDIA :"/api/profile-social-media/" ,
        PATCH_SOCIAL_MEDIA :id=>`/api/profile-social-media/${id}/`,
        DELETE_SOCIAL_MEDIA :id=>`/api/profile-social-media/${id}/`
    },

    SOCIAL_MEDIA_NAME :{
        GET_SOCIALMEDIA_NAME :"/api/social-media/",
        POST_SOCIAL_MEDIA_NAME:"/api/social-media/",
        PATCH_SOCIAL_MEDIA_NAME : id=>`/api/social-media/${id}/`,
        DELETE_SOCIAL_MEDIA_NAME : id=>`/api/social-media/${id}/`
    },





    //SETTINGS--------------------------------
    APP_VERSION :{
        GET_APP_VERSION : "/api/appversion/",
        POST_APP_VERSION : "/api/appversion/",
        PATCH_APP_VERSION : id=>`/api/appversion/${id}/`,
        DELETE_APP_VERSION : id=>`/api/appversion/${id}/`
    },

    PAYMENT_TERMS:{
        GET_PAYMENT_TERMS : "/api/payment-terms/",
        POST_PAYMENT_TERMS : "/api/payment-terms/",
        PATCH_PAYMENT_TERMS :id=>`/api/payment-terms/${id}/`,
        DELETE_PAYMENT_TERMS:id=>`/api/payment-terms/${id}/`
    },

    PAYMENT_SETTINGS:{
        GET_PAYMENT_SETTINGS : "/api/payment-settings/",
        POST_PAYMENT_SETTINGS : "/api/payment-settings/",
        PATCH_PAYMENT_SETTINGS :id=>`/api/payment-settings/${id}/`,
        DELETE_PAYMENT_SETTINGS :id=>`/api/payment-settings/${id}/`
    },

    TERMS_CONDITIONS:{
        GET_TERMS_CONDITIONS : "/api/terms-and-conditions/",
        POST_TERMS_CONDITIONS : "/api/terms-and-conditions/",
        PATCH_TERMS_CONDITIONS :id=>`/api/terms-and-conditions/${id}/`,
        DELETE_TERMS_CONDITIONS:id=>`/api/terms-and-conditions/${id}/`
    },


    IDCARD_SETTINGS:{
        GET_IDCARD_SETTINGS : "/api/idcard-settings/",
        POST_IDCARD_SETTINGS : "/api/idcard-settings/",
        PATCH_IDCARD_SETTINGS :id=>`/api/idcard-settings/${id}/`,
        DELETE_IDCARD_SETTINGS :id=>`/api/idcard-settings/${id}/`
    },

    DASHBOARD_SETTINGS:{
        GET_DASHBOARD_SETTINGS : "/api/dashboard-settings/",
        POST_DASHBOARD_SETTINGS : "/api/dashboard-settings/",
        PATCH_DASHBOARD_SETTINGS :id=>`/api/dashboard-settings/${id}/`,
        DELETE_DASHBOARD_SETTINGS :id=>`/api/dashboard-settings/${id}/`
    },

    CONTACT_US :{
        GET_CONTACT_US :"/api/contact-us/",
        POST_CONTACT_US : "/api/contact-us/",
        PATCH_CONTACT_US : id=>`/api/contact-us/${id}/`,
        DELETE_CONTACT_US : id=>`/api/contact-us/${id}/`
    },

    ABOUT_US :{
        GET_ABOUT_US :"/api/about-us/",
        POST_ABOUT_US : "/api/about-us/",
        PATCH_ABOUT_US : id=>`/api/about-us/${id}/`,
        DELETE_ABOUT_US : id=>`/api/about-us/${id}/`
    },

    USERS_SETTINGS :{
        GET_USERS_SETTINGS:"/api/intro-sliders/",
        POST_USERS_SETTINGS:"/api/intro-sliders/",
        PATCH_USERS_SETTINGS:id=>`/api/intro-sliders/${id}/`,
        DELETE_USERS_SETTINGS:id=>`/api/intro-sliders/${id}/`
    },

   WHATSAPP_SETTINGS :{
    GET_WHATSAPP_SETTINGS:"/api/whatsapp-texts/",
    POST_WHATSAPP_SETTINGS:"/api/whatsapp-texts/",
    PATCH_WHATSAPP_SETTINGS:id=>`/api/whatsapp-texts/${id}/`,
    DELETE_WHATSAPP_SETTINGS:id=>`/api/whatsapp-texts/${id}/`
   }
}