import { useLocation, useNavigate, useParams } from "react-router";
import { Btn } from "../../../../AbstractElements";
import { CSVLink } from "react-csv";
import { useCallback, useEffect, useRef, useState } from "react";
import { DELETE, get, post } from "../config";
import { API_URL } from "../config/api_urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import './userdetailscopy.css'

function UserDetailsPage({ isOpen, onClose }) {
  const location = useLocation();

  const navigate = useNavigate();

  const { uuid } = useParams();
  console.log(uuid);

  const videoRef = useRef(null);

  // const { userData: user } = location.state || {};
  const [data, setData] = useState([]);
  const [user, setuser] = useState([]);

  const [csvData, setCsvData] = useState(data);
  const [gallery, setgallery] = useState(data);
  const [social, setsocial] = useState(data);
  const [profiles, setprofiles] = useState(data);
  const [attributes, setattributess] = useState([]);
  const [Userattributes, setUserattributess] = useState([]);
  const [userCategory, setuserCategory] = useState([]);
  const [videos, setvideos] = useState(data);
  const [userVideo, setUserVideo] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [profilePreferences, setProfilePreferences] = useState([]);
  const [ProfileAttributes, setProfileAttributes] = useState([]);
  const [ProfileSocialmedia, setProfileSocialmedia] = useState([]);
  const [matchedAttributes, setMatchedAttributes] = useState([]);
  const [achievements,setAchevements] = useState([])

  const [userPreferences, setUserPreferences] = useState([]);
  const [userAttributes, setUserAttributes] = useState([]);
  const [userSocial, setUserSocial] = useState([]);
  const [uservideo, setUservideo] = useState([]);
  const [userabout, setUserabout] = useState([]);

  // setData(user)
  const userArray = Object.entries(user);

  console.log("user", user);
  console.log("profile", profiles);

  useEffect(() => {
    setCsvData(userArray);
  }, []);

  const handleExport = () => {
    setCsvData(userArray);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleProfileResponse = (profilesResponse) => {
    const profiles = profilesResponse?.data || [];
    console.log("Fetched User seprate Preferences:", profiles);
    setUserPreferences(profiles);
  };

  const handleAttributesResponse = (attributesResponse) => {
    const attributes = attributesResponse?.data || [];
    console.log("Fetched User Attributes:", attributes);
    setUserAttributes(attributes);
  };

  const handleSocialmediaResponse = (SocialResponse) => {
    const socialmedia = SocialResponse?.data || [];
    console.log("Fetched User Social Media:", socialmedia);
    setUserSocial(socialmedia);
  };

  const handleUservideoResponse = (videoResponse) => {
    const Uservideo = videoResponse?.data || [];
    console.log("Fetched User Video Media:", Uservideo);
    setUservideo(Uservideo);
  };

  console.log(user);
  
  const handleUseraboutResponse = (aboutResponse,users) => {
    const Userabout = aboutResponse?.data || [];
    console.log("Fetched User Video Media:", Userabout);
    console.log(users);
    console.log(Userabout);
    
    const matchedAbout = Userabout.filter((about)=>{
      return about.user_uuid === users.uuid
    })
    console.log(matchedAbout);
    
    setUserabout(matchedAbout);
  };

  console.log(userabout);
  

  const fetchUsers = useCallback(async () => {
    try {
      const usersResponse = await get(API_URL.USERS.GET_USERS);
      console.log(usersResponse);

      const users = usersResponse?.data || [];
      const matchedUser = users.find((user) => user.uuid === uuid);
      setuser(matchedUser);

      //   setOriginalData(users);
      setData(users);

      const profilesResponse = await get(API_URL.PROFILE.GET_PROFILE);
      const profiles = profilesResponse?.data || [];
      handleProfileResponse(profilesResponse);

      const attributesResponse = await get(
        API_URL.USER_ATTRIBUTES.GET_USER_ATTRIBUTES
      );
      const attributes = attributesResponse?.data || [];
      handleAttributesResponse(attributesResponse);

      const socialmediaResponse = await get(
        API_URL.SOCIAL_MEDIA.GET_SOCIAL_MEDIA
      );
      const socialmedia = socialmediaResponse?.data || [];
      handleSocialmediaResponse(socialmediaResponse);

      const videoResponse = await get(API_URL.PROFILE.GET_PROFILE);
      const Uservideo = videoResponse?.data || [];
      handleUservideoResponse(videoResponse);

      const useraboutResponse = await get(API_URL.PROFILE.GET_PROFILE);
      const about = useraboutResponse?.data || [];
      console.log(about);
      
      handleUseraboutResponse(useraboutResponse,matchedUser);

      console.log("Fetched User Preferences:", profiles);
      setData(users);
      setUserPreferences(profiles);
    } catch (error) {
      console.error("Error fetching users or preferences:", error);
    }
  }, []);

  const compareAttributes = useCallback(() => {
    if (Userattributes.length > 0 && attributes.length > 0) {
      const matched = attributes
        .filter((attr) =>
          Userattributes.some(
            (userAttr) =>
              attr.attribute_name === userAttr.attribute_name &&
              userAttr.user_name === user.name
          )
        )
        .map((attr) => {
          const matchedUserAttr = Userattributes.find(
            (userAttr) =>
              userAttr.attribute_name === attr.attribute_name &&
              userAttr.user_name === user.name
          );

          return {
            ...attr,
            ...matchedUserAttr,
          };
        });

      setMatchedAttributes(matched);
    }
  }, [Userattributes, attributes, user.name]);

  console.log("user", user);

  const fetchCategory = useCallback(() => {
    get(API_URL.USER_CATEGORY.USER_CATEGORY_GET)
      .then((response) => {
        if (response?.data.length > 0) setuserCategory(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log("usercategory", userCategory);
  const Category = userCategory?.find((i) => i.id === user?.category_id)?.name;

  console.log("category", Category);

  const fetchGallery = useCallback(() => {
    get(`${API_URL.USERS.USER_GALLERY}`)
      .then((response) => {
        if (response?.data.length > 0) {
          setgallery(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gallery:", error);
      });
  }, [user.uuid]);

  const fetchsocial = useCallback(() => {
    get(`${API_URL.SOCIAL_MEDIA.GET_SOCIAL_MEDIA}`)
      .then((response) => {
        if (response?.data.length > 0) {
          setsocial(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching social media:", error);
      });
  }, [user.uuid]);

  const fetchvideos = useCallback(() => {
    get(`${API_URL.PROFILE.GET_PROFILE}`)
      .then((response) => {
        if (response?.data.length > 0) {
          setvideos(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching social media:", error);
      });
  }, [user.uuid]);

  //achievements
  const fetchAchievements = async()=>{
    console.log(user);    
    await get(API_URL.ACHIEVEMENTS.GET_ACHIEVEMENTS(user.uuid))
    .then((response)=>{
      console.log(response);
      setAchevements(response.data)
    }).catch((err)=>{
      console.log(err);
    })
  }

  // const allpreference = profilePreferences[0]
  // console.log('outside',select);
  const allpreference =
    Array.isArray(profilePreferences) && profilePreferences.length > 0
      ? profilePreferences[0]
      : null;

      console.log(allpreference);
      

  const allsocialmedia =
    Array.isArray(ProfileSocialmedia) && ProfileSocialmedia.length > 0
      ? ProfileSocialmedia[0]
      : null;

  const uservideomatches = userVideo.filter(
    (video) => video.user_uuid === user.uuid
  );

  const fetchUserattributes = useCallback(() => {
    get(API_URL.USER_ATTRIBUTES.GET_USER_ATTRIBUTES)
      .then((response) => {
        console.log(response.data);

        if (response?.data.length > 0) {
          setUserattributess(response.data);
          // setOriginalData(response.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchattributes = useCallback(() => {
    get(API_URL.ATTRIBUTES.GET_ATTRIBUTES)
      .then((response) => {
        console.log(response.data);

        if (response?.data.length > 0) {
          setattributess(response.data);
          // setOriginalData(response.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchProfile = useCallback(() => {
    get(API_URL.PROFILE.GET_PROFILE)
      .then((response) => {
        if (response?.data.length > 0) {
          setprofiles(response.data);
          const matchedProfiles = response.data.filter((profile) => {
            // Log each profile during the filter process
            console.log("Checking profile:", profile);

            return profile.user_uuid === user.uuid;
          });

          console.log(matchedProfiles);
          

          if (matchedProfiles) {
            setProfilePreferences(
              matchedProfiles.map((profile) => {
                return profile.preferences;
              })
            );
            setProfileAttributes(
              matchedProfiles.map((profile) => {
                return profile.attributes;
              })
            );

            setProfileSocialmedia(
              matchedProfiles.map((profile) => {
                return profile.social_media;
              })
            );
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching profile:", error);
      });
  }, [user.uuid]);

  if (ProfileAttributes && Array.isArray(ProfileAttributes)) {
    const profileMap = ProfileAttributes.map(({ Height, Weight }) => {
      return { Height, Weight }; // Create a new object with Height and Weight
    });
  } else {
    console.error("ProfileAttributes is null or not an array");
  }

  const fetchEventVideo = useCallback(() => {
    get(API_URL.USERS.USER_VIDEO)
      .then((response) => {
        console.log(response.data);

        if (response?.data.length > 0) {
          setUserVideo(response.data);
          // setOriginalData(response.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log("uservideo", userVideo);

  const filteredImage = userVideo.filter(
    (videos) => videos.user_uuid === user.uuid
  );

  console.log(filteredImage);

  const handleAddVideo = (event) => {
    const files = Array.from(event.target.files);
    const names = files.map((file) => file.name);

    // setVideo(files);

    if (user.uuid) {
      for (let i = 0; i < files.length; i++) {
        const formDataa = new FormData();
        formDataa.append("user_uuid", user.uuid);
        formDataa.append("video_file", files[i]);

        post(API_URL.USERS.USER_VIDEO, formDataa)
          .then((response) => {
            console.log(response);
            fetchEventVideo();
            toast.success("Image added succesfully");
          })
          .catch((error) => {
            console.error(error);
            toast.error("Error adding image");
          });
      }
    }
  };

  const handleMouseEnter = () => {
    videoRef.current.play();
  };

  const handleMouseLeave = () => {
    videoRef.current.pause();
  };

  const handleImageClick = () => {
    document.getElementById("file-input").click();
  };

  const handleDeleteGallary = (id) => {
    console.log(id);

    if (id) {
      DELETE(API_URL.USERS.DELETE_USERVIDEO(id))
        .then((response) => {
          console.log(response);
          fetchEventVideo();
          toast.success("Deleted successfully");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  console.log("userattributes", Userattributes);

  const navigateGallery = (uuid) => {
    console.log(uuid);

    navigate(`${process.env.PUBLIC_URL}/user/gallery/${uuid}`);
  };

  useEffect(() => {
    // if (user?.uuid) {
    fetchUsers();
    fetchGallery();
    fetchsocial();
    fetchvideos();
    fetchEventVideo();
    fetchProfile();
    fetchattributes();
    fetchUserattributes();
    fetchCategory();
    fetchAchievements();
    // }
  }, [fetchGallery]);

  useEffect(() => {
    compareAttributes(); // Compare attributes when profiles and attributes are fetched
  }, [attributes, ProfileAttributes, compareAttributes]);

  console.log("Matched Attributes:", matchedAttributes);

  const attributesvalues = matchedAttributes.map((i) => {
    return i.enter_unit;
  });

  console.log("log", matchedAttributes);

  if (!user) {
    return <p>No user data available</p>;
  }

  if (!user) {
    return <p>No user data available</p>;
  }

  const style = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)",
  };

  const Pdfstyle = {
    styles: `
      @media print {
        .hide-when-printing {
          display: none;
        }
        .social {
        background-color: #bc4749 !important; /* Ensure visibility in print */
        -webkit-print-color-adjust: exact; /* For Webkit browsers */
        color-adjust: exact;
      }
        .allsocialmedia{
        position: relative; /* Enables positioning properties like 'left' */
        left: -10px; 
        }

        .cover-image {
          width: 650px !important; /* Increase width for printing */
          height: auto; /* Maintain aspect ratio */
        }
       .contact-me{
position:relative;
top:-72px;
left:130px;
       }
 .contact-me1{
position:relative;
top:-142px;
left:360px;
       }

       .aboutme{
       position:relative;
       top:-142px;
       }


         @page {
    margin: 0; /* Removes default margin, which often causes headers/footers */
  }

  body {
    margin: 0; /* Ensures the content fills the page */
  }
      }
    `,
  };

 
  return (
    <div className='container' style={{ padding: '20px',width:"100%",height:"100vh" }}>
      <div className="w-100">
       <div className='row'>
    <img className="cover-image"
      src={user.cover_image} 
      alt="Cover Image" 
      style={{ 
        width: '100%', 
        height: '400px', 
        objectFit: 'cover', 
    

      }} 
    />
  </div>

        <div className="row justify-content-center mt-1 bg-success" style={{height:"0px"}}>
          <div className="col-md-6 col-lg-4 text-center">
            <div
              style={{
                width: "150px",
                height: "150px",
                overflow: "hidden",
                display: "inline-block",
                border: "1px solid #ddd",
                borderRadius: "50%",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                padding: "5px",
                transition: "transform 0.2s",
                position:"relative",
                top:"-350px"
              }}
            >
              <img
                src={user.profile_image}
                alt="User Profile"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "scale(1.2)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "scale(1.0)")
                }
              />
            </div>

            <div className="mt-3 d-flex flex-column justify-content-center align-items-center " 
            style={{position:"relative",top:"-370px"}}
            >
              <h3 className="text-start" style={{position:"relative",left:"0px"}}>{user.name}</h3>
              <h6>{user.userRole}</h6>
              <h6>{user.state_name}</h6>
            </div>
            <div className="m-3" style={{ position: "relative", left: "0%", top: "-600px" }}>
      <style dangerouslySetInnerHTML={{ __html: Pdfstyle.styles }} />
     <button className="btn  hide-when-printing" onClick={handlePrint} style={{background:"white",color:"#bc4749",border:"1px solid pink",position: "relative", left: "90%"}}>
      <i className="fa-solid fa-file-pdf"></i> Download PDF
    </button>
      </div>
          </div>
        </div>

        <div className="row justify-content-center" >
          <div className="social d-flex justify-content-center align-items-center" style={{width:"98.5%",height:"40px",background:"#bc4749",position:"relative",top:"-20px"}}>
          <div className="col-md-6 col-lg-4 text-center allsocialmedia ">
            {ProfileSocialmedia.length > 0 ? (
              <div className="d-flex justify-content-center gap-2">
                {ProfileSocialmedia.map((item, index) =>
                  Object.entries(item).map(([key, value]) => {
                    return (
                      <a
                        key={`${index}-${key}`}
                        href={value}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center border rounded p-2"
                        style={{ borderColor: "pink" }}
                      >
                        {key.toLowerCase() === "instagram" && (
                          <i
                            className="fa-brands fa-instagram"
                            style={{ fontSize: "18px" }}
                          ></i>
                        )}
                        {key.toLowerCase() === "linkedin" && (
                          <i
                            className="fa-brands fa-linkedin"
                            style={{ fontSize: "18px" }}
                          ></i>
                        )}
                        {key.toLowerCase() === "facebook" && (
                          <i
                            className="fa-brands fa-facebook"
                            style={{ fontSize: "18px" }}
                          ></i>
                        )}
                        {key.toLowerCase() === "twitter" && (
                          <i
                            className="fa-brands fa-twitter"
                            style={{ fontSize: "18px" }}
                          ></i>
                        )}
                        {key.toLowerCase() === "others" && (
                          <i
                            className="fa-solid fa-hashtag"
                            style={{ fontSize: "18px" }}
                          ></i>
                        )}

                      {key.toLowerCase() === "youtube" && (
                          <i class="fa-brands fa-youtube"
                          style={{ fontSize: "18px" }}

                          ></i>
                        )}
                      </a>
                    );
                  })
                )}
              </div>
            ) : (
              <span>No Social Media</span>
            )}
          </div>
          </div>
        
        </div>

        <div className="row mt-4" >
          <div className="col-md-6">
         
      <h5 style={{color:"#bc4749"}}>
     
      <i className="fa-solid fa-id-card m-3"></i>BASIC INFO
            </h5>
            <div>
              <div className="d-flex flex-wrap gap-3 mb-3">
                <div className="w-100">
                  <p>
                    <strong>Name :</strong> {user.name}
                  </p>
                </div>
                <div className="w-100">
                  <p>
                    <strong>Gender :</strong> {user.gender}
                  </p>
                </div>
                <div className="w-100">
                  <p>
                    <strong>DOB :</strong> {user.date_of_birth}
                  </p>
                </div>
                <div className="w-100">
                  <p>
                    <strong>Age :</strong> {user.age}
                  </p>
                </div>
                <div className="w-100">
                  <p>
                    <strong>Category :</strong> {Category}
                  </p>
                </div>

               

              </div>
            </div>
          </div>

          <div className="col-md-6 ">
            <h5 style={{color:"#bc4749"}}>
              <i className="fa-solid fa-square-phone m-3"></i>CONTACT ME
            </h5>
            <div className="d-flex flex-wrap gap-3 mb-3 " >
              <div
                className="col-12 w-50 col-md-4 rounded p-2"
                // style={{ border: "1px solid #ddd" }}
              >
                <p>
                  <i className="fa-solid fa-phone"></i>{" "}
                  {user.phone_number || "N/A"}
                </p>
              </div>
              <div
                className="col-12 w-50 col-md-4 rounded p-2 contact-me"
                // style={{ border: "1px solid #ddd" }}
              >
                <p>
                  <i className="fa-solid fa-envelope "></i> {user.email || "N/A"}
                </p>
              </div>
              <div
                className="col-12 w-50 col-md-4 rounded p-2 contact-me1"
                // style={{ border: "1px solid #ddd" }}
              >
                <p>
                  <i className="fa-brands fa-whatsapp"></i>{" "}
                  {user.whatsapp_number || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4" style={{position:"relative",bottom:"40px"}}>
          <div className="col-md-6 aboutme">
          <h5 style={{color:"#bc4749"}}>
          <i className="fa-regular fa-file-lines m-3"></i>ABOUT ME
            </h5>
            {userabout.length>0?<p style={{height:"5rem"}} className="border rounded p-2 m-2">{userabout[0].about}</p>:
            <p>N/A</p>
            }
          </div>

          <div className="col-md-6">
          <h5 style={{color:"#bc4749"}}>
          <i className="fa-solid fa-trophy m-3"></i>MY ACHIEVEMENTS
            </h5>
            <div>
              {achievements && achievements.length > 0 ? (
                <div className="d-flex flex-wrap gap-2">
                  {achievements.map((achievement, index) => 
                  (
                    <button
                      key={index}
                      className="btn btn-light"
                      style={{ borderColor: "pink" }}
                      disabled
                    >
                      {achievement.name}
                    </button>
                  ))}
                </div>
              ) : (
                <p>N/A</p>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-4" style={{position:"relative",bottom:"30px"}}>
          <div className="col-md-6">
          <h5 style={{color:"#bc4749"}}>
          <i className="fa-solid fa-mug-hot m-3"></i>MY PREFERENCES
            </h5>
            <div>
              {allpreference && allpreference.length > 0 ? (
                <div className="d-flex flex-wrap gap-2">
                  {allpreference.map((pref, index) => (
                    <button
                      key={index}
                      className="btn btn-light"
                      style={{ borderColor: "pink" }}
                      disabled
                    >
                      {pref}
                    </button>
                  ))}
                </div>
              ) : (
                <p>N/A</p>
              )}
            </div>
          </div>

          <div className="col-md-6">
          <h5 style={{color:"#bc4749"}}>
          <i className="fa-brands fa-creative-commons-by m-3"></i>MY
              ATTRIBUTE
            </h5>
            <div>
              {matchedAttributes && matchedAttributes.length > 0 ? (
                <div className="d-flex flex-wrap gap-2">
                  {matchedAttributes.map((attr, index) => (
                    <button
                      key={index}
                      className="btn btn-light"
                      style={{ borderColor: "pink" }}
                      disabled
                    >
                      {attr.attribute_name}:{attr.value} {attr.enter_unit}
                    </button>
                  ))}
                </div>
              ) : (
                <p>N/A</p>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-4" style={{position:"relative",bottom:"30px"}}>
          <div className="col-md-6">
          <h5 style={{color:"#bc4749"}}>
          <i className="fa-solid fa-images m-3"></i>GALLERY
            </h5>
            <Link to={`${process.env.PUBLIC_URL}/user/gallery/${user.uuid}`}>
              {`${window.location.origin}/user/gallery/${user.uuid}`}
            </Link>
          </div>

          <div className="col-md-6">
          <h5 style={{color:"#bc4749"}}>
          <i className="fa-solid fa-film m-3"></i>VIDEO LINKS
            </h5>
            {uservideomatches.length > 0 ? (
              <div className="d-flex flex-wrap gap-2">
                {uservideomatches.map((video, index) => (
                  <a
                    key={index}
                    href={video.video_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center gap-2"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    <i
                      className="fa-solid fa-video"
                      style={{ fontSize: "18px" }}
                    ></i>
                    Watch Video {index + 1}
                  </a>
                ))}
              </div>
            ) : (
              <p>No videos available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetailsPage;