import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  applicationtabledata,
  applicationtableColumns,
} from "../common/mockData";
import { Btn, H4 } from "../../../../AbstractElements";
import EditCommonModal from "../common/EditCommonModal";
import AddApplication from "./AddApplication";
import ApplicationFilter from "./ApplicationFilter";
import { CSVLink } from "react-csv";
import { API_URL } from "../config/api_urls";
import { DELETE, get, patch, post } from "../config";
import { toast, ToastContainer } from "react-toastify";
import { constructFormData } from "../config/imageHandle";
import { useLocation, useNavigate } from "react-router";
import FilterForm from "../users/FilterForm";
import EventFilter from "./Eventfilter";

function EventApplication() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uuid = queryParams.get("state");

  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [applicationModal, setApplicationModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [statedata, setStatedata] = useState("");
  const [states, setStates] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [editApplicationData, setEditApplicationData] = useState();
  const [users, setusers] = useState([]);
  const [userPreferences, setUserPreferences] = useState([]);
  const [userAttributes, setUserAttributes] = useState([]);
  const [userSocial, setUserSocial] = useState([]);
  const [uservideo, setUservideo] = useState([]);
  const [userabout, setUserabout] = useState([]);
  const [userData, setUserData] = useState([]);
  const [Payment, setPayment] = useState([]);

  const navigate = useNavigate();

  const [selectedRows, setSelectedRows] = useState([]);

  //filter
  const [userRegional, setUserRegional] = useState([]);
  const [userRole, setUserRole] = useState();
  const [gender, setGender] = useState("");
  const [username, setUsername] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [selectedRegionalPartner, setSelectedRegionalPartner] = useState("");
  const [userCategories, setUserCategories] = useState([]);
  const [categoryModal, setCategoryModal] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [city, setcity] = useState([]);
  const [countries, setCountries] = useState();
  const [cities, setCities] = useState([]);
  const [eventCategory, setEventCategory] = useState([]);
  const [eventTitle, setEventTitle] = useState();

  useEffect(() => {
    setCsvData(data);
  }, [data]);

  useEffect(() => {
    if (uuid) {
      fetchAppliedEvents();
    } else {
      fetchEventApplication();
    }
    fetchStates();
    fetchUsers();
    fetchUsersList();
    fetchUserRoles();
  }, []);

  useEffect(() => {
    extractPaymentDetails();
  }, [data]);

  const handleOpenModal = (user) => {
    navigate(`/users/usersdetails`);
  };

  const handleProfileResponse = (profilesResponse) => {
    const profiles = profilesResponse?.data || [];
    setUserPreferences(profiles);
  };

  const handleAttributesResponse = (attributesResponse) => {
    const attributes = attributesResponse?.data || [];
    console.log("Fetched User Attributes:", attributes);
    setUserAttributes(attributes);
  };

  const handleSocialmediaResponse = (SocialResponse) => {
    const socialmedia = SocialResponse?.data || [];
    console.log("Fetched User Social Media:", socialmedia);
    setUserSocial(socialmedia);
  };

  const handleUservideoResponse = (videoResponse) => {
    const Uservideo = videoResponse?.data || [];
    console.log("Fetched User Video Media:", Uservideo);
    setUservideo(Uservideo);
  };

  const handleUseraboutResponse = (aboutResponse) => {
    const Userabout = aboutResponse?.data || [];
    console.log("Fetched User Video Media:", Userabout);
    setUserabout(Userabout);
  };

  const fetchUsersList = useCallback(async () => {
    try {
      const usersResponse = await get(API_URL.USERS.GET_USERS);
      const users = usersResponse?.data || [];
      console.log("Fetched Users:", users);

      // setOriginalData(users);
      setUserData(users);

      const profilesResponse = await get(
        API_URL.PROFILE_PREFERENCES.GET_PREFERENCES
      );
      const profiles = profilesResponse?.data || [];
      handleProfileResponse(profilesResponse);

      const attributesResponse = await get(
        API_URL.USER_ATTRIBUTES.GET_USER_ATTRIBUTES
      );
      const attributes = attributesResponse?.data || [];
      handleAttributesResponse(attributesResponse);

      const socialmediaResponse = await get(
        API_URL.SOCIAL_MEDIA.GET_SOCIAL_MEDIA
      );
      const socialmedia = socialmediaResponse?.data || [];
      handleSocialmediaResponse(socialmediaResponse);

      const videoResponse = await get(API_URL.PROFILE.GET_PROFILE);
      const Uservideo = videoResponse?.data || [];
      handleUservideoResponse(videoResponse);
      console.log("Video link", videoResponse);

      const useraboutResponse = await get(API_URL.PROFILE.GET_PROFILE);
      const about = useraboutResponse?.data || [];
      handleUseraboutResponse(useraboutResponse);

      console.log("Fetched User Preferences:", profiles);
      setUserData(users);
      setUserPreferences(profiles);
    } catch (error) {
      console.error("Error fetching users or preferences:", error);
    }
  }, []);

  const enrichedData = userData.map((user) => {
    const preference = userPreferences.find(
      (pref) => pref.user_name === user.name
    );
    const attribute = userAttributes.find(
      (attr) => attr.user_name === user.name
    );
    const socialmedia = userSocial.find(
      (media) => media.user_name === user.name
    );
    const Videolink = uservideo.find((video) => video.user_name === user.name);
    const About = userabout.find((about) => about.user_name === user.name);

    // console.log('chek this',uservideo);

    const videoLinks = Videolink?.video_links || []; // Get the video_link array
    const firstVideoLink = videoLinks.length > 0 ? videoLinks[0] : null;
    // console.log('this is video',videoLinks);

    return {
      ...user,
      preference: preference?.preference_name || "No Preference",
      attribute: attribute?.attribute_name || "No Attribute",
      media: socialmedia?.link || null,
      Video: firstVideoLink || "No Video",
      about: About?.about || "No About",
    };
  });

  console.log("enrichedata", enrichedData);

  const fetchUserRoles = useCallback(() => {
    get(API_URL.USER_ROLE.USER_ROLE_GET)
      .then((response) => {
        if (response?.data.length > 0) setUserRoles(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleOpenPreview = (row) => {
    console.log(row);

    const filteredUser = enrichedData.filter(
      (user) => user.uuid === row.user_uuid
    );

    console.log("userfilter", enrichedData);
    console.log("user", row);

    // const userRole = userRoles.find(role => role.id === row.user_role_id)?.role || 'No Role';

    // const enrichedUser = {
    //   ...row,
    //   userRole,
    //   // Add userRole to the user object
    // };

    // const url = `${process.env.PUBLIC_URL}/userdetails/${row.user_uuid}`,{state:{userData: filteredUser[0]}}`;

    const url = `${process.env.PUBLIC_URL}/userdetails/${row.user_uuid}`;

    // Pass the filtered user data to the new page via state
    window.open(url, "_blank");
  };

  const fetchEventApplication = useCallback(() => {
    get(API_URL.EVENT_APPLICATION.GET_EVENT_APPLICATION)
      .then((response) => {
        console.log(response.data);
        if (response?.data.length >= 0) setData(response.data);
        setOriginalData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log(originalData);

  const fetchAppliedEvents = useCallback(() => {
    console.log("hai");

    get(API_URL.EVENTS.GET_COUNT_DETAILS(uuid))
      .then((response) => {
        console.log("hai", response);

        if (response?.data.applications.length > 0)
          console.log(response.data.applications);

        setData(response.data.applications);
        setOriginalData(response.data.applications);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchCities = useCallback(() => {
    get(API_URL.CITIES.CITIES_GET)
      .then((response) => {
        console.log(response.data);

        if (response?.data.length > 0) setcity(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  // const paymentfulldetails = data.map((i)=>{
  //   return i.payment_details

  // })
  // const PaymentDetail = paymentfulldetails[0];

  // console.log('application',PaymentDetail.payment_method);

  const extractPaymentDetails = () => {
    const paymentfulldetails = data.map((i) => i.payment_details); // Extract payment details
    const PaymentDetail = paymentfulldetails[0]; // Get the first payment detail
    if (PaymentDetail) {
      setPayment(PaymentDetail); // Set the payment details in state
    }
  };
  console.log("paymentdetails", Payment);

  const fetchUsers = useCallback(() => {
    get(API_URL.USERS.GET_USERS)
      .then((response) => {
        console.log(response);

        if (response?.data.length >= 0) setusers(response.data);
        // setOriginalData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log("data", data, "originaldata", originalData);

  const fetchStates = useCallback(() => {
    get(API_URL.STATES.STATES_GET)
      .then((response) => {
        if (response?.data.length > 0) setStates(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const applicationToggle = () => {
    setApplicationModal(!applicationModal);
  };

  const editmodalToggle = (row) => {
    console.log(row);
    setApplicationModal(!applicationModal);
    setEditApplicationData(row);
  };

  const handleCancel = () => {
    setApplicationModal(!applicationModal);
    setEditApplicationData(null);
  };

  console.log(data);

  const handleSave = async (formData) => {
    console.log(formData);

    const values = {
      id: formData?.id || "",
      // user: formData?.user_name?.value,
      // user_role: formData?.user_role_name,
      // event_uuid: formData?.event?.value,
      status: formData?.status,
      // notes:formData?.note
    };

    console.log("VALUES", values);

    if (formData?.id) {
      console.log(formData);

      patch(
        API_URL.EVENT_APPLICATION.PATCH_EVENT_APPLICATION(formData?.id),
        values
      )
        .then((response) => {
          console.log(response);

          toast.success("Updated successfully", { autoClose: 1000 });
          setApplicationModal(!applicationModal);
          setEditApplicationData(null);
          fetchEventApplication();
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          toast.error("Oops! something went wrong", { autoClose: 1000 });
        });
    } else {
      try {
        await post(
          API_URL.EVENT_APPLICATION.POST_EVENT_APPLICATION,
          values
        ).then((response) => {
          console.log(response);
        });
        console.log(values);

        toast.success("Application Submited successfully", { autoClose: 1000 });
        setApplicationModal(!applicationModal);
        setEditApplicationData(null);
        fetchEventApplication();
      } catch (error) {
        console.error("Error saving data:", error);
        toast.error("Oops! something went wrong", { autoClose: 1000 });
      }
    }
  };

  //selected row
  const handleRowSelected = (state) => {
    console.log(state);

    setSelectedRows(state.selectedRows);
    // setSelected(true)
  };

  const handleDeletedata = (row) => {
    if (row?.id) {
      DELETE(API_URL.EVENT_APPLICATION.DELETE_EVENT_APPLICATION(row?.id))
        .then((response) => {
          toast.success("Deleted successfully", { autoClose: 1000 });
          fetchEventApplication();
        })
        .catch((error) => {
          toast.error("Oops! something went wrong", { autoClose: 1000 });
        });
    }
  };

  //filter
  const handleSaveFilter = () => {
    const filteredData = originalData.filter((item) => {
      console.log(item);
      console.log(uuid);

      console.log(userRole);

      const roleMatches = userRole
        ? String(item.user_role_name) === String(userRole)
        : true;
      console.log("role", roleMatches);

      const usernameMatches = username
        ? item.user_name?.includes(username) || false
        : true;
      console.log("username", usernameMatches);

      const PhonennumberMatches = whatsappNumber
        ? item.user_whatsapp_number?.includes(whatsappNumber) || false
        : true;
      console.log("phone", PhonennumberMatches);

      // Fixing country and state filter comparison
      const countryMatches = country ? item.country_name === country : true;

      // console.log(cities);
      // console.log(item.event.city_name);

      const cityMatches = cities ? item.event.city_name.includes(cities) : true;

      const stateMatches = state ? item.event.state_name.includes(state) : true;

      const eventType = userCategories
        ? item.event.event_types_name.includes(userCategories)
        : true;

      const eventTitles = eventTitle
        ? item.event.title.includes(eventTitle)
        : true;

      return (
        roleMatches &&
        // genderMatches &&
        usernameMatches &&
        PhonennumberMatches &&
        countryMatches &&
        stateMatches &&
        eventType &&
        // regionalPartnerMatches &&
        cityMatches &&
        eventTitles
      );
    });

    console.log(filteredData);

    setData(filteredData);
    setCsvData(filteredData);
    filterToggle();
    setUserRole("");
    setGender("");
    setUsername("");
    setWhatsappNumber("");
    setCountry("");
    setState("");
    setUserCategories("");
  };

  console.log(data);

  const handleCancelFilter = () => {
    filterToggle();
    setUserRole("");
    setGender("");
    setUsername("");
    setWhatsappNumber("");
    setCountry("");
    setState("");
    setUserCategories("");
    setData(originalData);
    setCsvData(originalData);
  };

  const filterToggle = () => {
    setFilterModal(!filterModal);
  };

  const handleExport = () => {
    console.log(data);

    // Helper function to reassign IDs and restructure data
    const reassignIds = (rows) =>
      rows.map((row, index) => ({
        ...row,
        id: index + 1, // Reassign numeric IDs
        event: row.event.title, // Extract the event title
        payment_method: row.payment_details.payment_method,
        payment_status: row.payment_details.payment_status, // Add payment_status
        total_amount: row.payment_details.total_amount, // Add total_amount
        // Remove payment_details from the structure
      }));

    if (selectedRows.length > 0) {
      console.log("Selected rows for export");
      const updatedRows = reassignIds(selectedRows); // Process selected rows
      setCsvData(updatedRows); // Set updated data for CSV export
    } else {
      console.log("Exporting all data");
      const updatedData = reassignIds(data); // Process all rows
      setCsvData(updatedData); // Set updated data for CSV export
    }
  };

  const categoryToggle = () => {
    setCategoryModal(!categoryModal);
  };

  // const handleSaveFilter = () => {
  //   filterToggle();
  //   const filteredData = data.filter((item) => item.state === statedata);
  //   setData(filteredData);
  // };

  console.log(username);

  console.log(data);

  return (
    <>
      <div>
        <div
          className={`d-flex align-items-center justify-content-between  p-4`}
        >
          <H4 attrH4={{ className: "text-muted m-0" }}>
            Intrested Applications
          </H4>
          <div
            className="d-flex"
            style={{ width: "250px", justifyContent: "space-around" }}
          >
            {/* <Btn attrBtn={{ color: "primary", onClick: applicationToggle }}>
              + New Application{" "}
            </Btn> */}

            <Btn attrBtn={{ color: "primary", onClick: filterToggle }}>
              Filters
            </Btn>
            <AddApplication
              isOpen={applicationModal}
              toggle={applicationToggle}
              title="Application"
              applicationData={editApplicationData}
              onSave={handleSave}
              onCancel={handleCancel}
            />

            <EditCommonModal
              isOpen={filterModal}
              toggle={filterToggle}
              title="Filter"
              onSave={handleSaveFilter}
              onCancel={handleCancelFilter}
            >
              <EventFilter
                userRole={userRole}
                userRoles={userRoles}
                setUserRole={setUserRole}
                gender={gender}
                setGender={setGender}
                username={username}
                setUsername={setUsername}
                whatsappNumber={whatsappNumber}
                setWhatsappNumber={setWhatsappNumber}
                country={country}
                setCountry={setCountry}
                state={state}
                setState={setState}
                city={city}
                cities={cities}
                setcities={setCities}
                usercategories={userCategories}
                userCategory={eventCategory}
                setUserCategories={setUserCategories}
                countries={countries}
                states={states}
                userRegional={userRegional}
                selectedRegionalPartner={selectedRegionalPartner}
                setSelectedRegionalPartner={setSelectedRegionalPartner}
                setEventTitle={setEventTitle}
                eventTitle={eventTitle}
              />
            </EditCommonModal>
            <CSVLink
              data={csvData}
              filename="Applications-data.csv"
              className="btn btn-primary"
              onClick={handleExport}
            >
              Export
            </CSVLink>
          </div>
        </div>
        <div>
          <DataTable
            data={data}
            columns={applicationtableColumns(
              editmodalToggle,

              handleDeletedata,
              states,
              handleOpenModal,
              handleOpenPreview,
              Payment
            )}
            striped={true}
            center={true}
            pagination
            selectableRows={true}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleDelet}
            fixedHeader
            fixedHeaderScrollHeight="80vh"
          />
        </div>
      </div>
    </>
  );
}

export default EventApplication;
